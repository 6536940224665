.sign-up-card {
  width: 55dvh;
}

@media screen and (min-width: 1023px) {
  .sign-up-card {
    margin-left: 25rem;
  }
}

.connect-cdk-signin-div {
  text-align: center;
  width: 100%;
  background-color: rgba(240, 233, 241, 1);
  margin-top: 1dvh;
}

.connect-cdk-signin-text,
.connect-cdk-signin-link {
  display: block;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: "Roboto";
}

.connect-cdk-signin-link {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6b2574;
  padding-top: 0.5dvh;
  cursor: pointer;
}

.connect-cdk-signin-link:hover{
    text-decoration: underline;
}
