/* Fortellis */
/* Material Overrides */
/* Fortellis */
/* Material Overrides */
:root {
  --mdc-theme-primary: #904778;
  --mdc-theme-secondary: #b23072;
  --mdc-theme-background: #fff;
  --mdc-theme-surface: #fff;
  --mdc-theme-error: #cc0000;
  --mdc-theme-on-primary: #fff;
  --mdc-theme-on-secondary: #fff;
  --mdc-theme-on-surface: #000;
  --mdc-theme-on-error: #fff;
  --mdc-theme-text-primary-on-background: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-background: rgba(0, 0, 0, 0.6);
  --mdc-theme-text-hint-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-light: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-light: rgba(0, 0, 0, 0.6);
  --mdc-theme-text-hint-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-dark: white;
  --mdc-theme-text-secondary-on-dark: rgba(255, 255, 255, 0.7);
  --mdc-theme-text-hint-on-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-disabled-on-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-icon-on-dark: rgba(255, 255, 255, 0.5); }

.mdc-theme--primary {
  color: #904778 !important;
  /* @alternate */
  color: var(--mdc-theme-primary, #904778) !important; }

.mdc-theme--secondary {
  color: #b23072 !important;
  /* @alternate */
  color: var(--mdc-theme-secondary, #b23072) !important; }

.mdc-theme--background {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-background, #fff); }

.mdc-theme--surface {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff); }

.mdc-theme--error {
  color: #cc0000 !important;
  /* @alternate */
  color: var(--mdc-theme-error, #cc0000) !important; }

.mdc-theme--on-primary {
  color: #fff !important;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff) !important; }

.mdc-theme--on-secondary {
  color: #fff !important;
  /* @alternate */
  color: var(--mdc-theme-on-secondary, #fff) !important; }

.mdc-theme--on-surface {
  color: #000 !important;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000) !important; }

.mdc-theme--on-error {
  color: #fff !important;
  /* @alternate */
  color: var(--mdc-theme-on-error, #fff) !important; }

.mdc-theme--text-primary-on-background {
  color: rgba(0, 0, 0, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87)) !important; }

.mdc-theme--text-secondary-on-background {
  color: rgba(0, 0, 0, 0.6) !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.6)) !important; }

.mdc-theme--text-hint-on-background {
  color: rgba(0, 0, 0, 0.38) !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38)) !important; }

.mdc-theme--text-disabled-on-background {
  color: rgba(0, 0, 0, 0.38) !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38)) !important; }

.mdc-theme--text-icon-on-background {
  color: rgba(0, 0, 0, 0.38) !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38)) !important; }

.mdc-theme--text-primary-on-light {
  color: rgba(0, 0, 0, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)) !important; }

.mdc-theme--text-secondary-on-light {
  color: rgba(0, 0, 0, 0.6) !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.6)) !important; }

.mdc-theme--text-hint-on-light {
  color: rgba(0, 0, 0, 0.38) !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-light, rgba(0, 0, 0, 0.38)) !important; }

.mdc-theme--text-disabled-on-light {
  color: rgba(0, 0, 0, 0.38) !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38)) !important; }

.mdc-theme--text-icon-on-light {
  color: rgba(0, 0, 0, 0.38) !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-light, rgba(0, 0, 0, 0.38)) !important; }

.mdc-theme--text-primary-on-dark {
  color: white !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white) !important; }

.mdc-theme--text-secondary-on-dark {
  color: rgba(255, 255, 255, 0.7) !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)) !important; }

.mdc-theme--text-hint-on-dark {
  color: rgba(255, 255, 255, 0.5) !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)) !important; }

.mdc-theme--text-disabled-on-dark {
  color: rgba(255, 255, 255, 0.5) !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5)) !important; }

.mdc-theme--text-icon-on-dark {
  color: rgba(255, 255, 255, 0.5) !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-dark, rgba(255, 255, 255, 0.5)) !important; }

.mdc-theme--primary-bg {
  background-color: #904778 !important;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #904778) !important; }

.mdc-theme--secondary-bg {
  background-color: #b23072 !important;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #b23072) !important; }

.mdc-typography {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

.mdc-typography--headline1 {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 6rem;
  line-height: 6rem;
  font-weight: 300;
  letter-spacing: -0.01562em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline2 {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 3.75rem;
  line-height: 3.75rem;
  font-weight: 300;
  letter-spacing: -0.00833em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline3 {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 3rem;
  line-height: 3.125rem;
  font-weight: 400;
  letter-spacing: normal;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline4 {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 2.125rem;
  line-height: 2.5rem;
  font-weight: 600;
  letter-spacing: 0.00735em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline5 {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: normal;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline6 {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--subtitle1 {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 600;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--subtitle2 {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 600;
  letter-spacing: 0.00714em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--body1 {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.03125em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--body2 {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.01786em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--caption {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.03333em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--button {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 600;
  letter-spacing: 0.08929em;
  text-decoration: none;
  text-transform: uppercase; }

.mdc-typography--overline {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 2rem;
  font-weight: 600;
  letter-spacing: 0.16667em;
  text-decoration: none;
  text-transform: uppercase; }

.mdc-button {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 600;
  letter-spacing: 0.08929em;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0 8px 0 8px;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 64px;
  height: 36px;
  border: none;
  outline: none;
  /* @alternate */
  line-height: inherit;
  user-select: none;
  -webkit-appearance: none;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 4px; }
  .mdc-button::-moz-focus-inner {
    padding: 0;
    border: 0; }
  .mdc-button:active {
    outline: none; }
  .mdc-button:hover {
    cursor: pointer; }
  .mdc-button:disabled {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.37);
    cursor: default;
    pointer-events: none; }
  .mdc-button.mdc-button--dense {
    border-radius: 4px; }
  .mdc-button:not(:disabled) {
    background-color: transparent; }
  .mdc-button .mdc-button__icon {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 8px;
    display: inline-block;
    width: 18px;
    height: 18px;
    font-size: 18px;
    vertical-align: top; }
    [dir="rtl"] .mdc-button .mdc-button__icon, .mdc-button .mdc-button__icon[dir="rtl"] {
      /* @noflip */
      margin-left: 8px;
      /* @noflip */
      margin-right: 0; }
  .mdc-button:not(:disabled) {
    color: #904778;
    /* @alternate */
    color: var(--mdc-theme-primary, #904778); }

.mdc-button__label + .mdc-button__icon {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0; }
  [dir="rtl"] .mdc-button__label + .mdc-button__icon, .mdc-button__label + .mdc-button__icon[dir="rtl"] {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 8px; }

svg.mdc-button__icon {
  fill: currentColor; }

.mdc-button--raised .mdc-button__icon,
.mdc-button--unelevated .mdc-button__icon,
.mdc-button--outlined .mdc-button__icon {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 8px; }
  [dir="rtl"] .mdc-button--raised .mdc-button__icon, .mdc-button--raised .mdc-button__icon[dir="rtl"], [dir="rtl"]
  .mdc-button--unelevated .mdc-button__icon,
  .mdc-button--unelevated .mdc-button__icon[dir="rtl"], [dir="rtl"]
  .mdc-button--outlined .mdc-button__icon,
  .mdc-button--outlined .mdc-button__icon[dir="rtl"] {
    /* @noflip */
    margin-left: 8px;
    /* @noflip */
    margin-right: -4px; }

.mdc-button--raised .mdc-button__label + .mdc-button__icon,
.mdc-button--unelevated .mdc-button__label + .mdc-button__icon,
.mdc-button--outlined .mdc-button__label + .mdc-button__icon {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: -4px; }
  [dir="rtl"] .mdc-button--raised .mdc-button__label + .mdc-button__icon, .mdc-button--raised .mdc-button__label + .mdc-button__icon[dir="rtl"], [dir="rtl"]
  .mdc-button--unelevated .mdc-button__label + .mdc-button__icon,
  .mdc-button--unelevated .mdc-button__label + .mdc-button__icon[dir="rtl"], [dir="rtl"]
  .mdc-button--outlined .mdc-button__label + .mdc-button__icon,
  .mdc-button--outlined .mdc-button__label + .mdc-button__icon[dir="rtl"] {
    /* @noflip */
    margin-left: -4px;
    /* @noflip */
    margin-right: 8px; }

.mdc-button--raised,
.mdc-button--unelevated {
  padding: 0 16px 0 16px; }
  .mdc-button--raised:disabled,
  .mdc-button--unelevated:disabled {
    background-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.37); }
  .mdc-button--raised:not(:disabled),
  .mdc-button--unelevated:not(:disabled) {
    background-color: #904778; }
    @supports not (-ms-ime-align: auto) {
      .mdc-button--raised:not(:disabled),
      .mdc-button--unelevated:not(:disabled) {
        /* @alternate */
        background-color: var(--mdc-theme-primary, #904778); } }
  .mdc-button--raised:not(:disabled),
  .mdc-button--unelevated:not(:disabled) {
    color: #fff;
    /* @alternate */
    color: var(--mdc-theme-on-primary, #fff); }

.mdc-button--raised {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1); }
  .mdc-button--raised:hover, .mdc-button--raised:focus {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .mdc-button--raised:active {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }
  .mdc-button--raised:disabled {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mdc-button--outlined {
  border-style: solid;
  padding: 0 14px 0 14px;
  border-width: 2px; }
  .mdc-button--outlined:disabled {
    border-color: rgba(0, 0, 0, 0.37); }
  .mdc-button--outlined:not(:disabled) {
    border-color: #904778;
    /* @alternate */
    border-color: var(--mdc-theme-primary, #904778); }

.mdc-button--dense {
  height: 32px;
  font-size: .8125rem; }

@keyframes mdc-ripple-fg-radius-in {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1); }
  to {
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); } }

@keyframes mdc-ripple-fg-opacity-in {
  from {
    animation-timing-function: linear;
    opacity: 0; }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0); } }

@keyframes mdc-ripple-fg-opacity-out {
  from {
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0); }
  to {
    opacity: 0; } }

.mdc-ripple-surface--test-edge-var-bug {
  --mdc-ripple-surface-test-edge-var: 1px solid #000;
  visibility: hidden; }
  .mdc-ripple-surface--test-edge-var-bug::before {
    border: var(--mdc-ripple-surface-test-edge-var); }

.mdc-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity; }
  .mdc-button::before, .mdc-button::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-button::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-button.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-button.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-button.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-button.mdc-ripple-upgraded--foreground-activation::after {
    animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards; }
  .mdc-button.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: mdc-ripple-fg-opacity-out 150ms;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-button::before, .mdc-button::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-button.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-button::before, .mdc-button::after {
    background-color: #904778; }
    @supports not (-ms-ime-align: auto) {
      .mdc-button::before, .mdc-button::after {
        /* @alternate */
        background-color: var(--mdc-theme-primary, #904778); } }
  .mdc-button:hover::before {
    opacity: 0.04; }
  .mdc-button:not(.mdc-ripple-upgraded):focus::before, .mdc-button.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-button:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-button:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-button.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.12; }

.mdc-button--raised::before, .mdc-button--raised::after,
.mdc-button--unelevated::before,
.mdc-button--unelevated::after {
  background-color: #fff; }
  @supports not (-ms-ime-align: auto) {
    .mdc-button--raised::before, .mdc-button--raised::after,
    .mdc-button--unelevated::before,
    .mdc-button--unelevated::after {
      /* @alternate */
      background-color: var(--mdc-theme-on-primary, #fff); } }

.mdc-button--raised:hover::before,
.mdc-button--unelevated:hover::before {
  opacity: 0.08; }

.mdc-button--raised:not(.mdc-ripple-upgraded):focus::before, .mdc-button--raised.mdc-ripple-upgraded--background-focused::before,
.mdc-button--unelevated:not(.mdc-ripple-upgraded):focus::before,
.mdc-button--unelevated.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.24; }

.mdc-button--raised:not(.mdc-ripple-upgraded)::after,
.mdc-button--unelevated:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear; }

.mdc-button--raised:not(.mdc-ripple-upgraded):active::after,
.mdc-button--unelevated:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24; }

.mdc-button--raised.mdc-ripple-upgraded,
.mdc-button--unelevated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.24; }

.mdc-card {
  border-radius: 4px;
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  box-sizing: border-box; }

.mdc-card--outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0; }

.mdc-card__media {
  position: relative;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }
  .mdc-card__media::before {
    display: block;
    content: ""; }

.mdc-card__media:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit; }

.mdc-card__media:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit; }

.mdc-card__media--square::before {
  margin-top: 100%; }

.mdc-card__media--16-9::before {
  margin-top: 56.25%; }

.mdc-card__media-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box; }

.mdc-card__primary-action {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  outline: none;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden; }

.mdc-card__primary-action:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit; }

.mdc-card__primary-action:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit; }

.mdc-card__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  min-height: 52px;
  padding: 8px; }

.mdc-card__actions--full-bleed {
  padding: 0; }

.mdc-card__action-buttons,
.mdc-card__action-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box; }

.mdc-card__action-icons {
  color: rgba(0, 0, 0, 0.6);
  flex-grow: 1;
  justify-content: flex-end; }

.mdc-card__action-buttons + .mdc-card__action-icons {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0; }
  [dir="rtl"] .mdc-card__action-buttons + .mdc-card__action-icons, .mdc-card__action-buttons + .mdc-card__action-icons[dir="rtl"] {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 16px; }

.mdc-card__action {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  cursor: pointer;
  user-select: none; }
  .mdc-card__action:focus {
    outline: none; }

.mdc-card__action--button {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
  padding: 0 8px; }
  [dir="rtl"] .mdc-card__action--button, .mdc-card__action--button[dir="rtl"] {
    /* @noflip */
    margin-left: 8px;
    /* @noflip */
    margin-right: 0; }
  .mdc-card__action--button:last-child {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 0; }
    [dir="rtl"] .mdc-card__action--button:last-child, .mdc-card__action--button:last-child[dir="rtl"] {
      /* @noflip */
      margin-left: 0;
      /* @noflip */
      margin-right: 0; }

.mdc-card__actions--full-bleed .mdc-card__action--button {
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-height: none;
  margin: 0;
  padding: 8px 16px;
  /* @noflip */
  text-align: left; }
  [dir="rtl"] .mdc-card__actions--full-bleed .mdc-card__action--button, .mdc-card__actions--full-bleed .mdc-card__action--button[dir="rtl"] {
    /* @noflip */
    text-align: right; }

.mdc-card__action--icon {
  margin: -6px 0;
  padding: 12px; }

.mdc-card__action--icon:not(:disabled) {
  color: rgba(0, 0, 0, 0.6); }

.mdc-card__primary-action {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity; }
  .mdc-card__primary-action::before, .mdc-card__primary-action::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-card__primary-action::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-card__primary-action.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-card__primary-action.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-card__primary-action.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-card__primary-action.mdc-ripple-upgraded--foreground-activation::after {
    animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards; }
  .mdc-card__primary-action.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: mdc-ripple-fg-opacity-out 150ms;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-card__primary-action::before, .mdc-card__primary-action::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-card__primary-action.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-card__primary-action::before, .mdc-card__primary-action::after {
    background-color: #000; }
  .mdc-card__primary-action:hover::before {
    opacity: 0.04; }
  .mdc-card__primary-action:not(.mdc-ripple-upgraded):focus::before, .mdc-card__primary-action.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-card__primary-action:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-card__primary-action:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-card__primary-action.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.12; }

@keyframes mdc-checkbox-unchecked-checked-checkmark-path {
  0%,
  50% {
    stroke-dashoffset: 29.78334; }
  50% {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1); }
  100% {
    stroke-dashoffset: 0; } }

@keyframes mdc-checkbox-unchecked-indeterminate-mixedmark {
  0%,
  68.2% {
    transform: scaleX(0); }
  68.2% {
    animation-timing-function: cubic-bezier(0, 0, 0, 1); }
  100% {
    transform: scaleX(1); } }

@keyframes mdc-checkbox-checked-unchecked-checkmark-path {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    opacity: 1;
    stroke-dashoffset: 0; }
  to {
    opacity: 0;
    stroke-dashoffset: -29.78334; } }

@keyframes mdc-checkbox-checked-indeterminate-checkmark {
  from {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transform: rotate(0deg);
    opacity: 1; }
  to {
    transform: rotate(45deg);
    opacity: 0; } }

@keyframes mdc-checkbox-indeterminate-checked-checkmark {
  from {
    animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    transform: rotate(45deg);
    opacity: 0; }
  to {
    transform: rotate(360deg);
    opacity: 1; } }

@keyframes mdc-checkbox-checked-indeterminate-mixedmark {
  from {
    animation-timing-function: mdc-animation-deceleration-curve-timing-function;
    transform: rotate(-45deg);
    opacity: 0; }
  to {
    transform: rotate(0deg);
    opacity: 1; } }

@keyframes mdc-checkbox-indeterminate-checked-mixedmark {
  from {
    animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    transform: rotate(0deg);
    opacity: 1; }
  to {
    transform: rotate(315deg);
    opacity: 0; } }

@keyframes mdc-checkbox-indeterminate-unchecked-mixedmark {
  0% {
    animation-timing-function: linear;
    transform: scaleX(1);
    opacity: 1; }
  32.8%,
  100% {
    transform: scaleX(0);
    opacity: 0; } }

.mdc-checkbox {
  display: inline-block;
  position: relative;
  flex: 0 0 18px;
  box-sizing: content-box;
  width: 18px;
  height: 18px;
  padding: 11px;
  line-height: 0;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: bottom; }

.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
  border-color: rgba(0, 0, 0, 0.54);
  background-color: transparent; }

.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
  border-color: #b23072;
  /* @alternate */
  border-color: var(--mdc-theme-secondary, #b23072);
  background-color: #b23072;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #b23072); }

@keyframes mdc-checkbox-fade-in-background-0 {
  0% {
    border-color: rgba(0, 0, 0, 0.54);
    background-color: transparent; }
  50% {
    border-color: #b23072;
    /* @alternate */
    border-color: var(--mdc-theme-secondary, #b23072);
    background-color: #b23072;
    /* @alternate */
    background-color: var(--mdc-theme-secondary, #b23072); } }

@keyframes mdc-checkbox-fade-out-background-0 {
  0%,
  80% {
    border-color: #b23072;
    /* @alternate */
    border-color: var(--mdc-theme-secondary, #b23072);
    background-color: #b23072;
    /* @alternate */
    background-color: var(--mdc-theme-secondary, #b23072); }
  100% {
    border-color: rgba(0, 0, 0, 0.54);
    background-color: transparent; } }

.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-in-background-0; }

.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-out-background-0; }

.mdc-checkbox__checkmark {
  color: #fff; }

.mdc-checkbox__mixedmark {
  border-color: #fff; }

.mdc-checkbox__background::before {
  background-color: #b23072; }
  @supports not (-ms-ime-align: auto) {
    .mdc-checkbox__background::before {
      /* @alternate */
      background-color: var(--mdc-theme-secondary, #b23072); } }

.mdc-checkbox__native-control:disabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
  border-color: rgba(0, 0, 0, 0.26); }

.mdc-checkbox__native-control:disabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:disabled:indeterminate ~ .mdc-checkbox__background {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.26); }

@media screen and (-ms-high-contrast: active) {
  .mdc-checkbox__mixedmark {
    margin: 0 1px; } }

.mdc-checkbox--disabled {
  cursor: default;
  pointer-events: none; }

.mdc-checkbox__background {
  /* @noflip */
  left: 11px;
  /* @noflip */
  right: initial;
  display: inline-flex;
  position: absolute;
  top: 11px;
  bottom: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 45%;
  height: 45%;
  border: 2px solid currentColor;
  border-radius: 2px;
  background-color: transparent;
  pointer-events: none;
  will-change: background-color, border-color;
  transition: background-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), border-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1); }
  .mdc-checkbox[dir="rtl"] .mdc-checkbox__background,
  [dir="rtl"] .mdc-checkbox .mdc-checkbox__background {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 11px; }

.mdc-checkbox__checkmark {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1); }
  .mdc-checkbox--upgraded .mdc-checkbox__checkmark {
    opacity: 1; }

.mdc-checkbox__checkmark-path {
  transition: stroke-dashoffset 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  stroke: currentColor;
  stroke-width: 3.12px;
  stroke-dashoffset: 29.78334;
  stroke-dasharray: 29.78334; }

.mdc-checkbox__mixedmark {
  width: 100%;
  height: 0;
  transform: scaleX(0) rotate(0deg);
  border-width: 1px;
  border-style: solid;
  opacity: 0;
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1); }

.mdc-checkbox--upgraded .mdc-checkbox__background,
.mdc-checkbox--upgraded .mdc-checkbox__checkmark,
.mdc-checkbox--upgraded .mdc-checkbox__checkmark-path,
.mdc-checkbox--upgraded .mdc-checkbox__mixedmark {
  transition: none !important; }

.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__background, .mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__background, .mdc-checkbox--anim-checked-unchecked .mdc-checkbox__background, .mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__background {
  animation-duration: 180ms;
  animation-timing-function: linear; }

.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__checkmark-path {
  animation: mdc-checkbox-unchecked-checked-checkmark-path 180ms linear 0s;
  transition: none; }

.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-unchecked-indeterminate-mixedmark 90ms linear 0s;
  transition: none; }

.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__checkmark-path {
  animation: mdc-checkbox-checked-unchecked-checkmark-path 90ms linear 0s;
  transition: none; }

.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__checkmark {
  animation: mdc-checkbox-checked-indeterminate-checkmark 90ms linear 0s;
  transition: none; }

.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-checked-indeterminate-mixedmark 90ms linear 0s;
  transition: none; }

.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__checkmark {
  animation: mdc-checkbox-indeterminate-checked-checkmark 500ms linear 0s;
  transition: none; }

.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-indeterminate-checked-mixedmark 500ms linear 0s;
  transition: none; }

.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-indeterminate-unchecked-mixedmark 300ms linear 0s;
  transition: none; }

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background {
  transition: border-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1), background-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1); }
  .mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path,
  .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path {
    stroke-dashoffset: 0; }

.mdc-checkbox__background::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(0, 0);
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
  will-change: opacity, transform;
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1); }

.mdc-checkbox__native-control:focus ~ .mdc-checkbox__background::before {
  transform: scale(2.75, 2.75);
  opacity: 0.12;
  transition: opacity 80ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 80ms 0ms cubic-bezier(0, 0, 0.2, 1); }

.mdc-checkbox__native-control {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: inherit; }
  .mdc-checkbox__native-control:disabled {
    cursor: default;
    pointer-events: none; }

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  transition: opacity 180ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 180ms 0ms cubic-bezier(0, 0, 0.2, 1);
  opacity: 1; }

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scaleX(1) rotate(-45deg); }

.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1); }

.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scaleX(1) rotate(0deg);
  opacity: 1; }

.mdc-checkbox {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity; }
  .mdc-checkbox::before, .mdc-checkbox::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-checkbox::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-checkbox.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-checkbox.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-checkbox.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-checkbox.mdc-ripple-upgraded--foreground-activation::after {
    animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards; }
  .mdc-checkbox.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: mdc-ripple-fg-opacity-out 150ms;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-checkbox::before, .mdc-checkbox::after {
    background-color: #b23072; }
    @supports not (-ms-ime-align: auto) {
      .mdc-checkbox::before, .mdc-checkbox::after {
        /* @alternate */
        background-color: var(--mdc-theme-secondary, #b23072); } }
  .mdc-checkbox:hover::before {
    opacity: 0.04; }
  .mdc-checkbox:not(.mdc-ripple-upgraded):focus::before, .mdc-checkbox.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-checkbox:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-checkbox:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-checkbox.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.12; }
  .mdc-checkbox::before, .mdc-checkbox::after {
    top: calc(50% - 50%);
    /* @noflip */
    left: calc(50% - 50%);
    width: 100%;
    height: 100%; }
  .mdc-checkbox.mdc-ripple-upgraded::before, .mdc-checkbox.mdc-ripple-upgraded::after {
    top: var(--mdc-ripple-top, calc(50% - 50%));
    /* @noflip */
    left: var(--mdc-ripple-left, calc(50% - 50%));
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-checkbox.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }

.mdc-ripple-upgraded--background-focused .mdc-checkbox__background::before {
  content: none; }

.mdc-chip {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  border-radius: 16px;
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.01786em;
  text-decoration: inherit;
  text-transform: inherit;
  height: 32px;
  display: inline-flex;
  position: relative;
  align-items: center;
  box-sizing: border-box;
  padding: 7px 12px;
  outline: none;
  cursor: pointer;
  overflow: hidden; }
  .mdc-chip::before, .mdc-chip::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-chip::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-chip.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-chip.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-chip.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-chip.mdc-ripple-upgraded--foreground-activation::after {
    animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards; }
  .mdc-chip.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: mdc-ripple-fg-opacity-out 150ms;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-chip::before, .mdc-chip::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-chip.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-chip::before, .mdc-chip::after {
    background-color: rgba(0, 0, 0, 0.87); }
  .mdc-chip:hover::before {
    opacity: 0.04; }
  .mdc-chip:not(.mdc-ripple-upgraded):focus::before, .mdc-chip.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-chip:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-chip:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-chip.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.12; }
  .mdc-chip:hover {
    color: rgba(0, 0, 0, 0.87); }
  .mdc-chip.mdc-chip--selected .mdc-chip__checkmark,
  .mdc-chip .mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden) {
    /* @noflip */
    margin-left: -4px;
    /* @noflip */
    margin-right: 4px;
    margin-top: -4px;
    margin-bottom: -4px; }
    [dir="rtl"] .mdc-chip.mdc-chip--selected .mdc-chip__checkmark, .mdc-chip.mdc-chip--selected .mdc-chip__checkmark[dir="rtl"], [dir="rtl"]
    .mdc-chip .mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden),
    .mdc-chip .mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden)[dir="rtl"] {
      /* @noflip */
      margin-left: 4px;
      /* @noflip */
      margin-right: -4px; }
  .mdc-chip:hover {
    color: #000;
    /* @alternate */
    color: var(--mdc-theme-on-surface, #000); }

.mdc-chip__icon--leading {
  color: rgba(0, 0, 0, 0.54); }

.mdc-chip__icon--trailing {
  color: rgba(0, 0, 0, 0.54); }
  .mdc-chip__icon--trailing:hover {
    color: rgba(0, 0, 0, 0.62); }
  .mdc-chip__icon--trailing:focus {
    color: rgba(0, 0, 0, 0.87); }

.mdc-chip__icon.mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden) {
  width: 20px;
  height: 20px;
  font-size: 20px; }

.mdc-chip__icon.mdc-chip__icon--trailing {
  width: 18px;
  height: 18px;
  font-size: 18px; }

.mdc-chip__icon--trailing {
  margin: 0 -4px 0 4px; }

.mdc-chip--exit {
  transition: opacity 75ms cubic-bezier(0.4, 0, 0.2, 1), width 150ms cubic-bezier(0, 0, 0.2, 1), padding 100ms linear, margin 100ms linear;
  opacity: 0; }

.mdc-chip__text {
  white-space: nowrap; }

.mdc-chip__icon {
  border-radius: 50%;
  outline: none;
  vertical-align: middle; }

.mdc-chip__checkmark {
  height: 20px; }

.mdc-chip__checkmark-path {
  transition: stroke-dashoffset 150ms 50ms cubic-bezier(0.4, 0, 0.6, 1);
  stroke-width: 2px;
  stroke-dashoffset: 29.78334;
  stroke-dasharray: 29.78334; }

.mdc-chip--selected .mdc-chip__checkmark-path {
  stroke-dashoffset: 0; }

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected::before {
  opacity: 0.08; }

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected::after {
  background-color: #904778; }
  @supports not (-ms-ime-align: auto) {
    .mdc-chip-set--choice .mdc-chip.mdc-chip--selected::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected::after {
      /* @alternate */
      background-color: var(--mdc-theme-primary, #904778); } }

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:hover::before {
  opacity: 0.12; }

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded):focus::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.2; }

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear; }

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.2; }

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.2; }

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected {
  color: #904778;
  /* @alternate */
  color: var(--mdc-theme-primary, #904778); }
  .mdc-chip-set--choice .mdc-chip.mdc-chip--selected .mdc-chip__icon--leading {
    color: rgba(144, 71, 120, 0.54); }
  .mdc-chip-set--choice .mdc-chip.mdc-chip--selected:hover {
    color: #904778;
    /* @alternate */
    color: var(--mdc-theme-primary, #904778); }

.mdc-chip-set--choice .mdc-chip .mdc-chip__checkmark-path {
  stroke: #904778;
  /* @alternate */
  stroke: var(--mdc-theme-primary, #904778); }

.mdc-chip-set--choice .mdc-chip--selected {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff); }

.mdc-chip__checkmark-svg {
  width: 0;
  height: 20px;
  transition: width 150ms cubic-bezier(0.4, 0, 0.2, 1); }

.mdc-chip--selected .mdc-chip__checkmark-svg {
  width: 20px; }

.mdc-chip-set--filter .mdc-chip__icon--leading {
  transition: opacity 75ms linear;
  transition-delay: -50ms;
  opacity: 1; }
  .mdc-chip-set--filter .mdc-chip__icon--leading + .mdc-chip__checkmark {
    transition: opacity 75ms linear;
    transition-delay: 80ms;
    opacity: 0; }
    .mdc-chip-set--filter .mdc-chip__icon--leading + .mdc-chip__checkmark .mdc-chip__checkmark-svg {
      transition: width 0ms; }

.mdc-chip-set--filter .mdc-chip--selected .mdc-chip__icon--leading {
  opacity: 0; }
  .mdc-chip-set--filter .mdc-chip--selected .mdc-chip__icon--leading + .mdc-chip__checkmark {
    width: 0;
    opacity: 1; }

.mdc-chip-set--filter .mdc-chip__icon--leading-hidden.mdc-chip__icon--leading {
  width: 0;
  opacity: 0; }
  .mdc-chip-set--filter .mdc-chip__icon--leading-hidden.mdc-chip__icon--leading + .mdc-chip__checkmark {
    width: 20px; }

@keyframes mdc-chip-entry {
  from {
    transform: scale(0.8);
    opacity: .4; }
  to {
    transform: scale(1);
    opacity: 1; } }

.mdc-chip-set {
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box; }
  .mdc-chip-set .mdc-chip {
    margin: 4px; }

.mdc-chip-set--input .mdc-chip {
  animation: mdc-chip-entry 100ms cubic-bezier(0, 0, 0.2, 1); }

.mdc-dialog,
.mdc-dialog__scrim {
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%; }

.mdc-dialog {
  display: none;
  z-index: 7; }
  .mdc-dialog .mdc-dialog__surface {
    background-color: #fff;
    /* @alternate */
    background-color: var(--mdc-theme-surface, #fff); }
  .mdc-dialog .mdc-dialog__scrim {
    background-color: rgba(0, 0, 0, 0.32); }
  .mdc-dialog .mdc-dialog__title {
    color: rgba(0, 0, 0, 0.87); }
  .mdc-dialog .mdc-dialog__content {
    color: rgba(0, 0, 0, 0.6); }
  .mdc-dialog.mdc-dialog--scrollable .mdc-dialog__title,
  .mdc-dialog.mdc-dialog--scrollable .mdc-dialog__actions {
    border-color: rgba(0, 0, 0, 0.12); }
  .mdc-dialog .mdc-dialog__surface {
    min-width: 280px; }
  @media (max-width: 592px) {
    .mdc-dialog .mdc-dialog__surface {
      max-width: calc(100vw - 32px); } }
  @media (min-width: 592px) {
    .mdc-dialog .mdc-dialog__surface {
      max-width: 560px; } }
  .mdc-dialog .mdc-dialog__surface {
    max-height: calc(100vh - 32px); }
  .mdc-dialog .mdc-dialog__surface {
    border-radius: 4px; }

.mdc-dialog__scrim {
  opacity: 0;
  z-index: -1; }

.mdc-dialog__container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  box-sizing: border-box;
  transform: scale(0.8);
  opacity: 0; }

.mdc-dialog__surface {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%; }
  .mdc-dialog[dir="rtl"] .mdc-dialog__surface,
  [dir="rtl"] .mdc-dialog .mdc-dialog__surface {
    text-align: right; }

.mdc-dialog__title {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0 24px 9px;
  border-bottom: 1px solid transparent; }
  .mdc-dialog__title::before {
    display: inline-block;
    width: 0;
    height: 40px;
    content: "";
    vertical-align: 0; }
  .mdc-dialog[dir="rtl"] .mdc-dialog__title,
  [dir="rtl"] .mdc-dialog .mdc-dialog__title {
    text-align: right; }

.mdc-dialog--scrollable .mdc-dialog__title {
  padding-bottom: 15px; }

.mdc-dialog__content {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.03125em;
  text-decoration: inherit;
  text-transform: inherit;
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0;
  padding: 20px 24px;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }
  .mdc-dialog__content > :first-child {
    margin-top: 0; }
  .mdc-dialog__content > :last-child {
    margin-bottom: 0; }

.mdc-dialog__title + .mdc-dialog__content {
  padding-top: 0; }

.mdc-dialog--scrollable .mdc-dialog__content {
  padding-top: 8px;
  padding-bottom: 8px; }

.mdc-dialog__content .mdc-list:first-child:last-child {
  padding: 6px 0 0; }

.mdc-dialog--scrollable .mdc-dialog__content .mdc-list:first-child:last-child {
  padding: 0; }

.mdc-dialog__actions {
  display: flex;
  position: relative;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  min-height: 52px;
  margin: 0;
  padding: 8px;
  border-top: 1px solid transparent; }
  .mdc-dialog--stacked .mdc-dialog__actions {
    flex-direction: column;
    align-items: flex-end; }

.mdc-dialog__button {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
  max-width: 100%;
  text-align: right; }
  [dir="rtl"] .mdc-dialog__button, .mdc-dialog__button[dir="rtl"] {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 8px; }
  .mdc-dialog__button:first-child {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 0; }
    [dir="rtl"] .mdc-dialog__button:first-child, .mdc-dialog__button:first-child[dir="rtl"] {
      /* @noflip */
      margin-left: 0;
      /* @noflip */
      margin-right: 0; }
  .mdc-dialog[dir="rtl"] .mdc-dialog__button,
  [dir="rtl"] .mdc-dialog .mdc-dialog__button {
    text-align: left; }
  .mdc-dialog--stacked .mdc-dialog__button:not(:first-child) {
    margin-top: 12px; }

.mdc-dialog--open,
.mdc-dialog--opening,
.mdc-dialog--closing {
  display: flex; }

.mdc-dialog--opening .mdc-dialog__scrim {
  transition: opacity 150ms linear; }

.mdc-dialog--opening .mdc-dialog__container {
  transition: opacity 75ms linear, transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1); }

.mdc-dialog--closing .mdc-dialog__scrim,
.mdc-dialog--closing .mdc-dialog__container {
  transition: opacity 75ms linear; }

.mdc-dialog--closing .mdc-dialog__container {
  transform: scale(1); }

.mdc-dialog--open .mdc-dialog__scrim {
  opacity: 1; }

.mdc-dialog--open .mdc-dialog__container {
  transform: scale(1);
  opacity: 1; }

.mdc-dialog-scroll-lock {
  overflow: hidden; }

.mdc-drawer {
  border-color: rgba(0, 0, 0, 0.12);
  background-color: #fff;
  /* @noflip */
  border-radius: 0 0 0 0;
  z-index: 6;
  width: 256px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-sizing: border-box;
  height: 100%;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  /* @noflip */
  border-right-width: 1px;
  /* @noflip */
  border-right-style: solid;
  overflow: hidden; }
  .mdc-drawer .mdc-drawer__title {
    color: rgba(0, 0, 0, 0.87); }
  .mdc-drawer .mdc-list-group__subheader {
    color: rgba(0, 0, 0, 0.6); }
  .mdc-drawer .mdc-drawer__subtitle {
    color: rgba(0, 0, 0, 0.6); }
  .mdc-drawer .mdc-list-item__graphic {
    color: rgba(0, 0, 0, 0.6); }
  .mdc-drawer .mdc-list-item {
    color: rgba(0, 0, 0, 0.87); }
  .mdc-drawer .mdc-list-item--activated .mdc-list-item__graphic {
    color: #904778; }
  .mdc-drawer .mdc-list-item--activated {
    color: rgba(144, 71, 120, 0.87); }
  [dir="rtl"] .mdc-drawer, .mdc-drawer[dir="rtl"] {
    /* @noflip */
    border-radius: 0 0 0 0; }
  .mdc-drawer .mdc-list-item {
    border-radius: 4px; }
  .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content {
    /* @noflip */
    margin-left: 256px;
    /* @noflip */
    margin-right: 0; }
    [dir="rtl"] .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content, .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content[dir="rtl"] {
      /* @noflip */
      margin-left: 0;
      /* @noflip */
      margin-right: 256px; }
  [dir="rtl"] .mdc-drawer, .mdc-drawer[dir="rtl"] {
    /* @noflip */
    border-right-width: 0;
    /* @noflip */
    border-left-width: 1px;
    /* @noflip */
    border-right-style: none;
    /* @noflip */
    border-left-style: solid; }
  .mdc-drawer .mdc-list-item {
    font-family: Raleway, Arial, Helvetica, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-weight: 600;
    letter-spacing: 0.00714em;
    text-decoration: inherit;
    text-transform: inherit;
    height: calc(48px - 2 * 4px);
    margin: 8px 8px;
    padding: 0 8px; }
  .mdc-drawer .mdc-list-item:nth-child(1) {
    margin-top: 2px; }
  .mdc-drawer .mdc-list-item:nth-last-child(1) {
    margin-bottom: 0; }
  .mdc-drawer .mdc-list-group__subheader {
    font-family: Raleway, Arial, Helvetica, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    letter-spacing: 0.01786em;
    text-decoration: inherit;
    text-transform: inherit;
    display: block;
    margin-top: 0;
    /* @alternate */
    line-height: normal;
    margin: 0;
    padding: 0 16px; }
    .mdc-drawer .mdc-list-group__subheader::before {
      display: inline-block;
      width: 0;
      height: 24px;
      content: "";
      vertical-align: 0; }
  .mdc-drawer .mdc-list-divider {
    margin: 3px 0 4px 0; }
  .mdc-drawer .mdc-list-item__text,
  .mdc-drawer .mdc-list-item__graphic {
    pointer-events: none; }

.mdc-drawer--animate {
  transform: translateX(-100%); }
  [dir="rtl"] .mdc-drawer--animate, .mdc-drawer--animate[dir="rtl"] {
    transform: translateX(100%); }

.mdc-drawer--opening {
  transform: translateX(0);
  transition-duration: 250ms; }
  [dir="rtl"] .mdc-drawer--opening, .mdc-drawer--opening[dir="rtl"] {
    transform: translateX(0); }

.mdc-drawer--closing {
  transform: translateX(-100%);
  transition-duration: 200ms; }
  [dir="rtl"] .mdc-drawer--closing, .mdc-drawer--closing[dir="rtl"] {
    transform: translateX(100%); }

.mdc-drawer__header {
  flex-shrink: 0;
  box-sizing: border-box;
  min-height: 64px;
  padding: 0 16px 4px; }

.mdc-drawer__title {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px; }
  .mdc-drawer__title::before {
    display: inline-block;
    width: 0;
    height: 36px;
    content: "";
    vertical-align: 0; }
  .mdc-drawer__title::after {
    display: inline-block;
    width: 0;
    height: 20px;
    content: "";
    vertical-align: -20px; }

.mdc-drawer__subtitle {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.01786em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: 0; }
  .mdc-drawer__subtitle::before {
    display: inline-block;
    width: 0;
    height: 20px;
    content: "";
    vertical-align: 0; }

.mdc-drawer__content {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.mdc-drawer--dismissible {
  /* @noflip */
  left: 0;
  /* @noflip */
  right: initial;
  display: none;
  position: absolute; }
  [dir="rtl"] .mdc-drawer--dismissible, .mdc-drawer--dismissible[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 0; }
  .mdc-drawer--dismissible.mdc-drawer--open {
    display: flex; }

.mdc-drawer-app-content {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
  position: relative; }
  [dir="rtl"] .mdc-drawer-app-content, .mdc-drawer-app-content[dir="rtl"] {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 0; }

.mdc-drawer--modal {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  /* @noflip */
  left: 0;
  /* @noflip */
  right: initial;
  display: none;
  position: fixed; }
  .mdc-drawer--modal + .mdc-drawer-scrim {
    background-color: rgba(0, 0, 0, 0.32); }
  [dir="rtl"] .mdc-drawer--modal, .mdc-drawer--modal[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 0; }
  .mdc-drawer--modal.mdc-drawer--open {
    display: flex; }

.mdc-drawer-scrim {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 5; }
  .mdc-drawer--open + .mdc-drawer-scrim {
    display: block; }
  .mdc-drawer--animate + .mdc-drawer-scrim {
    opacity: 0; }
  .mdc-drawer--opening + .mdc-drawer-scrim {
    transition-duration: 250ms;
    opacity: 1; }
  .mdc-drawer--closing + .mdc-drawer-scrim {
    transition-duration: 200ms;
    opacity: 0; }

.mdc-icon-button {
  width: 48px;
  height: 48px;
  padding: 12px;
  font-size: 24px;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: transparent;
  fill: currentColor;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  user-select: none; }
  .mdc-icon-button svg,
  .mdc-icon-button img {
    width: 24px;
    height: 24px; }
  .mdc-icon-button:disabled {
    color: rgba(0, 0, 0, 0.38);
    /* @alternate */
    color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
    cursor: default;
    pointer-events: none; }

.mdc-icon-button__icon {
  display: inline-block; }
  .mdc-icon-button__icon.mdc-icon-button__icon--on {
    display: none; }

.mdc-icon-button--on .mdc-icon-button__icon {
  display: none; }
  .mdc-icon-button--on .mdc-icon-button__icon.mdc-icon-button__icon--on {
    display: inline-block; }

.mdc-icon-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity; }
  .mdc-icon-button::before, .mdc-icon-button::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-icon-button::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-icon-button.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-icon-button.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-icon-button.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-icon-button.mdc-ripple-upgraded--foreground-activation::after {
    animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards; }
  .mdc-icon-button.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: mdc-ripple-fg-opacity-out 150ms;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-icon-button::before, .mdc-icon-button::after {
    top: calc(50% - 50%);
    /* @noflip */
    left: calc(50% - 50%);
    width: 100%;
    height: 100%; }
  .mdc-icon-button.mdc-ripple-upgraded::before, .mdc-icon-button.mdc-ripple-upgraded::after {
    top: var(--mdc-ripple-top, calc(50% - 50%));
    /* @noflip */
    left: var(--mdc-ripple-left, calc(50% - 50%));
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-icon-button.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-icon-button::before, .mdc-icon-button::after {
    background-color: #000; }
  .mdc-icon-button:hover::before {
    opacity: 0.04; }
  .mdc-icon-button:not(.mdc-ripple-upgraded):focus::before, .mdc-icon-button.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-icon-button:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-icon-button:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-icon-button.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.12; }

:root {
  --mdc-layout-grid-margin-desktop: 24px;
  --mdc-layout-grid-gutter-desktop: 24px;
  --mdc-layout-grid-column-width-desktop: 72px;
  --mdc-layout-grid-margin-tablet: 16px;
  --mdc-layout-grid-gutter-tablet: 16px;
  --mdc-layout-grid-column-width-tablet: 72px;
  --mdc-layout-grid-margin-phone: 16px;
  --mdc-layout-grid-gutter-phone: 16px;
  --mdc-layout-grid-column-width-phone: 72px; }

@media (min-width: 840px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 24px;
    padding: var(--mdc-layout-grid-margin-desktop, 24px); } }

@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-tablet, 16px); } }

@media (max-width: 479px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-phone, 16px); } }

@media (min-width: 840px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -12px;
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2 * -1); }
    @supports (display: grid) {
      .mdc-layout-grid__inner {
        display: grid;
        margin: 0;
        grid-gap: 24px;
        grid-gap: var(--mdc-layout-grid-gutter-desktop, 24px);
        grid-template-columns: repeat(12, minmax(0, 1fr)); } } }

@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -8px;
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2 * -1); }
    @supports (display: grid) {
      .mdc-layout-grid__inner {
        display: grid;
        margin: 0;
        grid-gap: 16px;
        grid-gap: var(--mdc-layout-grid-gutter-tablet, 16px);
        grid-template-columns: repeat(8, minmax(0, 1fr)); } } }

@media (max-width: 479px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -8px;
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2 * -1); }
    @supports (display: grid) {
      .mdc-layout-grid__inner {
        display: grid;
        margin: 0;
        grid-gap: 16px;
        grid-gap: var(--mdc-layout-grid-gutter-phone, 16px);
        grid-template-columns: repeat(4, minmax(0, 1fr)); } } }

@media (min-width: 840px) {
  .mdc-layout-grid__cell {
    width: calc(33.33333% - 24px);
    width: calc(33.33333% - var(--mdc-layout-grid-gutter-desktop, 24px));
    box-sizing: border-box;
    margin: 12px;
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2); }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        width: auto;
        grid-column-end: span 4; } }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        margin: 0; } }
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-desktop {
      width: calc(8.33333% - 24px);
      width: calc(8.33333% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-1,
        .mdc-layout-grid__cell--span-1-desktop {
          width: auto;
          grid-column-end: span 1; } }
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-desktop {
      width: calc(16.66667% - 24px);
      width: calc(16.66667% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-2,
        .mdc-layout-grid__cell--span-2-desktop {
          width: auto;
          grid-column-end: span 2; } }
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-desktop {
      width: calc(25% - 24px);
      width: calc(25% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-3,
        .mdc-layout-grid__cell--span-3-desktop {
          width: auto;
          grid-column-end: span 3; } }
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-desktop {
      width: calc(33.33333% - 24px);
      width: calc(33.33333% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-4,
        .mdc-layout-grid__cell--span-4-desktop {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-desktop {
      width: calc(41.66667% - 24px);
      width: calc(41.66667% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-5,
        .mdc-layout-grid__cell--span-5-desktop {
          width: auto;
          grid-column-end: span 5; } }
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-desktop {
      width: calc(50% - 24px);
      width: calc(50% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-6,
        .mdc-layout-grid__cell--span-6-desktop {
          width: auto;
          grid-column-end: span 6; } }
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-desktop {
      width: calc(58.33333% - 24px);
      width: calc(58.33333% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-7,
        .mdc-layout-grid__cell--span-7-desktop {
          width: auto;
          grid-column-end: span 7; } }
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-desktop {
      width: calc(66.66667% - 24px);
      width: calc(66.66667% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-8,
        .mdc-layout-grid__cell--span-8-desktop {
          width: auto;
          grid-column-end: span 8; } }
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-desktop {
      width: calc(75% - 24px);
      width: calc(75% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-9,
        .mdc-layout-grid__cell--span-9-desktop {
          width: auto;
          grid-column-end: span 9; } }
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-desktop {
      width: calc(83.33333% - 24px);
      width: calc(83.33333% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-10,
        .mdc-layout-grid__cell--span-10-desktop {
          width: auto;
          grid-column-end: span 10; } }
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-desktop {
      width: calc(91.66667% - 24px);
      width: calc(91.66667% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-11,
        .mdc-layout-grid__cell--span-11-desktop {
          width: auto;
          grid-column-end: span 11; } }
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-desktop {
      width: calc(100% - 24px);
      width: calc(100% - var(--mdc-layout-grid-gutter-desktop, 24px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-12,
        .mdc-layout-grid__cell--span-12-desktop {
          width: auto;
          grid-column-end: span 12; } } }

@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid__cell {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px));
    box-sizing: border-box;
    margin: 8px;
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2); }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        width: auto;
        grid-column-end: span 4; } }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        margin: 0; } }
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-tablet {
      width: calc(12.5% - 16px);
      width: calc(12.5% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-1,
        .mdc-layout-grid__cell--span-1-tablet {
          width: auto;
          grid-column-end: span 1; } }
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-tablet {
      width: calc(25% - 16px);
      width: calc(25% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-2,
        .mdc-layout-grid__cell--span-2-tablet {
          width: auto;
          grid-column-end: span 2; } }
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-tablet {
      width: calc(37.5% - 16px);
      width: calc(37.5% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-3,
        .mdc-layout-grid__cell--span-3-tablet {
          width: auto;
          grid-column-end: span 3; } }
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-tablet {
      width: calc(50% - 16px);
      width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-4,
        .mdc-layout-grid__cell--span-4-tablet {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-tablet {
      width: calc(62.5% - 16px);
      width: calc(62.5% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-5,
        .mdc-layout-grid__cell--span-5-tablet {
          width: auto;
          grid-column-end: span 5; } }
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-tablet {
      width: calc(75% - 16px);
      width: calc(75% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-6,
        .mdc-layout-grid__cell--span-6-tablet {
          width: auto;
          grid-column-end: span 6; } }
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-tablet {
      width: calc(87.5% - 16px);
      width: calc(87.5% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-7,
        .mdc-layout-grid__cell--span-7-tablet {
          width: auto;
          grid-column-end: span 7; } }
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-tablet {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-8,
        .mdc-layout-grid__cell--span-8-tablet {
          width: auto;
          grid-column-end: span 8; } }
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-tablet {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-9,
        .mdc-layout-grid__cell--span-9-tablet {
          width: auto;
          grid-column-end: span 8; } }
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-tablet {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-10,
        .mdc-layout-grid__cell--span-10-tablet {
          width: auto;
          grid-column-end: span 8; } }
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-tablet {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-11,
        .mdc-layout-grid__cell--span-11-tablet {
          width: auto;
          grid-column-end: span 8; } }
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-tablet {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-12,
        .mdc-layout-grid__cell--span-12-tablet {
          width: auto;
          grid-column-end: span 8; } } }

@media (max-width: 479px) {
  .mdc-layout-grid__cell {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
    box-sizing: border-box;
    margin: 8px;
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2); }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        width: auto;
        grid-column-end: span 4; } }
    @supports (display: grid) {
      .mdc-layout-grid__cell {
        margin: 0; } }
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-phone {
      width: calc(25% - 16px);
      width: calc(25% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-1,
        .mdc-layout-grid__cell--span-1-phone {
          width: auto;
          grid-column-end: span 1; } }
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-phone {
      width: calc(50% - 16px);
      width: calc(50% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-2,
        .mdc-layout-grid__cell--span-2-phone {
          width: auto;
          grid-column-end: span 2; } }
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-phone {
      width: calc(75% - 16px);
      width: calc(75% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-3,
        .mdc-layout-grid__cell--span-3-phone {
          width: auto;
          grid-column-end: span 3; } }
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-phone {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-4,
        .mdc-layout-grid__cell--span-4-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-phone {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-5,
        .mdc-layout-grid__cell--span-5-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-phone {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-6,
        .mdc-layout-grid__cell--span-6-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-phone {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-7,
        .mdc-layout-grid__cell--span-7-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-phone {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-8,
        .mdc-layout-grid__cell--span-8-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-phone {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-9,
        .mdc-layout-grid__cell--span-9-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-phone {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-10,
        .mdc-layout-grid__cell--span-10-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-phone {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-11,
        .mdc-layout-grid__cell--span-11-phone {
          width: auto;
          grid-column-end: span 4; } }
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-phone {
      width: calc(100% - 16px);
      width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px)); }
      @supports (display: grid) {
        .mdc-layout-grid__cell--span-12,
        .mdc-layout-grid__cell--span-12-phone {
          width: auto;
          grid-column-end: span 4; } } }

.mdc-layout-grid__cell--order-1 {
  order: 1; }

.mdc-layout-grid__cell--order-2 {
  order: 2; }

.mdc-layout-grid__cell--order-3 {
  order: 3; }

.mdc-layout-grid__cell--order-4 {
  order: 4; }

.mdc-layout-grid__cell--order-5 {
  order: 5; }

.mdc-layout-grid__cell--order-6 {
  order: 6; }

.mdc-layout-grid__cell--order-7 {
  order: 7; }

.mdc-layout-grid__cell--order-8 {
  order: 8; }

.mdc-layout-grid__cell--order-9 {
  order: 9; }

.mdc-layout-grid__cell--order-10 {
  order: 10; }

.mdc-layout-grid__cell--order-11 {
  order: 11; }

.mdc-layout-grid__cell--order-12 {
  order: 12; }

.mdc-layout-grid__cell--align-top {
  align-self: flex-start; }
  @supports (display: grid) {
    .mdc-layout-grid__cell--align-top {
      align-self: start; } }

.mdc-layout-grid__cell--align-middle {
  align-self: center; }

.mdc-layout-grid__cell--align-bottom {
  align-self: flex-end; }
  @supports (display: grid) {
    .mdc-layout-grid__cell--align-bottom {
      align-self: end; } }

@media (min-width: 840px) {
  .mdc-layout-grid--fixed-column-width {
    width: 1176px;
    width: calc( var(--mdc-layout-grid-column-width-desktop, 72px) * 12 + var(--mdc-layout-grid-gutter-desktop, 24px) * 11 + var(--mdc-layout-grid-margin-desktop, 24px) * 2); } }

@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid--fixed-column-width {
    width: 720px;
    width: calc( var(--mdc-layout-grid-column-width-tablet, 72px) * 8 + var(--mdc-layout-grid-gutter-tablet, 16px) * 7 + var(--mdc-layout-grid-margin-tablet, 16px) * 2); } }

@media (max-width: 479px) {
  .mdc-layout-grid--fixed-column-width {
    width: 368px;
    width: calc( var(--mdc-layout-grid-column-width-phone, 72px) * 4 + var(--mdc-layout-grid-gutter-phone, 16px) * 3 + var(--mdc-layout-grid-margin-phone, 16px) * 2); } }

.mdc-layout-grid--align-left {
  margin-right: auto;
  margin-left: 0; }

.mdc-layout-grid--align-right {
  margin-right: 0;
  margin-left: auto; }

.mdc-list {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 600;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  /* @alternate */
  line-height: 1.5rem;
  margin: 0;
  padding: 8px 0;
  list-style-type: none;
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87)); }

.mdc-list-item__secondary-text {
  color: rgba(0, 0, 0, 0.6);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.6)); }

.mdc-list-item__graphic {
  background-color: transparent; }

.mdc-list-item__graphic {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38)); }

.mdc-list-item__meta {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38)); }

.mdc-list-group__subheader {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87)); }

.mdc-list--dense {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: .812rem; }

.mdc-list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  height: 48px;
  padding: 0 16px;
  overflow: hidden; }
  .mdc-list-item:focus {
    outline: none; }

.mdc-list-item--selected,
.mdc-list-item--activated {
  color: #904778;
  /* @alternate */
  color: var(--mdc-theme-primary, #904778); }
  .mdc-list-item--selected .mdc-list-item__graphic,
  .mdc-list-item--activated .mdc-list-item__graphic {
    color: #904778;
    /* @alternate */
    color: var(--mdc-theme-primary, #904778); }

.mdc-list-item--disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38)); }

.mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 32px;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  fill: currentColor; }
  .mdc-list-item[dir="rtl"] .mdc-list-item__graphic,
  [dir="rtl"] .mdc-list-item .mdc-list-item__graphic {
    /* @noflip */
    margin-left: 32px;
    /* @noflip */
    margin-right: 0; }

.mdc-list .mdc-list-item__graphic {
  display: inline-flex; }

.mdc-list-item__meta {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0; }
  .mdc-list-item[dir="rtl"] .mdc-list-item__meta,
  [dir="rtl"] .mdc-list-item .mdc-list-item__meta {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: auto; }

.mdc-list-item__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.mdc-list-item__text[for] {
  pointer-events: none; }

.mdc-list-item__primary-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
  display: block; }
  .mdc-list-item__primary-text::before {
    display: inline-block;
    width: 0;
    height: 32px;
    content: "";
    vertical-align: 0; }
  .mdc-list-item__primary-text::after {
    display: inline-block;
    width: 0;
    height: 20px;
    content: "";
    vertical-align: -20px; }
  .mdc-list--dense .mdc-list-item__primary-text {
    display: block;
    margin-top: 0;
    /* @alternate */
    line-height: normal;
    margin-bottom: -20px; }
    .mdc-list--dense .mdc-list-item__primary-text::before {
      display: inline-block;
      width: 0;
      height: 24px;
      content: "";
      vertical-align: 0; }
    .mdc-list--dense .mdc-list-item__primary-text::after {
      display: inline-block;
      width: 0;
      height: 20px;
      content: "";
      vertical-align: -20px; }

.mdc-list-item__secondary-text {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.01786em;
  text-decoration: inherit;
  text-transform: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  display: block; }
  .mdc-list-item__secondary-text::before {
    display: inline-block;
    width: 0;
    height: 20px;
    content: "";
    vertical-align: 0; }
  .mdc-list--dense .mdc-list-item__secondary-text {
    display: block;
    margin-top: 0;
    /* @alternate */
    line-height: normal;
    font-size: inherit; }
    .mdc-list--dense .mdc-list-item__secondary-text::before {
      display: inline-block;
      width: 0;
      height: 20px;
      content: "";
      vertical-align: 0; }

.mdc-list--dense .mdc-list-item {
  height: 40px; }

.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 36px;
  width: 20px;
  height: 20px; }
  .mdc-list-item[dir="rtl"] .mdc-list--dense .mdc-list-item__graphic,
  [dir="rtl"] .mdc-list-item .mdc-list--dense .mdc-list-item__graphic {
    /* @noflip */
    margin-left: 36px;
    /* @noflip */
    margin-right: 0; }

.mdc-list--avatar-list .mdc-list-item {
  height: 56px; }

.mdc-list--avatar-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%; }
  .mdc-list-item[dir="rtl"] .mdc-list--avatar-list .mdc-list-item__graphic,
  [dir="rtl"] .mdc-list-item .mdc-list--avatar-list .mdc-list-item__graphic {
    /* @noflip */
    margin-left: 16px;
    /* @noflip */
    margin-right: 0; }

.mdc-list--two-line .mdc-list-item__text {
  align-self: flex-start; }

.mdc-list--two-line .mdc-list-item {
  height: 72px; }

.mdc-list--two-line.mdc-list--dense .mdc-list-item,
.mdc-list--avatar-list.mdc-list--dense .mdc-list-item {
  height: 60px; }

.mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 20px;
  width: 36px;
  height: 36px; }
  .mdc-list-item[dir="rtl"] .mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic,
  [dir="rtl"] .mdc-list-item .mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic {
    /* @noflip */
    margin-left: 20px;
    /* @noflip */
    margin-right: 0; }

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item {
  cursor: pointer; }

a.mdc-list-item {
  color: inherit;
  text-decoration: none; }

.mdc-list-divider {
  height: 0;
  margin: 0;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid; }

.mdc-list-divider {
  border-bottom-color: rgba(0, 0, 0, 0.12); }

.mdc-list-divider--padded {
  margin: 0 16px; }

.mdc-list-divider--inset {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px); }
  .mdc-list-group[dir="rtl"] .mdc-list-divider--inset,
  [dir="rtl"] .mdc-list-group .mdc-list-divider--inset {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 72px; }

.mdc-list-divider--inset.mdc-list-divider--padded {
  width: calc(100% - 72px - 16px); }

.mdc-list-group .mdc-list {
  padding: 0; }

.mdc-list-group__subheader {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 600;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  margin: 0.75rem 16px; }

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-activation::after {
    animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: mdc-ripple-fg-opacity-out 150ms;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::after {
    background-color: #000; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:hover::before {
    opacity: 0.04; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.12; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.12; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::before {
    opacity: 0.12; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::after {
    background-color: #904778; }
    @supports not (-ms-ime-align: auto) {
      :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::after {
        /* @alternate */
        background-color: var(--mdc-theme-primary, #904778); } }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:hover::before {
    opacity: 0.16; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.24; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.24; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.24; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::before {
    opacity: 0.08; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::after {
    background-color: #904778; }
    @supports not (-ms-ime-align: auto) {
      :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::after {
        /* @alternate */
        background-color: var(--mdc-theme-primary, #904778); } }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:hover::before {
    opacity: 0.12; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.2; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.2; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.2; }

.material-icons--ripple-surface {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity; }
  .material-icons--ripple-surface::before, .material-icons--ripple-surface::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .material-icons--ripple-surface::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .material-icons--ripple-surface.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .material-icons--ripple-surface.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .material-icons--ripple-surface.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .material-icons--ripple-surface.mdc-ripple-upgraded--foreground-activation::after {
    animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards; }
  .material-icons--ripple-surface.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: mdc-ripple-fg-opacity-out 150ms;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .material-icons--ripple-surface::before, .material-icons--ripple-surface::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .material-icons--ripple-surface.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .material-icons--ripple-surface::before, .material-icons--ripple-surface::after {
    background-color: #000; }
  .material-icons--ripple-surface:hover::before {
    opacity: 0.04; }
  .material-icons--ripple-surface:not(.mdc-ripple-upgraded):focus::before, .material-icons--ripple-surface.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .material-icons--ripple-surface:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .material-icons--ripple-surface:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.12; }
  .material-icons--ripple-surface.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.12; }

.mdc-menu-surface {
  display: none;
  position: absolute;
  box-sizing: border-box;
  max-width: calc(100vw - 32px);
  max-height: calc(100vh - 32px);
  margin: 0;
  padding: 0;
  transform: scale(1);
  transform-origin: top left;
  opacity: 0;
  overflow: auto;
  will-change: transform, opacity;
  z-index: 8;
  transition: opacity 0.03s linear, transform 0.12s cubic-bezier(0, 0, 0.2, 1);
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
  border-radius: 4px;
  /* @noflip */
  transform-origin-left: top left;
  /* @noflip */
  transform-origin-right: top right; }
  .mdc-menu-surface:focus {
    outline: none; }
  .mdc-menu-surface--open {
    display: inline-block;
    transform: scale(1);
    opacity: 1; }
  .mdc-menu-surface--animating-open {
    display: inline-block;
    transform: scale(0.8);
    opacity: 0; }
  .mdc-menu-surface--animating-closed {
    display: inline-block;
    opacity: 0;
    transition: opacity 0.075s linear; }
  [dir="rtl"] .mdc-menu-surface, .mdc-menu-surface[dir="rtl"] {
    /* @noflip */
    transform-origin-left: top right;
    /* @noflip */
    transform-origin-right: top left; }

.mdc-menu-surface--anchor {
  position: relative;
  overflow: visible; }

.mdc-menu-surface--fixed {
  position: fixed; }

.mdc-radio {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  display: inline-block;
  position: relative;
  flex: 0 0 auto;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  padding: 10px;
  cursor: pointer;
  /* @alternate */
  will-change: opacity, transform, border-color, color; }
  .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
    border-color: rgba(0, 0, 0, 0.54); }
  .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
    border-color: #b23072;
    /* @alternate */
    border-color: var(--mdc-theme-secondary, #b23072); }
  .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
    border-color: #b23072;
    /* @alternate */
    border-color: var(--mdc-theme-secondary, #b23072); }
  .mdc-radio .mdc-radio__background::before {
    background-color: #b23072; }
    @supports not (-ms-ime-align: auto) {
      .mdc-radio .mdc-radio__background::before {
        /* @alternate */
        background-color: var(--mdc-theme-secondary, #b23072); } }
  .mdc-radio::before, .mdc-radio::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-radio::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-radio.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-radio.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-radio.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-radio.mdc-ripple-upgraded--foreground-activation::after {
    animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards; }
  .mdc-radio.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: mdc-ripple-fg-opacity-out 150ms;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-radio::before, .mdc-radio::after {
    top: calc(50% - 50%);
    /* @noflip */
    left: calc(50% - 50%);
    width: 100%;
    height: 100%; }
  .mdc-radio.mdc-ripple-upgraded::before, .mdc-radio.mdc-ripple-upgraded::after {
    top: var(--mdc-ripple-top, calc(50% - 50%));
    /* @noflip */
    left: var(--mdc-ripple-left, calc(50% - 50%));
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-radio.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-radio::before, .mdc-radio::after {
    background-color: #b23072; }
    @supports not (-ms-ime-align: auto) {
      .mdc-radio::before, .mdc-radio::after {
        /* @alternate */
        background-color: var(--mdc-theme-secondary, #b23072); } }
  .mdc-radio:hover::before {
    opacity: 0.04; }
  .mdc-radio:not(.mdc-ripple-upgraded):focus::before, .mdc-radio.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-radio:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-radio:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-radio.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.12; }
  .mdc-radio__background {
    display: inline-block;
    position: absolute;
    left: 10px;
    box-sizing: border-box;
    width: 50%;
    height: 50%; }
    .mdc-radio__background::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: scale(0, 0);
      transition: opacity 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
      border-radius: 50%;
      opacity: 0;
      pointer-events: none;
      content: ""; }
  .mdc-radio__outer-circle {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    transition: border-color 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    border-width: 2px;
    border-style: solid;
    border-radius: 50%; }
  .mdc-radio__inner-circle {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    transform: scale(0, 0);
    transition: transform 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1), border-color 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
    border-width: 10px;
    border-style: solid;
    border-radius: 50%; }
  .mdc-radio__native-control {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    opacity: 0;
    cursor: inherit;
    z-index: 1; }
  .mdc-radio.mdc-ripple-upgraded--background-focused .mdc-radio__background::before {
    content: none; }

.mdc-radio__native-control:checked + .mdc-radio__background,
.mdc-radio__native-control:disabled + .mdc-radio__background {
  transition: opacity 120ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1); }
  .mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__outer-circle,
  .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__outer-circle {
    transition: border-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1); }
  .mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__inner-circle,
  .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__inner-circle {
    transition: transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1), border-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1); }

.mdc-radio--disabled {
  cursor: default;
  pointer-events: none; }

.mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__inner-circle {
  transform: scale(0.5);
  transition: transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1), border-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1); }

.mdc-radio__native-control:disabled + .mdc-radio__background,
[aria-disabled="true"] .mdc-radio__native-control + .mdc-radio__background {
  cursor: default; }
  .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__outer-circle,
  [aria-disabled="true"] .mdc-radio__native-control + .mdc-radio__background .mdc-radio__outer-circle {
    border-color: rgba(0, 0, 0, 0.26); }
  .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__inner-circle,
  [aria-disabled="true"] .mdc-radio__native-control + .mdc-radio__background .mdc-radio__inner-circle {
    border-color: rgba(0, 0, 0, 0.26); }

.mdc-radio__native-control:focus + .mdc-radio__background::before {
  transform: scale(2, 2);
  transition: opacity 120ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
  opacity: 0.12; }

.mdc-form-field {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.01786em;
  text-decoration: inherit;
  text-transform: inherit;
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  display: inline-flex;
  align-items: center;
  vertical-align: middle; }
  .mdc-form-field > label {
    order: 0;
    margin-right: auto;
    padding-left: 4px; }
  [dir="rtl"] .mdc-form-field > label, .mdc-form-field[dir="rtl"] > label {
    margin-left: auto;
    padding-right: 4px; }

.mdc-form-field--align-end > label {
  order: -1;
  margin-left: auto;
  padding-right: 4px; }

[dir="rtl"] .mdc-form-field--align-end > label, .mdc-form-field--align-end[dir="rtl"] > label {
  margin-right: auto;
  padding-left: 4px; }

.mdc-ripple-surface {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  position: relative;
  outline: none;
  overflow: hidden; }
  .mdc-ripple-surface::before, .mdc-ripple-surface::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-ripple-surface::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-ripple-surface.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-ripple-surface.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-ripple-surface.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-ripple-surface.mdc-ripple-upgraded--foreground-activation::after {
    animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards; }
  .mdc-ripple-surface.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: mdc-ripple-fg-opacity-out 150ms;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-ripple-surface::before, .mdc-ripple-surface::after {
    background-color: #000; }
  .mdc-ripple-surface:hover::before {
    opacity: 0.04; }
  .mdc-ripple-surface:not(.mdc-ripple-upgraded):focus::before, .mdc-ripple-surface.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-ripple-surface:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-ripple-surface:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-ripple-surface.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.12; }
  .mdc-ripple-surface::before, .mdc-ripple-surface::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-ripple-surface.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-ripple-surface[data-mdc-ripple-is-unbounded] {
    overflow: visible; }
    .mdc-ripple-surface[data-mdc-ripple-is-unbounded]::before, .mdc-ripple-surface[data-mdc-ripple-is-unbounded]::after {
      top: calc(50% - 50%);
      /* @noflip */
      left: calc(50% - 50%);
      width: 100%;
      height: 100%; }
    .mdc-ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::before, .mdc-ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::after {
      top: var(--mdc-ripple-top, calc(50% - 50%));
      /* @noflip */
      left: var(--mdc-ripple-left, calc(50% - 50%));
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%); }
    .mdc-ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::after {
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-ripple-surface--primary::before, .mdc-ripple-surface--primary::after {
    background-color: #904778; }
    @supports not (-ms-ime-align: auto) {
      .mdc-ripple-surface--primary::before, .mdc-ripple-surface--primary::after {
        /* @alternate */
        background-color: var(--mdc-theme-primary, #904778); } }
  .mdc-ripple-surface--primary:hover::before {
    opacity: 0.04; }
  .mdc-ripple-surface--primary:not(.mdc-ripple-upgraded):focus::before, .mdc-ripple-surface--primary.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-ripple-surface--primary:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-ripple-surface--primary:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-ripple-surface--primary.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.12; }
  .mdc-ripple-surface--accent::before, .mdc-ripple-surface--accent::after {
    background-color: #b23072; }
    @supports not (-ms-ime-align: auto) {
      .mdc-ripple-surface--accent::before, .mdc-ripple-surface--accent::after {
        /* @alternate */
        background-color: var(--mdc-theme-secondary, #b23072); } }
  .mdc-ripple-surface--accent:hover::before {
    opacity: 0.04; }
  .mdc-ripple-surface--accent:not(.mdc-ripple-upgraded):focus::before, .mdc-ripple-surface--accent.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-ripple-surface--accent:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-ripple-surface--accent:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-ripple-surface--accent.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.12; }

@keyframes mdc-select-float-native-control {
  0% {
    transform: translateY(8px);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

.mdc-line-ripple {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  transform: scaleX(0);
  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: 2; }

.mdc-line-ripple--active {
  transform: scaleX(1);
  opacity: 1; }

.mdc-line-ripple--deactivating {
  opacity: 0; }

.mdc-notched-outline {
  display: flex;
  position: absolute;
  right: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 100%;
  /* @noflip */
  text-align: left;
  pointer-events: none; }
  [dir="rtl"] .mdc-notched-outline, .mdc-notched-outline[dir="rtl"] {
    /* @noflip */
    text-align: right; }
  .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
    box-sizing: border-box;
    height: 100%;
    transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
    border-top: 1px solid;
    border-bottom: 1px solid;
    pointer-events: none; }
  .mdc-notched-outline__leading {
    /* @noflip */
    border-left: 1px solid;
    /* @noflip */
    border-right: none;
    width: 12px; }
    [dir="rtl"] .mdc-notched-outline__leading, .mdc-notched-outline__leading[dir="rtl"] {
      /* @noflip */
      border-left: none;
      /* @noflip */
      border-right: 1px solid; }
  .mdc-notched-outline__trailing {
    /* @noflip */
    border-left: none;
    /* @noflip */
    border-right: 1px solid;
    flex-grow: 1; }
    [dir="rtl"] .mdc-notched-outline__trailing, .mdc-notched-outline__trailing[dir="rtl"] {
      /* @noflip */
      border-left: 1px solid;
      /* @noflip */
      border-right: none; }
  .mdc-notched-outline__notch {
    flex: 0 0 auto;
    width: auto;
    max-width: calc(100% - 12px * 2); }
  .mdc-notched-outline .mdc-floating-label {
    display: inline-block;
    position: relative;
    top: 17px;
    bottom: auto;
    max-width: 100%; }
  .mdc-notched-outline .mdc-floating-label--float-above {
    text-overflow: clip; }
  .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    max-width: calc(100% / .75); }

.mdc-notched-outline--notched .mdc-notched-outline__notch {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 8px;
  border-top: none; }
  [dir="rtl"] .mdc-notched-outline--notched .mdc-notched-outline__notch, .mdc-notched-outline--notched .mdc-notched-outline__notch[dir="rtl"] {
    /* @noflip */
    padding-left: 8px;
    /* @noflip */
    padding-right: 0; }

.mdc-notched-outline--no-label .mdc-notched-outline__notch {
  padding: 0; }

.mdc-floating-label {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 600;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  position: absolute;
  /* @noflip */
  left: 0;
  /* @noflip */
  transform-origin: left top;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  /* @alternate */
  line-height: 1.15rem;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: text;
  overflow: hidden;
  will-change: transform; }
  [dir="rtl"] .mdc-floating-label, .mdc-floating-label[dir="rtl"] {
    /* @noflip */
    right: 0;
    /* @noflip */
    left: auto;
    /* @noflip */
    transform-origin: right top;
    /* @noflip */
    text-align: right; }

.mdc-floating-label--float-above {
  cursor: auto; }

.mdc-floating-label--float-above {
  transform: translateY(-50%) scale(0.75); }

.mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-standard 250ms 1; }

@keyframes mdc-floating-label-shake-float-above-standard {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - 0%)) translateY(-50%) scale(0.75); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - 0%)) translateY(-50%) scale(0.75); }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75); } }

.mdc-select--with-leading-icon:not(.mdc-select--disabled) .mdc-select__icon {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000); }

.mdc-select--with-leading-icon .mdc-select__icon {
  display: inline-block;
  position: absolute;
  bottom: 16px;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  fill: currentColor;
  opacity: 0.54;
  text-decoration: none;
  cursor: pointer;
  user-select: none; }

.mdc-select__icon:not([tabindex]),
.mdc-select__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none; }

.mdc-select-helper-text {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.03333em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  will-change: opacity; }
  .mdc-select-helper-text::before {
    display: inline-block;
    width: 0;
    height: 16px;
    content: "";
    vertical-align: 0; }

.mdc-select-helper-text--persistent {
  transition: none;
  opacity: 1;
  will-change: initial; }

.mdc-select {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  height: 56px;
  overflow: hidden;
  /* @alternate */
  will-change: opacity, transform, color; }
  .mdc-select:not(.mdc-select--disabled) {
    background-color: whitesmoke; }
  .mdc-select::before, .mdc-select::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-select::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-select.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-select.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-select.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-select.mdc-ripple-upgraded--foreground-activation::after {
    animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards; }
  .mdc-select.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: mdc-ripple-fg-opacity-out 150ms;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-select::before, .mdc-select::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-select.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-select::before, .mdc-select::after {
    background-color: rgba(0, 0, 0, 0.87); }
  .mdc-select:hover::before {
    opacity: 0.04; }
  .mdc-select:not(.mdc-ripple-upgraded):focus::before, .mdc-select.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control,
  .mdc-select:not(.mdc-select--disabled) .mdc-select__selected-text {
    color: rgba(0, 0, 0, 0.87); }
  .mdc-select:not(.mdc-select--disabled) .mdc-floating-label {
    color: rgba(0, 0, 0, 0.6); }
  .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control,
  .mdc-select:not(.mdc-select--disabled) .mdc-select__selected-text {
    border-bottom-color: rgba(0, 0, 0, 0.42); }
  .mdc-select:not(.mdc-select--disabled) + .mdc-select-helper-text {
    color: rgba(0, 0, 0, 0.6); }
  .mdc-select, .mdc-select__native-control {
    border-radius: 4px 4px 0 0; }
  .mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-line-ripple {
    background-color: #904778;
    /* @alternate */
    background-color: var(--mdc-theme-primary, #904778); }
  .mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
    color: rgba(144, 71, 120, 0.87); }
  .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control:hover {
    border-bottom-color: rgba(0, 0, 0, 0.87); }
  .mdc-select .mdc-floating-label--float-above {
    transform: translateY(-70%) scale(0.75); }
  .mdc-select .mdc-floating-label {
    /* @noflip */
    left: 16px;
    /* @noflip */
    right: initial;
    top: 21px;
    pointer-events: none; }
    [dir="rtl"] .mdc-select .mdc-floating-label, .mdc-select .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 16px; }
  .mdc-select.mdc-select--with-leading-icon .mdc-floating-label {
    /* @noflip */
    left: 48px;
    /* @noflip */
    right: initial; }
    [dir="rtl"] .mdc-select.mdc-select--with-leading-icon .mdc-floating-label, .mdc-select.mdc-select--with-leading-icon .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 48px; }
  .mdc-select.mdc-select--outlined .mdc-floating-label {
    /* @noflip */
    left: 4px;
    /* @noflip */
    right: initial;
    top: 17px; }
    [dir="rtl"] .mdc-select.mdc-select--outlined .mdc-floating-label, .mdc-select.mdc-select--outlined .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 4px; }
  .mdc-select.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label {
    /* @noflip */
    left: 36px;
    /* @noflip */
    right: initial; }
    [dir="rtl"] .mdc-select.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label, .mdc-select.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 36px; }
    .mdc-select.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above {
      /* @noflip */
      left: 36px;
      /* @noflip */
      right: initial; }
      [dir="rtl"] .mdc-select.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above, .mdc-select.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above[dir="rtl"] {
        /* @noflip */
        left: initial;
        /* @noflip */
        right: 36px; }
  .mdc-select__dropdown-icon {
    background: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23000%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.54%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E") no-repeat center;
    /* @noflip */
    left: auto;
    /* @noflip */
    right: 8px;
    position: absolute;
    bottom: 16px;
    width: 24px;
    height: 24px;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
    pointer-events: none; }
    [dir="rtl"] .mdc-select__dropdown-icon, .mdc-select__dropdown-icon[dir="rtl"] {
      /* @noflip */
      left: 8px;
      /* @noflip */
      right: auto; }
    .mdc-select--focused .mdc-select__dropdown-icon {
      background: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23904778%22%20fill-rule%3D%22evenodd%22%20opacity%3D%221%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E") no-repeat center;
      transform: rotate(180deg) translateY(-5px);
      transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1); }
  .mdc-select__native-control {
    padding-top: 20px; }
  .mdc-select.mdc-select--focused .mdc-line-ripple::after {
    transform: scale(1, 2);
    opacity: 1; }

.mdc-select + .mdc-select-helper-text {
  margin-right: 12px;
  margin-left: 12px; }

.mdc-select--outlined + .mdc-select-helper-text {
  margin-right: 16px;
  margin-left: 16px; }

.mdc-select--focused + .mdc-select-helper-text:not(.mdc-select-helper-text--validation-msg) {
  opacity: 1; }

.mdc-select__selected-text {
  min-width: 200px;
  padding-top: 22px; }

.mdc-select__native-control,
.mdc-select__selected-text {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 52px;
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 600;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  box-sizing: border-box;
  width: 100%;
  height: 56px;
  padding-top: 20px;
  padding-bottom: 4px;
  border: none;
  border-bottom: 1px solid;
  outline: none;
  background-color: transparent;
  color: inherit;
  white-space: nowrap;
  cursor: pointer;
  appearance: none; }
  [dir="rtl"] .mdc-select__native-control, .mdc-select__native-control[dir="rtl"], [dir="rtl"]
  .mdc-select__selected-text,
  .mdc-select__selected-text[dir="rtl"] {
    /* @noflip */
    padding-left: 52px;
    /* @noflip */
    padding-right: 16px; }
  .mdc-select__native-control::-ms-expand,
  .mdc-select__selected-text::-ms-expand {
    display: none; }
  .mdc-select__native-control::-ms-value,
  .mdc-select__selected-text::-ms-value {
    background-color: transparent;
    color: inherit; }

@-moz-document url-prefix("") {
  .mdc-select__native-control,
  .mdc-select__selected-text {
    text-indent: -2px; } }

.mdc-select--outlined {
  border: none;
  overflow: visible; }
  .mdc-select--outlined:not(.mdc-select--disabled) {
    background-color: transparent; }
  .mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
  .mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
  .mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
    border-color: rgba(0, 0, 0, 0.24); }
  .mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__native-control:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
  .mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__native-control:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
  .mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__native-control:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing,
  .mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__selected-text:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
  .mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__selected-text:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
  .mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__selected-text:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing {
    border-color: rgba(0, 0, 0, 0.87); }
  .mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
  .mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
  .mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
    border-width: 2px; }
  .mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
  .mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
  .mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
    border-color: #904778;
    /* @alternate */
    border-color: var(--mdc-theme-primary, #904778); }
  .mdc-select--outlined .mdc-floating-label--shake {
    animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1; }
  .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    /* @noflip */
    border-radius: 4px 0 0 4px; }
    [dir="rtl"] .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading, .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading[dir="rtl"] {
      /* @noflip */
      border-radius: 0 4px 4px 0; }
  .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
    /* @noflip */
    border-radius: 0 4px 4px 0; }
    [dir="rtl"] .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing, .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing[dir="rtl"] {
      /* @noflip */
      border-radius: 4px 0 0 4px; }
  .mdc-select--outlined .mdc-select__native-control {
    border-radius: 4px; }
  .mdc-select--outlined::before, .mdc-select--outlined::after {
    content: none; }
  .mdc-select--outlined:not(.mdc-select--disabled) {
    background-color: transparent; }
  .mdc-select--outlined .mdc-floating-label--float-above {
    transform: translateY(-144%) scale(1); }
  .mdc-select--outlined .mdc-floating-label--float-above {
    font-size: 0.75rem; }
  .mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  .mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    transform: translateY(-130%) scale(0.75); }
  .mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  .mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    font-size: 1rem; }
  .mdc-select--outlined .mdc-select__native-control,
  .mdc-select--outlined .mdc-select__selected-text {
    /* @noflip */
    padding-left: 16px;
    /* @noflip */
    padding-right: 52px;
    display: flex;
    padding-top: 12px;
    padding-bottom: 12px;
    border: none;
    background-color: transparent;
    z-index: 1; }
    [dir="rtl"] .mdc-select--outlined .mdc-select__native-control, .mdc-select--outlined .mdc-select__native-control[dir="rtl"], [dir="rtl"]
    .mdc-select--outlined .mdc-select__selected-text,
    .mdc-select--outlined .mdc-select__selected-text[dir="rtl"] {
      /* @noflip */
      padding-left: 52px;
      /* @noflip */
      padding-right: 16px; }
  .mdc-select--outlined .mdc-select__selected-text {
    padding-top: 14px; }
  .mdc-select--outlined .mdc-select__icon {
    z-index: 2; }
  .mdc-select--outlined .mdc-floating-label {
    line-height: 1.15rem;
    pointer-events: auto; }

.mdc-select--invalid:not(.mdc-select--disabled) .mdc-floating-label {
  color: #cc0000;
  /* @alternate */
  color: var(--mdc-theme-error, #cc0000); }

.mdc-select--invalid:not(.mdc-select--disabled) .mdc-select__native-control,
.mdc-select--invalid:not(.mdc-select--disabled) .mdc-select__selected-text {
  border-bottom-color: #cc0000;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #cc0000); }

.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-line-ripple {
  background-color: #cc0000;
  /* @alternate */
  background-color: var(--mdc-theme-error, #cc0000); }

.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
  color: #cc0000; }

.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--invalid + .mdc-select-helper-text--validation-msg {
  color: #cc0000;
  /* @alternate */
  color: var(--mdc-theme-error, #cc0000); }

.mdc-select--invalid:not(.mdc-select--disabled) .mdc-select__native-control:hover {
  border-bottom-color: #cc0000;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #cc0000); }

.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: #cc0000;
  /* @alternate */
  border-color: var(--mdc-theme-error, #cc0000); }

.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__native-control:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__native-control:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__native-control:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__selected-text:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__selected-text:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__selected-text:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #cc0000;
  /* @alternate */
  border-color: var(--mdc-theme-error, #cc0000); }

.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-width: 2px; }

.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #cc0000;
  /* @alternate */
  border-color: var(--mdc-theme-error, #cc0000); }

.mdc-select--invalid .mdc-select__dropdown-icon {
  background: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23cc0000%22%20fill-rule%3D%22evenodd%22%20opacity%3D%221%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E") no-repeat center; }

.mdc-select--invalid + .mdc-select-helper-text--validation-msg {
  opacity: 1; }

.mdc-select--required .mdc-floating-label::after {
  content: "*"; }

.mdc-select--disabled {
  background-color: #fafafa;
  cursor: default;
  pointer-events: none; }
  .mdc-select--disabled .mdc-floating-label {
    color: rgba(0, 0, 0, 0.37); }
  .mdc-select--disabled .mdc-select__dropdown-icon {
    background: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23000%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.37%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E") no-repeat center; }
  .mdc-select--disabled .mdc-line-ripple {
    display: none; }
  .mdc-select--disabled .mdc-select__icon {
    color: rgba(0, 0, 0, 0.37); }
  .mdc-select--disabled .mdc-select__native-control,
  .mdc-select--disabled .mdc-select__selected-text {
    color: rgba(0, 0, 0, 0.37);
    border-bottom-style: dotted; }
  .mdc-select--disabled .mdc-select__selected-text {
    pointer-events: none; }
  .mdc-select--disabled.mdc-select--outlined {
    background-color: transparent; }
    .mdc-select--disabled.mdc-select--outlined .mdc-select__native-control,
    .mdc-select--disabled.mdc-select--outlined .mdc-select__selected-text {
      border-bottom-style: none; }
    .mdc-select--disabled.mdc-select--outlined .mdc-notched-outline__leading,
    .mdc-select--disabled.mdc-select--outlined .mdc-notched-outline__notch,
    .mdc-select--disabled.mdc-select--outlined .mdc-notched-outline__trailing {
      border-color: rgba(0, 0, 0, 0.16); }

.mdc-select--with-leading-icon .mdc-select__icon {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-select--with-leading-icon .mdc-select__icon, .mdc-select--with-leading-icon .mdc-select__icon[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 16px; }

.mdc-select--with-leading-icon .mdc-select__native-control,
.mdc-select--with-leading-icon .mdc-select__selected-text {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 32px; }
  [dir="rtl"] .mdc-select--with-leading-icon .mdc-select__native-control, .mdc-select--with-leading-icon .mdc-select__native-control[dir="rtl"], [dir="rtl"]
  .mdc-select--with-leading-icon .mdc-select__selected-text,
  .mdc-select--with-leading-icon .mdc-select__selected-text[dir="rtl"] {
    /* @noflip */
    padding-left: 32px;
    /* @noflip */
    padding-right: 48px; }

.mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--float-above {
  transform: translateY(-144%) translateX(-32px) scale(1); }
  [dir="rtl"] .mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--float-above, .mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--float-above[dir="rtl"] {
    transform: translateY(-144%) translateX(32px) scale(1); }

.mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem; }

.mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--with-leading-icon.mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-130%) translateX(-32px) scale(0.75); }
  [dir="rtl"] .mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above, .mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir="rtl"], [dir="rtl"]
  .mdc-select--with-leading-icon.mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  .mdc-select--with-leading-icon.mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir="rtl"] {
    transform: translateY(-130%) translateX(32px) scale(0.75); }

.mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--with-leading-icon.mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem; }

.mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon 250ms 1; }

[dir="rtl"] .mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--shake, .mdc-select--with-leading-icon.mdc-select--outlined[dir="rtl"] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon-rtl 250ms 1; }

.mdc-select--with-leading-icon.mdc-select__menu .mdc-list-item__text {
  /* @noflip */
  padding-left: 32px;
  /* @noflip */
  padding-right: 32px; }
  [dir="rtl"] .mdc-select--with-leading-icon.mdc-select__menu .mdc-list-item__text, .mdc-select--with-leading-icon.mdc-select__menu .mdc-list-item__text[dir="rtl"] {
    /* @noflip */
    padding-left: 32px;
    /* @noflip */
    padding-right: 32px; }

.mdc-select__menu .mdc-list .mdc-list-item--selected {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000); }
  .mdc-select__menu .mdc-list .mdc-list-item--selected::before, .mdc-select__menu .mdc-list .mdc-list-item--selected::after {
    background-color: #000; }
    @supports not (-ms-ime-align: auto) {
      .mdc-select__menu .mdc-list .mdc-list-item--selected::before, .mdc-select__menu .mdc-list .mdc-list-item--selected::after {
        /* @alternate */
        background-color: var(--mdc-theme-on-surface, #000); } }
  .mdc-select__menu .mdc-list .mdc-list-item--selected:hover::before {
    opacity: 0.04; }
  .mdc-select__menu .mdc-list .mdc-list-item--selected:not(.mdc-ripple-upgraded):focus::before, .mdc-select__menu .mdc-list .mdc-list-item--selected.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-select__menu .mdc-list .mdc-list-item--selected:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-select__menu .mdc-list .mdc-list-item--selected:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-select__menu .mdc-list .mdc-list-item--selected.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.12; }

@keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon {
  0% {
    transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - 32px)) translateY(-130%) scale(0.75); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - 32px)) translateY(-130%) scale(0.75); }
  100% {
    transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75); } }

@keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon-rtl {
  0% {
    transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - -32px)) translateY(-130%) scale(0.75); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - -32px)) translateY(-130%) scale(0.75); }
  100% {
    transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75); } }

.mdc-text-field--with-leading-icon .mdc-text-field__icon,
.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  position: absolute;
  bottom: 16px;
  cursor: pointer; }

.mdc-text-field__icon:not([tabindex]),
.mdc-text-field__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none; }

.mdc-text-field-helper-text {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.03333em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  will-change: opacity; }
  .mdc-text-field-helper-text::before {
    display: inline-block;
    width: 0;
    height: 16px;
    content: "";
    vertical-align: 0; }

.mdc-text-field-helper-text--persistent {
  transition: none;
  opacity: 1;
  will-change: initial; }

.mdc-text-field-helper-text {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.03333em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  will-change: opacity; }
  .mdc-text-field-helper-text::before {
    display: inline-block;
    width: 0;
    height: 16px;
    content: "";
    vertical-align: 0; }

.mdc-text-field-helper-text--persistent {
  transition: none;
  opacity: 1;
  will-change: initial; }

.mdc-text-field-character-counter {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.03333em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 0;
  white-space: nowrap; }
  .mdc-text-field-character-counter::before {
    display: inline-block;
    width: 0;
    height: 16px;
    content: "";
    vertical-align: 0; }
  [dir="rtl"] .mdc-text-field-character-counter, .mdc-text-field-character-counter[dir="rtl"] {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: auto; }
  [dir="rtl"] .mdc-text-field-character-counter, .mdc-text-field-character-counter[dir="rtl"] {
    /* @noflip */
    padding-left: 0;
    /* @noflip */
    padding-right: 16px; }

.mdc-text-field--with-leading-icon .mdc-text-field__icon,
.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  position: absolute;
  bottom: 16px;
  cursor: pointer; }

.mdc-text-field__icon:not([tabindex]),
.mdc-text-field__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none; }

.mdc-text-field {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  border-radius: 4px 4px 0 0;
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  height: 56px;
  overflow: hidden;
  /* @alternate */
  will-change: opacity, transform, color; }
  .mdc-text-field::before, .mdc-text-field::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-text-field::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-text-field.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-text-field.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-text-field.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-text-field.mdc-ripple-upgraded--foreground-activation::after {
    animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards; }
  .mdc-text-field.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: mdc-ripple-fg-opacity-out 150ms;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-text-field::before, .mdc-text-field::after {
    background-color: rgba(0, 0, 0, 0.87); }
  .mdc-text-field:hover::before {
    opacity: 0.04; }
  .mdc-text-field:not(.mdc-ripple-upgraded):focus::before, .mdc-text-field.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-text-field::before, .mdc-text-field::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-text-field.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: rgba(0, 0, 0, 0.6); }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: rgba(0, 0, 0, 0.87); }
  .mdc-text-field .mdc-text-field__input {
    caret-color: #904778;
    /* @alternate */
    caret-color: var(--mdc-theme-primary, #904778); }
  .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
    border-bottom-color: rgba(0, 0, 0, 0.42); }
  .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input:hover {
    border-bottom-color: rgba(0, 0, 0, 0.87); }
  .mdc-text-field .mdc-line-ripple {
    background-color: #904778;
    /* @alternate */
    background-color: var(--mdc-theme-primary, #904778); }
  .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--textarea) {
    border-bottom-color: rgba(0, 0, 0, 0.12); }
  .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: rgba(0, 0, 0, 0.6); }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
  .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: rgba(0, 0, 0, 0.6); }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon {
    color: rgba(0, 0, 0, 0.54); }
  .mdc-text-field:not(.mdc-text-field--disabled) {
    background-color: whitesmoke; }
  .mdc-text-field .mdc-floating-label {
    /* @noflip */
    left: 16px;
    /* @noflip */
    right: initial;
    top: 18px;
    pointer-events: none; }
    [dir="rtl"] .mdc-text-field .mdc-floating-label, .mdc-text-field .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 16px; }
  .mdc-text-field--textarea .mdc-floating-label {
    /* @noflip */
    left: 4px;
    /* @noflip */
    right: initial; }
    [dir="rtl"] .mdc-text-field--textarea .mdc-floating-label, .mdc-text-field--textarea .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 4px; }
  .mdc-text-field--outlined .mdc-floating-label {
    /* @noflip */
    left: 4px;
    /* @noflip */
    right: initial;
    top: 17px; }
    [dir="rtl"] .mdc-text-field--outlined .mdc-floating-label, .mdc-text-field--outlined .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 4px; }
  .mdc-text-field--outlined--with-leading-icon .mdc-floating-label {
    /* @noflip */
    left: 36px;
    /* @noflip */
    right: initial; }
    [dir="rtl"] .mdc-text-field--outlined--with-leading-icon .mdc-floating-label, .mdc-text-field--outlined--with-leading-icon .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 36px; }
    .mdc-text-field--outlined--with-leading-icon .mdc-floating-label--float-above {
      /* @noflip */
      left: 40px;
      /* @noflip */
      right: initial; }
      [dir="rtl"] .mdc-text-field--outlined--with-leading-icon .mdc-floating-label--float-above, .mdc-text-field--outlined--with-leading-icon .mdc-floating-label--float-above[dir="rtl"] {
        /* @noflip */
        left: initial;
        /* @noflip */
        right: 40px; }

.mdc-text-field__input {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 600;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  align-self: flex-end;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 20px 16px 6px;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  background: none;
  appearance: none; }
  .mdc-text-field__input::placeholder {
    transition: opacity 67ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    color: rgba(0, 0, 0, 0.54); }
  .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.54) !important; }
  .mdc-text-field--fullwidth .mdc-text-field__input::placeholder,
  .mdc-text-field--no-label .mdc-text-field__input::placeholder,
  .mdc-text-field--focused .mdc-text-field__input::placeholder {
    transition-delay: 40ms;
    transition-duration: 110ms;
    opacity: 1; }
  .mdc-text-field__input:focus {
    outline: none; }
  .mdc-text-field__input:invalid {
    box-shadow: none; }
  .mdc-text-field__input:-webkit-autofill {
    z-index: auto !important; }
  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
    padding: 16px; }

.mdc-text-field__input:-webkit-autofill + .mdc-floating-label {
  transform: translateY(-50%) scale(0.75);
  cursor: auto; }

.mdc-text-field--outlined {
  border: none;
  overflow: visible; }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: rgba(0, 0, 0, 0.24); }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing {
    border-color: rgba(0, 0, 0, 0.87); }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
    border-color: #904778;
    /* @alternate */
    border-color: var(--mdc-theme-primary, #904778); }
  .mdc-text-field--outlined .mdc-floating-label--shake {
    animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1; }
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    /* @noflip */
    border-radius: 4px 0 0 4px; }
    [dir="rtl"] .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading, .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading[dir="rtl"] {
      /* @noflip */
      border-radius: 0 4px 4px 0; }
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
    /* @noflip */
    border-radius: 0 4px 4px 0; }
    [dir="rtl"] .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing, .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing[dir="rtl"] {
      /* @noflip */
      border-radius: 4px 0 0 4px; }
  .mdc-text-field--outlined .mdc-floating-label--float-above {
    transform: translateY(-144%) scale(1); }
  .mdc-text-field--outlined .mdc-floating-label--float-above {
    font-size: 0.75rem; }
  .mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    transform: translateY(-130%) scale(0.75); }
  .mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    font-size: 1rem; }
  .mdc-text-field--outlined::before, .mdc-text-field--outlined::after {
    content: none; }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) {
    background-color: transparent; }
  .mdc-text-field--outlined .mdc-text-field__input {
    display: flex;
    padding: 12px 16px 14px;
    border: none !important;
    background-color: transparent;
    z-index: 1; }
  .mdc-text-field--outlined .mdc-text-field__icon {
    z-index: 2; }

.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__trailing {
  border-width: 2px; }

.mdc-text-field--outlined.mdc-text-field--disabled {
  background-color: transparent; }
  .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__leading,
  .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__notch,
  .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: rgba(0, 0, 0, 0.06); }
  .mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input {
    border-bottom: none; }

.mdc-text-field--outlined.mdc-text-field--dense {
  height: 48px; }
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above {
    transform: translateY(-134%) scale(1); }
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above {
    font-size: 0.8rem; }
  .mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    transform: translateY(-120%) scale(0.8); }
  .mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    font-size: 1rem; }
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake {
    animation: mdc-floating-label-shake-float-above-text-field-outlined-dense 250ms 1; }
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__input {
    padding: 12px 12px 7px; }
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label {
    top: 14px; }
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__icon {
    top: 12px; }

.mdc-text-field--with-leading-icon .mdc-text-field__icon {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon .mdc-text-field__icon, .mdc-text-field--with-leading-icon .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 16px; }

.mdc-text-field--with-leading-icon .mdc-text-field__input {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 16px; }
  [dir="rtl"] .mdc-text-field--with-leading-icon .mdc-text-field__input, .mdc-text-field--with-leading-icon .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 16px;
    /* @noflip */
    padding-right: 48px; }

.mdc-text-field--with-leading-icon .mdc-floating-label {
  /* @noflip */
  left: 48px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon .mdc-floating-label, .mdc-text-field--with-leading-icon .mdc-floating-label[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 48px; }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__icon {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 16px; }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__input {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 16px; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__input, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 16px;
    /* @noflip */
    padding-right: 48px; }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-144%) translateX(-32px) scale(1); }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above[dir="rtl"] {
    transform: translateY(-144%) translateX(32px) scale(1); }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem; }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-130%) translateX(-32px) scale(0.75); }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir="rtl"], [dir="rtl"]
  .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir="rtl"] {
    transform: translateY(-130%) translateX(32px) scale(0.75); }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem; }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon 250ms 1; }

[dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir="rtl"] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl 250ms 1; }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label {
  /* @noflip */
  left: 36px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 36px; }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above {
  transform: translateY(-134%) translateX(-21px) scale(1); }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above[dir="rtl"] {
    transform: translateY(-134%) translateX(21px) scale(1); }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above {
  font-size: 0.8rem; }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-120%) translateX(-21px) scale(0.8); }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir="rtl"], [dir="rtl"]
  .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir="rtl"] {
    transform: translateY(-120%) translateX(21px) scale(0.8); }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem; }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense 250ms 1; }

[dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense[dir="rtl"] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense-rtl 250ms 1; }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label {
  /* @noflip */
  left: 32px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 32px; }

.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 12px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon .mdc-text-field__icon, .mdc-text-field--with-trailing-icon .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: 12px;
    /* @noflip */
    right: initial; }

.mdc-text-field--with-trailing-icon .mdc-text-field__input {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 48px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon .mdc-text-field__input, .mdc-text-field--with-trailing-icon .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 48px;
    /* @noflip */
    padding-right: 16px; }

.mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__icon {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__icon, .mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: 16px;
    /* @noflip */
    right: initial; }

.mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__input {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 48px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__input, .mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 48px;
    /* @noflip */
    padding-right: 16px; }

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: auto; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: auto;
    /* @noflip */
    right: 16px; }
  .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon ~ .mdc-text-field__icon {
    /* @noflip */
    right: 12px;
    /* @noflip */
    left: auto; }
    [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon ~ .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon ~ .mdc-text-field__icon[dir="rtl"] {
      /* @noflip */
      right: auto;
      /* @noflip */
      left: 12px; }

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__input {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 48px; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__input, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 48px;
    /* @noflip */
    padding-right: 48px; }

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon,
.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon {
  bottom: 16px;
  transform: scale(0.8); }

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon {
  /* @noflip */
  left: 12px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 12px; }

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input {
  /* @noflip */
  padding-left: 44px;
  /* @noflip */
  padding-right: 16px; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 16px;
    /* @noflip */
    padding-right: 44px; }

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label {
  /* @noflip */
  left: 44px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 44px; }

.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 12px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon, .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: 12px;
    /* @noflip */
    right: initial; }

.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 44px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input, .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 44px;
    /* @noflip */
    padding-right: 16px; }

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon {
  /* @noflip */
  left: 12px;
  /* @noflip */
  right: auto; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: auto;
    /* @noflip */
    right: 12px; }
  .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon ~ .mdc-text-field__icon {
    /* @noflip */
    right: 12px;
    /* @noflip */
    left: auto; }
    [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon ~ .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon ~ .mdc-text-field__icon[dir="rtl"] {
      /* @noflip */
      right: auto;
      /* @noflip */
      left: 12px; }

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input {
  /* @noflip */
  padding-left: 44px;
  /* @noflip */
  padding-right: 44px; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 44px;
    /* @noflip */
    padding-right: 44px; }

.mdc-text-field--dense .mdc-floating-label--float-above {
  transform: translateY(-70%) scale(0.8); }

.mdc-text-field--dense .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-dense 250ms 1; }

.mdc-text-field--dense .mdc-text-field__input {
  padding: 12px 12px 0; }

.mdc-text-field--dense .mdc-floating-label {
  font-size: .813rem; }
  .mdc-text-field--dense .mdc-floating-label--float-above {
    font-size: .813rem; }

.mdc-text-field__input:required ~ .mdc-floating-label::after,
.mdc-text-field__input:required ~ .mdc-notched-outline .mdc-floating-label::after {
  margin-left: 1px;
  content: "*"; }

.mdc-text-field--textarea {
  display: inline-flex;
  width: auto;
  height: auto;
  transition: none;
  overflow: visible; }
  .mdc-text-field--textarea:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--textarea:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--textarea:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: rgba(0, 0, 0, 0.24); }
  .mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
  .mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
  .mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing,
  .mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
  .mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
  .mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing {
    border-color: rgba(0, 0, 0, 0.87); }
  .mdc-text-field--textarea:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
  .mdc-text-field--textarea:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
  .mdc-text-field--textarea:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
    border-color: #904778;
    /* @alternate */
    border-color: var(--mdc-theme-primary, #904778); }
  .mdc-text-field--textarea .mdc-floating-label--shake {
    animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1; }
  .mdc-text-field--textarea .mdc-notched-outline .mdc-notched-outline__leading {
    /* @noflip */
    border-radius: 4px 0 0 4px; }
    [dir="rtl"] .mdc-text-field--textarea .mdc-notched-outline .mdc-notched-outline__leading, .mdc-text-field--textarea .mdc-notched-outline .mdc-notched-outline__leading[dir="rtl"] {
      /* @noflip */
      border-radius: 0 4px 4px 0; }
  .mdc-text-field--textarea .mdc-notched-outline .mdc-notched-outline__trailing {
    /* @noflip */
    border-radius: 0 4px 4px 0; }
    [dir="rtl"] .mdc-text-field--textarea .mdc-notched-outline .mdc-notched-outline__trailing, .mdc-text-field--textarea .mdc-notched-outline .mdc-notched-outline__trailing[dir="rtl"] {
      /* @noflip */
      border-radius: 4px 0 0 4px; }
  .mdc-text-field--textarea::before, .mdc-text-field--textarea::after {
    content: none; }
  .mdc-text-field--textarea:not(.mdc-text-field--disabled) {
    background-color: transparent; }
  .mdc-text-field--textarea .mdc-floating-label--float-above {
    transform: translateY(-144%) scale(1); }
  .mdc-text-field--textarea .mdc-floating-label--float-above {
    font-size: 0.75rem; }
  .mdc-text-field--textarea.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  .mdc-text-field--textarea .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    transform: translateY(-130%) scale(0.75); }
  .mdc-text-field--textarea.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  .mdc-text-field--textarea .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    font-size: 1rem; }
  .mdc-text-field--textarea .mdc-text-field-character-counter {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 16px;
    position: absolute;
    bottom: 13px; }
    [dir="rtl"] .mdc-text-field--textarea .mdc-text-field-character-counter, .mdc-text-field--textarea .mdc-text-field-character-counter[dir="rtl"] {
      /* @noflip */
      left: 16px;
      /* @noflip */
      right: initial; }
  .mdc-text-field--textarea .mdc-text-field__input {
    align-self: auto;
    box-sizing: border-box;
    height: auto;
    margin: 8px 1px 1px 0;
    padding: 0 16px 16px;
    border: none; }
  .mdc-text-field--textarea .mdc-text-field-character-counter + .mdc-text-field__input {
    margin-bottom: 28px;
    padding-bottom: 0; }
  .mdc-text-field--textarea .mdc-floating-label {
    top: 17px;
    bottom: auto;
    width: auto;
    pointer-events: none; }
  .mdc-text-field--textarea.mdc-text-field--focused .mdc-notched-outline__leading,
  .mdc-text-field--textarea.mdc-text-field--focused .mdc-notched-outline__notch,
  .mdc-text-field--textarea.mdc-text-field--focused .mdc-notched-outline__trailing {
    border-width: 2px; }

.mdc-text-field--fullwidth {
  width: 100%; }
  .mdc-text-field--fullwidth:not(.mdc-text-field--textarea) {
    display: block; }
    .mdc-text-field--fullwidth:not(.mdc-text-field--textarea)::before, .mdc-text-field--fullwidth:not(.mdc-text-field--textarea)::after {
      content: none; }
    .mdc-text-field--fullwidth:not(.mdc-text-field--textarea):not(.mdc-text-field--disabled) {
      background-color: transparent; }
    .mdc-text-field--fullwidth:not(.mdc-text-field--textarea) .mdc-text-field__input {
      padding: 0; }
  .mdc-text-field--fullwidth.mdc-text-field--textarea .mdc-text-field__input {
    resize: vertical; }

.mdc-text-field--fullwidth.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--textarea) {
  border-bottom-color: #cc0000;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #cc0000); }

.mdc-text-field-helper-line {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box; }
  .mdc-text-field--dense + .mdc-text-field-helper-line {
    margin-bottom: 4px; }
  .mdc-text-field + .mdc-text-field-helper-line {
    padding-right: 16px;
    padding-left: 16px; }

.mdc-form-field > .mdc-text-field + label {
  align-self: flex-start; }

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(144, 71, 120, 0.87); }

.mdc-text-field--focused .mdc-text-field__input:required ~ .mdc-floating-label::after,
.mdc-text-field--focused .mdc-text-field__input:required ~ .mdc-notched-outline .mdc-floating-label::after {
  color: #cc0000;
  /* @alternate */
  color: var(--mdc-theme-error, #cc0000); }

.mdc-text-field--focused + .mdc-text-field-helper-line .mdc-text-field-helper-text:not(.mdc-text-field-helper-text--validation-msg) {
  opacity: 1; }

.mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #904778;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #904778); }

.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
  border-bottom-color: #cc0000;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #cc0000); }

.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input:hover {
  border-bottom-color: #cc0000;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #cc0000); }

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple {
  background-color: #cc0000;
  /* @alternate */
  background-color: var(--mdc-theme-error, #cc0000); }

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #cc0000;
  /* @alternate */
  color: var(--mdc-theme-error, #cc0000); }

.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: #cc0000;
  /* @alternate */
  color: var(--mdc-theme-error, #cc0000); }

.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: #cc0000;
  /* @alternate */
  caret-color: var(--mdc-theme-error, #cc0000); }

.mdc-text-field--invalid.mdc-text-field--with-trailing-icon:not(.mdc-text-field--with-leading-icon):not(.mdc-text-field--disabled) .mdc-text-field__icon {
  color: #cc0000;
  /* @alternate */
  color: var(--mdc-theme-error, #cc0000); }

.mdc-text-field--invalid.mdc-text-field--with-trailing-icon.mdc-text-field--with-leading-icon:not(.mdc-text-field--disabled) .mdc-text-field__icon ~ .mdc-text-field__icon {
  color: #cc0000;
  /* @alternate */
  color: var(--mdc-theme-error, #cc0000); }

.mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  opacity: 1; }

.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #cc0000;
  /* @alternate */
  border-color: var(--mdc-theme-error, #cc0000); }

.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #cc0000;
  /* @alternate */
  border-color: var(--mdc-theme-error, #cc0000); }

.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #cc0000;
  /* @alternate */
  border-color: var(--mdc-theme-error, #cc0000); }

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #cc0000;
  /* @alternate */
  border-color: var(--mdc-theme-error, #cc0000); }

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #cc0000;
  /* @alternate */
  border-color: var(--mdc-theme-error, #cc0000); }

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #cc0000;
  /* @alternate */
  border-color: var(--mdc-theme-error, #cc0000); }

.mdc-text-field--disabled {
  background-color: #fafafa;
  border-bottom: none;
  pointer-events: none; }
  .mdc-text-field--disabled .mdc-text-field__input {
    border-bottom-color: rgba(0, 0, 0, 0.06); }
  .mdc-text-field--disabled .mdc-text-field__input {
    color: rgba(0, 0, 0, 0.37); }
  .mdc-text-field--disabled .mdc-floating-label {
    color: rgba(0, 0, 0, 0.37); }
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: rgba(0, 0, 0, 0.37); }
  .mdc-text-field--disabled .mdc-text-field-character-counter,
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: rgba(0, 0, 0, 0.37); }
  .mdc-text-field--disabled .mdc-text-field__icon {
    color: rgba(0, 0, 0, 0.3); }
  .mdc-text-field--disabled:not(.mdc-text-field--textarea) {
    border-bottom-color: rgba(0, 0, 0, 0.12); }
  .mdc-text-field--disabled .mdc-floating-label {
    cursor: default; }

.mdc-text-field--textarea.mdc-text-field--disabled {
  background-color: transparent;
  /* @alternate */
  background-color: #f9f9f9; }
  .mdc-text-field--textarea.mdc-text-field--disabled .mdc-notched-outline__leading,
  .mdc-text-field--textarea.mdc-text-field--disabled .mdc-notched-outline__notch,
  .mdc-text-field--textarea.mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: rgba(0, 0, 0, 0.06); }
  .mdc-text-field--textarea.mdc-text-field--disabled .mdc-text-field__input {
    border-bottom: none; }

@keyframes mdc-floating-label-shake-float-above-text-field-dense {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.8); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - 0%)) translateY(-70%) scale(0.8); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - 0%)) translateY(-70%) scale(0.8); }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.8); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - 0%)) translateY(-130%) scale(0.75); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - 0%)) translateY(-130%) scale(0.75); }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-dense {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-120%) scale(0.8); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - 0%)) translateY(-120%) scale(0.8); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - 0%)) translateY(-120%) scale(0.8); }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-120%) scale(0.8); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon {
  0% {
    transform: translateX(calc(0 - 0)) translateY(-130%) scale(0.75); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - 0)) translateY(-130%) scale(0.75); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - 0)) translateY(-130%) scale(0.75); }
  100% {
    transform: translateX(calc(0 - 0)) translateY(-130%) scale(0.75); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense {
  0% {
    transform: translateX(calc(0 - 21px)) translateY(-120%) scale(0.8); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - 21px)) translateY(-120%) scale(0.8); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - 21px)) translateY(-120%) scale(0.8); }
  100% {
    transform: translateX(calc(0 - 21px)) translateY(-120%) scale(0.8); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl {
  0% {
    transform: translateX(calc(0 - 0)) translateY(-130%) scale(0.75); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - 0)) translateY(-130%) scale(0.75); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - 0)) translateY(-130%) scale(0.75); }
  100% {
    transform: translateX(calc(0 - 0)) translateY(-130%) scale(0.75); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense-rtl {
  0% {
    transform: translateX(calc(0 - -21px)) translateY(-120%) scale(0.8); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - -21px)) translateY(-120%) scale(0.8); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - -21px)) translateY(-120%) scale(0.8); }
  100% {
    transform: translateX(calc(0 - -21px)) translateY(-120%) scale(0.8); } }

@keyframes mdc-floating-label-shake-float-above-textarea {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - 0%)) translateY(-130%) scale(0.75); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - 0%)) translateY(-130%) scale(0.75); }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75); } }

/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab-bar {
  width: 100%; }

/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab-scroller {
  overflow-y: hidden; }

.mdc-tab-scroller__test {
  position: absolute;
  top: -9999px;
  width: 100px;
  height: 100px;
  overflow-x: scroll; }

.mdc-tab-scroller__scroll-area {
  -webkit-overflow-scrolling: touch;
  display: flex;
  overflow-x: hidden; }

.mdc-tab-scroller__scroll-area::-webkit-scrollbar,
.mdc-tab-scroller__test::-webkit-scrollbar {
  display: none; }

.mdc-tab-scroller__scroll-area--scroll {
  overflow-x: scroll; }

.mdc-tab-scroller__scroll-content {
  position: relative;
  display: flex;
  flex: 1 0 auto;
  transform: none;
  will-change: transform; }

.mdc-tab-scroller--align-start .mdc-tab-scroller__scroll-content {
  justify-content: flex-start; }

.mdc-tab-scroller--align-end .mdc-tab-scroller__scroll-content {
  justify-content: flex-end; }

.mdc-tab-scroller--align-center .mdc-tab-scroller__scroll-content {
  justify-content: center; }

.mdc-tab-scroller--animating .mdc-tab-scroller__scroll-area {
  -webkit-overflow-scrolling: auto; }

.mdc-tab-scroller--animating .mdc-tab-scroller__scroll-content {
  transition: 250ms transform cubic-bezier(0.4, 0, 0.2, 1); }

/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab {
  position: relative;
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 600;
  letter-spacing: 0.08929em;
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  box-sizing: border-box;
  height: 48px;
  padding: 0 24px;
  border: none;
  outline: none;
  background: none;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
  z-index: 1; }
  .mdc-tab .mdc-tab__text-label {
    color: #000;
    /* @alternate */
    color: var(--mdc-theme-on-surface, #000); }
  .mdc-tab .mdc-tab__icon {
    color: #000;
    /* @alternate */
    color: var(--mdc-theme-on-surface, #000);
    fill: currentColor; }
  .mdc-tab::-moz-focus-inner {
    padding: 0;
    border: 0; }

.mdc-tab--min-width {
  flex: 0 1 auto; }

.mdc-tab__ripple {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }
  .mdc-tab__ripple::before, .mdc-tab__ripple::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-tab__ripple::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-tab__ripple.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-tab__ripple.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-tab__ripple.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-tab__ripple.mdc-ripple-upgraded--foreground-activation::after {
    animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards; }
  .mdc-tab__ripple.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: mdc-ripple-fg-opacity-out 150ms;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-tab__ripple::before, .mdc-tab__ripple::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-tab__ripple.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-tab__ripple::before, .mdc-tab__ripple::after {
    background-color: #904778; }
    @supports not (-ms-ime-align: auto) {
      .mdc-tab__ripple::before, .mdc-tab__ripple::after {
        /* @alternate */
        background-color: var(--mdc-theme-primary, #904778); } }
  .mdc-tab__ripple:hover::before {
    opacity: 0.04; }
  .mdc-tab__ripple:not(.mdc-ripple-upgraded):focus::before, .mdc-tab__ripple.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-tab__ripple:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-tab__ripple:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-tab__ripple.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.12; }

.mdc-tab__content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  pointer-events: none; }

.mdc-tab__text-label,
.mdc-tab__icon {
  transition: 150ms color linear, 150ms opacity linear;
  z-index: 2; }

.mdc-tab__text-label {
  display: inline-block;
  opacity: 0.6;
  line-height: 1; }

.mdc-tab__icon {
  width: 24px;
  height: 24px;
  opacity: 0.54;
  font-size: 24px; }

.mdc-tab--stacked {
  height: 72px; }

.mdc-tab--stacked .mdc-tab__content {
  flex-direction: column;
  align-items: center;
  justify-content: space-between; }

.mdc-tab--stacked .mdc-tab__icon {
  padding-top: 12px; }

.mdc-tab--stacked .mdc-tab__text-label {
  padding-bottom: 16px; }

.mdc-tab--active .mdc-tab__text-label {
  color: #904778;
  /* @alternate */
  color: var(--mdc-theme-primary, #904778); }

.mdc-tab--active .mdc-tab__icon {
  color: #904778;
  /* @alternate */
  color: var(--mdc-theme-primary, #904778);
  fill: currentColor; }

.mdc-tab--active .mdc-tab__text-label,
.mdc-tab--active .mdc-tab__icon {
  transition-delay: 100ms;
  opacity: 1; }

.mdc-tab:not(.mdc-tab--stacked) .mdc-tab__icon + .mdc-tab__text-label {
  /* @noflip */
  padding-left: 8px;
  /* @noflip */
  padding-right: 0; }
  [dir="rtl"] .mdc-tab:not(.mdc-tab--stacked) .mdc-tab__icon + .mdc-tab__text-label, .mdc-tab:not(.mdc-tab--stacked) .mdc-tab__icon + .mdc-tab__text-label[dir="rtl"] {
    /* @noflip */
    padding-left: 0;
    /* @noflip */
    padding-right: 8px; }

/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab-indicator {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1; }
  .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
    background-color: #904778;
    /* @alternate */
    background-color: var(--mdc-theme-primary, #904778); }
  .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
    height: 2px; }
  .mdc-tab-indicator > .mdc-tab-indicator__content--icon {
    color: #b23072;
    /* @alternate */
    color: var(--mdc-theme-secondary, #b23072); }
  .mdc-tab-indicator > .mdc-tab-indicator__content--icon {
    height: 34px;
    font-size: 34px; }

.mdc-tab-indicator__content {
  transform-origin: left;
  opacity: 0; }

.mdc-tab-indicator__content--underline {
  align-self: flex-end;
  width: 100%; }

.mdc-tab-indicator__content--icon {
  align-self: center;
  margin: 0 auto; }

.mdc-tab-indicator--active > .mdc-tab-indicator__content {
  opacity: 1; }

.mdc-tab-indicator > .mdc-tab-indicator__content {
  transition: 250ms transform cubic-bezier(0.4, 0, 0.2, 1); }

.mdc-tab-indicator--no-transition > .mdc-tab-indicator__content {
  transition: none; }

.mdc-tab-indicator--fade > .mdc-tab-indicator__content {
  transition: 150ms opacity linear; }

.mdc-tab-indicator--active.mdc-tab-indicator--fade > .mdc-tab-indicator__content {
  transition-delay: 100ms; }

/* Fortellis */
/* Material Overrides */
.frc-circular-progress {
  display: block;
  position: relative; }
  .frc-circular-progress svg {
    position: absolute;
    transform: rotate(-90deg);
    top: 0;
    left: 0;
    transform-origin: center;
    overflow: visible; }
  .frc-circular-progress circle {
    fill: transparent;
    transform-origin: center;
    transition: stroke-dashoffset 225ms linear;
    stroke: #904778; }
    ._frc-animation-noopable.frc-circular-progress circle {
      transition: none;
      animation: none; }
  .frc-circular-progress.frc-circular-progress-indeterminate-animation {
    animation: frc-circular-progress-linear-rotate 2000ms linear infinite; }
    ._frc-animation-noopable.frc-circular-progress.frc-circular-progress-indeterminate-animation {
      transition: none;
      animation: none; }
    .frc-circular-progress.frc-circular-progress-indeterminate-animation circle {
      transition-property: stroke;
      animation-duration: 4000ms;
      animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
      animation-iteration-count: infinite; }
      ._frc-animation-noopable.frc-circular-progress.frc-circular-progress-indeterminate-animation circle {
        transition: none;
        animation: none; }
  .frc-circular-progress.frc-circular-progress-indeterminate-fallback-animation[mode='indeterminate'] {
    animation: frc-circular-progress-stroke-rotate-fallback 10000ms cubic-bezier(0.87, 0.03, 0.33, 1) infinite; }
    ._frc-animation-noopable.frc-circular-progress.frc-circular-progress-indeterminate-fallback-animation[mode='indeterminate'] {
      transition: none;
      animation: none; }
    .frc-circular-progress.frc-circular-progress-indeterminate-fallback-animation[mode='indeterminate'] circle {
      transition-property: stroke; }
      ._frc-animation-noopable.frc-circular-progress.frc-circular-progress-indeterminate-fallback-animation[mode='indeterminate'] circle {
        transition: none;
        animation: none; }

@keyframes frc-circular-progress-linear-rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes frc-circular-progress-stroke-rotate-100 {
  0% {
    stroke-dashoffset: 268.60617px;
    transform: rotate(0); }
  12.5% {
    stroke-dashoffset: 56.54867px;
    transform: rotate(0); }
  12.5001% {
    stroke-dashoffset: 56.54867px;
    transform: rotateX(180deg) rotate(72.5deg); }
  25% {
    stroke-dashoffset: 268.60617px;
    transform: rotateX(180deg) rotate(72.5deg); }
  25.0001% {
    stroke-dashoffset: 268.60617px;
    transform: rotate(270deg); }
  37.5% {
    stroke-dashoffset: 56.54867px;
    transform: rotate(270deg); }
  37.5001% {
    stroke-dashoffset: 56.54867px;
    transform: rotateX(180deg) rotate(161.5deg); }
  50% {
    stroke-dashoffset: 268.60617px;
    transform: rotateX(180deg) rotate(161.5deg); }
  50.0001% {
    stroke-dashoffset: 268.60617px;
    transform: rotate(180deg); }
  62.5% {
    stroke-dashoffset: 56.54867px;
    transform: rotate(180deg); }
  62.5001% {
    stroke-dashoffset: 56.54867px;
    transform: rotateX(180deg) rotate(251.5deg); }
  75% {
    stroke-dashoffset: 268.60617px;
    transform: rotateX(180deg) rotate(251.5deg); }
  75.0001% {
    stroke-dashoffset: 268.60617px;
    transform: rotate(90deg); }
  87.5% {
    stroke-dashoffset: 56.54867px;
    transform: rotate(90deg); }
  87.5001% {
    stroke-dashoffset: 56.54867px;
    transform: rotateX(180deg) rotate(341.5deg); }
  100% {
    stroke-dashoffset: 268.60617px;
    transform: rotateX(180deg) rotate(341.5deg); } }

@keyframes frc-circular-progress-stroke-rotate-75 {
  0% {
    stroke-dashoffset: 201.45463px;
    transform: rotate(0); }
  12.5% {
    stroke-dashoffset: 42.4115px;
    transform: rotate(0); }
  12.5001% {
    stroke-dashoffset: 42.4115px;
    transform: rotateX(180deg) rotate(72.5deg); }
  25% {
    stroke-dashoffset: 201.45463px;
    transform: rotateX(180deg) rotate(72.5deg); }
  25.0001% {
    stroke-dashoffset: 201.45463px;
    transform: rotate(270deg); }
  37.5% {
    stroke-dashoffset: 42.4115px;
    transform: rotate(270deg); }
  37.5001% {
    stroke-dashoffset: 42.4115px;
    transform: rotateX(180deg) rotate(161.5deg); }
  50% {
    stroke-dashoffset: 201.45463px;
    transform: rotateX(180deg) rotate(161.5deg); }
  50.0001% {
    stroke-dashoffset: 201.45463px;
    transform: rotate(180deg); }
  62.5% {
    stroke-dashoffset: 42.4115px;
    transform: rotate(180deg); }
  62.5001% {
    stroke-dashoffset: 42.4115px;
    transform: rotateX(180deg) rotate(251.5deg); }
  75% {
    stroke-dashoffset: 201.45463px;
    transform: rotateX(180deg) rotate(251.5deg); }
  75.0001% {
    stroke-dashoffset: 201.45463px;
    transform: rotate(90deg); }
  87.5% {
    stroke-dashoffset: 42.4115px;
    transform: rotate(90deg); }
  87.5001% {
    stroke-dashoffset: 42.4115px;
    transform: rotateX(180deg) rotate(341.5deg); }
  100% {
    stroke-dashoffset: 201.45463px;
    transform: rotateX(180deg) rotate(341.5deg); } }

@keyframes frc-circular-progress-stroke-rotate-50 {
  0% {
    stroke-dashoffset: 134.30309px;
    transform: rotate(0); }
  12.5% {
    stroke-dashoffset: 28.27433px;
    transform: rotate(0); }
  12.5001% {
    stroke-dashoffset: 28.27433px;
    transform: rotateX(180deg) rotate(72.5deg); }
  25% {
    stroke-dashoffset: 134.30309px;
    transform: rotateX(180deg) rotate(72.5deg); }
  25.0001% {
    stroke-dashoffset: 134.30309px;
    transform: rotate(270deg); }
  37.5% {
    stroke-dashoffset: 28.27433px;
    transform: rotate(270deg); }
  37.5001% {
    stroke-dashoffset: 28.27433px;
    transform: rotateX(180deg) rotate(161.5deg); }
  50% {
    stroke-dashoffset: 134.30309px;
    transform: rotateX(180deg) rotate(161.5deg); }
  50.0001% {
    stroke-dashoffset: 134.30309px;
    transform: rotate(180deg); }
  62.5% {
    stroke-dashoffset: 28.27433px;
    transform: rotate(180deg); }
  62.5001% {
    stroke-dashoffset: 28.27433px;
    transform: rotateX(180deg) rotate(251.5deg); }
  75% {
    stroke-dashoffset: 134.30309px;
    transform: rotateX(180deg) rotate(251.5deg); }
  75.0001% {
    stroke-dashoffset: 134.30309px;
    transform: rotate(90deg); }
  87.5% {
    stroke-dashoffset: 28.27433px;
    transform: rotate(90deg); }
  87.5001% {
    stroke-dashoffset: 28.27433px;
    transform: rotateX(180deg) rotate(341.5deg); }
  100% {
    stroke-dashoffset: 134.30309px;
    transform: rotateX(180deg) rotate(341.5deg); } }

@keyframes frc-circular-progress-stroke-rotate-30 {
  0% {
    stroke-dashoffset: 80.58185px;
    transform: rotate(0); }
  12.5% {
    stroke-dashoffset: 16.9646px;
    transform: rotate(0); }
  12.5001% {
    stroke-dashoffset: 16.9646px;
    transform: rotateX(180deg) rotate(72.5deg); }
  25% {
    stroke-dashoffset: 80.58185px;
    transform: rotateX(180deg) rotate(72.5deg); }
  25.0001% {
    stroke-dashoffset: 80.58185px;
    transform: rotate(270deg); }
  37.5% {
    stroke-dashoffset: 16.9646px;
    transform: rotate(270deg); }
  37.5001% {
    stroke-dashoffset: 16.9646px;
    transform: rotateX(180deg) rotate(161.5deg); }
  50% {
    stroke-dashoffset: 80.58185px;
    transform: rotateX(180deg) rotate(161.5deg); }
  50.0001% {
    stroke-dashoffset: 80.58185px;
    transform: rotate(180deg); }
  62.5% {
    stroke-dashoffset: 16.9646px;
    transform: rotate(180deg); }
  62.5001% {
    stroke-dashoffset: 16.9646px;
    transform: rotateX(180deg) rotate(251.5deg); }
  75% {
    stroke-dashoffset: 80.58185px;
    transform: rotateX(180deg) rotate(251.5deg); }
  75.0001% {
    stroke-dashoffset: 80.58185px;
    transform: rotate(90deg); }
  87.5% {
    stroke-dashoffset: 16.9646px;
    transform: rotate(90deg); }
  87.5001% {
    stroke-dashoffset: 16.9646px;
    transform: rotateX(180deg) rotate(341.5deg); }
  100% {
    stroke-dashoffset: 80.58185px;
    transform: rotateX(180deg) rotate(341.5deg); } }

@keyframes frc-circular-progress-stroke-rotate-25 {
  0% {
    stroke-dashoffset: 67.15154px;
    transform: rotate(0); }
  12.5% {
    stroke-dashoffset: 14.13717px;
    transform: rotate(0); }
  12.5001% {
    stroke-dashoffset: 14.13717px;
    transform: rotateX(180deg) rotate(72.5deg); }
  25% {
    stroke-dashoffset: 67.15154px;
    transform: rotateX(180deg) rotate(72.5deg); }
  25.0001% {
    stroke-dashoffset: 67.15154px;
    transform: rotate(270deg); }
  37.5% {
    stroke-dashoffset: 14.13717px;
    transform: rotate(270deg); }
  37.5001% {
    stroke-dashoffset: 14.13717px;
    transform: rotateX(180deg) rotate(161.5deg); }
  50% {
    stroke-dashoffset: 67.15154px;
    transform: rotateX(180deg) rotate(161.5deg); }
  50.0001% {
    stroke-dashoffset: 67.15154px;
    transform: rotate(180deg); }
  62.5% {
    stroke-dashoffset: 14.13717px;
    transform: rotate(180deg); }
  62.5001% {
    stroke-dashoffset: 14.13717px;
    transform: rotateX(180deg) rotate(251.5deg); }
  75% {
    stroke-dashoffset: 67.15154px;
    transform: rotateX(180deg) rotate(251.5deg); }
  75.0001% {
    stroke-dashoffset: 67.15154px;
    transform: rotate(90deg); }
  87.5% {
    stroke-dashoffset: 14.13717px;
    transform: rotate(90deg); }
  87.5001% {
    stroke-dashoffset: 14.13717px;
    transform: rotateX(180deg) rotate(341.5deg); }
  100% {
    stroke-dashoffset: 67.15154px;
    transform: rotateX(180deg) rotate(341.5deg); } }

@keyframes frc-circular-progress-stroke-rotate-fallback {
  0% {
    transform: rotate(0deg); }
  25% {
    transform: rotate(1170deg); }
  50% {
    transform: rotate(2340deg); }
  75% {
    transform: rotate(3510deg); }
  100% {
    transform: rotate(4680deg); } }

.flex {
  display: flex; }
  .flex--row {
    flex-direction: row; }
  .flex--row-reverse {
    flex-direction: row-reverse; }
  .flex--column {
    flex-direction: column; }
  .flex--column-reverse {
    flex-direction: column-reverse; }
  .flex--nowrap {
    flex-wrap: nowrap; }
  .flex--wrap {
    flex-wrap: wrap; }
  .flex--wrap-reverse {
    flex-wrap: wrap-reverse; }
  .flex--justify-start {
    justify-content: flex-start; }
  .flex--justify-end {
    justify-content: flex-end; }
  .flex--justify-center {
    justify-content: center; }
  .flex--justify-between {
    justify-content: space-between; }
  .flex--justify-around {
    justify-content: space-around; }
  .flex--align-start {
    align-items: flex-start; }
  .flex--align-end {
    align-items: flex-end; }
  .flex--align-center {
    align-items: center; }
  .flex--align-baseline {
    align-items: baseline; }
  .flex--align-stretch {
    align-items: stretch; }
  .flex--align-content-start {
    align-content: flex-start; }
  .flex--align-content-end {
    align-content: flex-end; }
  .flex--align-content-center {
    align-content: center; }
  .flex--align-content-between {
    align-content: space-between; }
  .flex--align-content-around {
    align-content: space-around; }
  .flex--align-content-stretch {
    align-content: stretch; }
  .flex__item--align-self-auto {
    align-self: auto; }
  .flex__item--align-self-start {
    align-self: flex-start; }
  .flex__item--align-self-end {
    align-self: flex-end; }
  .flex__item--align-self-center {
    align-self: center; }
  .flex__item--align-self-baseline {
    align-self: baseline; }
  .flex__item--align-self-stretch {
    align-self: stretch; }

.frc-logo {
  height: 22px;
  width: 207.9px; }
  .frc-logo--mobile {
    height: 14px;
    width: 132.3px; }

/* Fortellis */
/* Material Overrides */
.mdc-typography.frc-typography--headline1, .mdc-typography.frc-typography--headline2, .mdc-typography.frc-typography--headline3, .mdc-typography.frc-typography--headline4, .mdc-typography.frc-typography--headline5, .mdc-typography.frc-typography--headline6, .mdc-typography.frc-typography--subtitle1, .mdc-typography.frc-typography--subtitle2 {
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  margin: 0; }

.mdc-typography.frc-typography--body1, .mdc-typography.frc-typography--body2, .mdc-typography.frc-typography--caption, .mdc-typography.frc-typography--button, .mdc-typography.frc-typography--overline {
  margin: 0; }

.mdc-typography.frc-typography--secondary {
  color: rgba(0, 0, 0, 0.6); }

.mdc-typography.frc-typography--weight-100 {
  font-weight: 100; }

.mdc-typography.frc-typography--weight-200 {
  font-weight: 200; }

.mdc-typography.frc-typography--weight-300 {
  font-weight: 300; }

.mdc-typography.frc-typography--weight-400 {
  font-weight: 400; }

.mdc-typography.frc-typography--weight-500 {
  font-weight: 500; }

.mdc-typography.frc-typography--weight-600 {
  font-weight: 600; }

.mdc-typography.frc-typography--weight-700 {
  font-weight: 700; }

.mdc-typography.frc-typography--weight-800 {
  font-weight: 800; }

.mdc-typography.frc-typography--weight-900 {
  font-weight: 900; }

/**
 * Copyright 2017 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2017 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2017 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2017 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.mdc-expansion-panel {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  outline: none;
  background-color: white;
  cursor: pointer;
  overflow: hidden; }
  .mdc-expansion-panel:active, .mdc-expansion-panel:focus {
    background: #eee; }
  .mdc-expansion-panel__text-primary {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: text;
    color: rgba(0, 0, 0, 0.87);
    font-size: 15px; }
  .mdc-expansion-panel__text-secondary {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: text;
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px; }
  .mdc-expansion-panel__header {
    -webkit-transition: padding 0.3s;
    -moz-transition: padding 0.3s;
    -ms-transition: padding 0.3s;
    -o-transition: padding 0.3s;
    transition: padding 0.3s;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-start;
    order: 0;
    height: 24px;
    padding: 12px 24px; }
    .mdc-expansion-panel__header__section {
      margin-right: 16px; }
    .mdc-expansion-panel__header .mdc-expansion-panel__expansion-icon {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: transform 0.3s;
      -moz-transition: transform 0.3s;
      -ms-transition: transform 0.3s;
      -o-transition: transform 0.3s;
      transition: transform 0.3s;
      order: 10;
      margin-right: initial;
      margin-left: auto;
      color: rgba(0, 0, 0, 0.38); }
      .mdc-expansion-panel[dir="rtl"] .mdc-expansion-panel__header .mdc-expansion-panel__expansion-icon,
      [dir="rtl"] .mdc-expansion-panel .mdc-expansion-panel__header .mdc-expansion-panel__expansion-icon {
        margin-right: auto;
        margin-left: initial; }
      .mdc-expansion-panel__header .mdc-expansion-panel__expansion-icon:empty::before {
        content: 'expand_more'; }
  .mdc-expansion-panel__icon {
    color: rgba(0, 0, 0, 0.38); }
  .mdc-expansion-panel__body {
    -webkit-transition: flex-shrink 0.3s;
    -moz-transition: flex-shrink 0.3s;
    -ms-transition: flex-shrink 0.3s;
    -o-transition: flex-shrink 0.3s;
    transition: flex-shrink 0.3s;
    order: 1;
    padding: 0 24px 16px; }
  .mdc-expansion-panel__footer {
    -webkit-transition: padding flex-shrink 0.3s;
    -moz-transition: padding flex-shrink 0.3s;
    -ms-transition: padding flex-shrink 0.3s;
    -o-transition: padding flex-shrink 0.3s;
    transition: padding flex-shrink 0.3s;
    display: flex;
    align-items: center;
    order: 2;
    height: 36px;
    padding: 16px 8px 16px 24px;
    border-top: 1px solid #e0e0e0; }
    .mdc-expansion-panel[dir="rtl"] .mdc-expansion-panel__footer,
    [dir="rtl"] .mdc-expansion-panel .mdc-expansion-panel__footer {
      padding-right: 24px;
      padding-left: 8px; }
    .mdc-expansion-panel__footer__button-bar {
      order: 10;
      margin-right: initial;
      margin-left: auto; }
      .mdc-expansion-panel[dir="rtl"] .mdc-expansion-panel__footer__button-bar,
      [dir="rtl"] .mdc-expansion-panel .mdc-expansion-panel__footer__button-bar {
        margin-right: auto;
        margin-left: initial; }
  .mdc-expansion-panel--collapsing, .mdc-expansion-panel--collapsed {
    height: 48px;
    margin: 0; }
    .mdc-expansion-panel--collapsing .mdc-expansion-panel--details, .mdc-expansion-panel--collapsed .mdc-expansion-panel--details {
      display: none; }
  .mdc-expansion-panel--expanding, .mdc-expansion-panel--expanded {
    margin: 16px 0; }
    .mdc-expansion-panel--expanding .mdc-expansion-panel--summary, .mdc-expansion-panel--expanded .mdc-expansion-panel--summary {
      display: none; }
    .mdc-expansion-panel--expanding .mdc-expansion-panel__header, .mdc-expansion-panel--expanded .mdc-expansion-panel__header {
      padding: 20px 24px; }
    .mdc-expansion-panel--expanding .mdc-expansion-panel__expansion-icon, .mdc-expansion-panel--expanded .mdc-expansion-panel__expansion-icon {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); }
  .mdc-expansion-panel--expanding .mdc-expansion-panel__body,
  .mdc-expansion-panel--expanding .mdc-expansion-panel__footer, .mdc-expansion-panel--collapsing .mdc-expansion-panel__body,
  .mdc-expansion-panel--collapsing .mdc-expansion-panel__footer {
    flex-shrink: 1; }

/* Fortellis */
/* Material Overrides */
/* Fortellis */
/* Material Overrides */
/* Fortellis Typography */
.mdc-dialog__title {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 600;
  letter-spacing: 0.0075em; }

.mdc-form-field {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500; }

.mdc-list {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500; }
  .mdc-list a,
  .mdc-list label {
    color: rgba(0, 0, 0, 0.87);
    text-decoration: none; }
  .mdc-list a,
  .mdc-list label,
  .mdc-list .mdc-layout-grid__inner {
    outline: 0; }
  .mdc-list .mdc-list-item {
    /* add will-change: initial to any component with ripple effect present in list item component */
    /* hack to make list item opacity work on hover and on click when radio is present */
    /* hack to make list item opacity work on hover and on click when checkbox is present */
    /* hack to make list item opacity work on hover and on click when icon button is present */ }
    .mdc-list .mdc-list-item .mdc-radio {
      will-change: initial; }
    .mdc-list .mdc-list-item .mdc-checkbox {
      will-change: initial; }
    .mdc-list .mdc-list-item .mdc-icon-button {
      will-change: initial; }

.mdc-drawer .mdc-list-item {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  border-radius: 0;
  height: 48px; }

.mdc-snackbar__label {
  color: rgba(255, 255, 255, 0.87); }

.mdc-select {
  width: 100%; }
  .mdc-select:not(.mdc-select--disabled) {
    background-color: transparent; }
  .mdc-select .mdc-floating-label {
    top: 22px;
    left: 0; }
  .mdc-select .mdc-select__native-control {
    padding-top: 20px;
    padding-bottom: 0;
    padding-left: 0; }
  .mdc-select .mdc-select__selected-text {
    padding-top: 23.8px;
    padding-bottom: 0;
    padding-left: 0;
    text-indent: 0; }

.mdc-text-field {
  width: 100%; }
  .mdc-text-field:not(.mdc-text-field--disabled) {
    background-color: transparent; }
  .mdc-text-field .mdc-floating-label {
    top: 18px;
    left: 0; }
  .mdc-text-field .mdc-text-field__input {
    padding-top: 20px;
    padding-bottom: 0;
    padding-left: 0; }

.mdc-text-field + .mdc-text-field-helper-line {
  padding-left: 0;
  padding-right: 0; }

.mdc-dialog {
  z-index: 99; }

/* Fortellis */
/* Material Overrides */
/* Fortellis */
/* Material Overrides */
a {
  outline: 0;
  text-decoration: none; }

i {
  outline: 0; }

.frc-header__bar {
  background: rgba(0, 0, 0, 0.2);
  height: 80px;
  padding: 0 0 0 32px;
  z-index: 4; }
  .frc-header__bar--mobile {
    height: 56px;
    padding: 0 4px 0 16px; }

.frc-header__bar-item {
  height: 100%;
  display: flex;
  align-items: center; }
  .frc-header__bar-item--nav {
    width: 100%; }

.frc-header__nav-items {
  height: 100%;
  margin-left: auto; }

.frc-header__nav-item {
  height: 100%;
  display: flex;
  align-items: center; }

.frc-header__icon, .frc-header__icon-button {
  color: #ffffff; }

.frc-header__list--nav-link, .frc-header__list--flyout {
  min-width: 192px; }

.frc-header__list--user {
  min-width: 320px; }

.frc-header__list--language {
  min-width: 142px; }

.frc-header__list .flyout-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%; }

.frc-header__list-item {
  cursor: pointer; }
  .frc-header__list-item.mdc-list-item--selected > .frc-header__list-item-link,
  .frc-header__list-item.mdc-list-item--selected > .frc-link,
  .frc-header__list-item.mdc-list-item--selected > .mdc-form-field > label,
  .frc-header__list-item.mdc-list-item--selected > a,
  .frc-header__list-item.mdc-list-item--selected > i,
  .frc-header__list-item.mdc-list-item--selected > span,
  .frc-header__list-item.mdc-list-item--selected > label {
    color: #904778; }

.frc-header__list-item-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  height: 100%;
  width: 100%; }

.frc-header__nav-buttons {
  height: 100%;
  margin-left: 32px; }

.frc-header__nav-button {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
  padding: 0 15px 0 15px;
  border-width: 1px;
  margin-left: 8px;
  position: initial; }
  .frc-header__nav-button:not(:disabled) {
    background-color: transparent; }
  .frc-header__nav-button:not(:disabled) {
    color: white;
    /* @alternate */
    color: var(--mdc-theme-text-primary-on-dark, white); }
  .frc-header__nav-button::before, .frc-header__nav-button::after {
    background-color: white; }
    @supports not (-ms-ime-align: auto) {
      .frc-header__nav-button::before, .frc-header__nav-button::after {
        /* @alternate */
        background-color: var(--mdc-theme-text-primary-on-dark, white); } }
  .frc-header__nav-button:hover::before {
    opacity: 0.08; }
  .frc-header__nav-button:not(.mdc-ripple-upgraded):focus::before, .frc-header__nav-button.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.24; }
  .frc-header__nav-button:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .frc-header__nav-button:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.24; }
  .frc-header__nav-button.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.24; }
  .frc-header__nav-button:not(:disabled) {
    border-color: #ffffff; }

.frc-header__nav-link {
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 600;
  letter-spacing: normal;
  text-decoration: none;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
  margin: 0 12px;
  padding: 10px 0; }
  .frc-header__nav-link:hover, .frc-header__nav-link--selected {
    color: #ffffff;
    cursor: pointer; }
  .frc-header__nav-link--beta {
    position: relative;
    margin-right: 42px; }
    .frc-header__nav-link--beta:after {
      content: 'BETA';
      font-size: 0.55rem;
      position: absolute;
      top: -2px;
      border: 1px solid;
      border-radius: 4px;
      padding: 0em 0.25em;
      margin-left: 2px; }

.frc-header__menu {
  z-index: 9; }

.frc-header__menu-anchor {
  height: 100%; }

.frc-user-card {
  border-width: 0;
  border-style: solid;
  border-color: #ffffff;
  border-radius: 0;
  background: url("assets/AvatarBackground.png") no-repeat;
  background-size: cover;
  color: #ffffff;
  font-family: Raleway, Arial, Helvetica, sans-serif; }
  .frc-user-card__content {
    padding: 4px 4px 24px 16px; }
  .frc-user-card__primary-content--desktop {
    cursor: default; }
    .frc-user-card__primary-content--desktop::before, .frc-user-card__primary-content--desktop::after {
      content: none; }
    .frc-user-card__primary-content--desktop:hover::before {
      opacity: 0.04; }
    .frc-user-card__primary-content--desktop:not(.mdc-ripple-upgraded):focus::before, .frc-user-card__primary-content--desktop.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.12; }
    .frc-user-card__primary-content--desktop:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .frc-user-card__primary-content--desktop:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.12; }
    .frc-user-card__primary-content--desktop.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.12; }
  .frc-user-card__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 50%;
    color: #904778;
    margin-top: 12px !important;
    text-transform: uppercase;
    height: 64px;
    width: 64px; }
  .frc-user-card__icon {
    color: #ffffff !important;
    margin-right: 12px; }
  .frc-user-card__row {
    color: #ffffff;
    width: 100%; }
    .frc-user-card__row--title {
      margin-top: 16px;
      margin-right: 12px; }
  .frc-user-card__title {
    color: #ffffff; }
  .frc-user-card__subtitle {
    display: flex;
    align-items: center;
    min-height: 32px;
    max-width: 220px; }
  .frc-user-card__chip {
    background-color: transparent;
    color: #ffffff;
    padding: 6px 11px;
    border-width: 1px;
    border-style: solid;
    border-color: #ffffff;
    margin-right: 16px; }
    .frc-user-card__chip::before, .frc-user-card__chip::after {
      background-color: #ffffff; }
    .frc-user-card__chip:hover::before {
      opacity: 0.08; }
    .frc-user-card__chip:not(.mdc-ripple-upgraded):focus::before, .frc-user-card__chip.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.24; }
    .frc-user-card__chip:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .frc-user-card__chip:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.24; }
    .frc-user-card__chip.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.24; }
    .frc-user-card__chip:hover {
      color: #ffffff; }
    .frc-user-card__chip--disabled {
      cursor: default; }
      .frc-user-card__chip--disabled::before, .frc-user-card__chip--disabled::after {
        content: none; }
      .frc-user-card__chip--disabled:hover::before {
        opacity: 0.04; }
      .frc-user-card__chip--disabled:not(.mdc-ripple-upgraded):focus::before, .frc-user-card__chip--disabled.mdc-ripple-upgraded--background-focused::before {
        transition-duration: 75ms;
        opacity: 0.12; }
      .frc-user-card__chip--disabled:not(.mdc-ripple-upgraded)::after {
        transition: opacity 150ms linear; }
      .frc-user-card__chip--disabled:not(.mdc-ripple-upgraded):active::after {
        transition-duration: 75ms;
        opacity: 0.12; }
      .frc-user-card__chip--disabled.mdc-ripple-upgraded {
        --mdc-ripple-fg-opacity: 0.12; }
    .frc-user-card__chip--mobile {
      cursor: pointer;
      margin-right: 12px; }

.frc-drawer {
  width: 100%; }
  .frc-drawer--sub {
    top: 0; }
  .frc-drawer__header {
    min-height: 56px;
    padding: 0 4px 0 16px; }
    .frc-drawer__header--authenticated {
      padding: 0; }
    .frc-drawer__header--sub {
      background-color: #ffffff;
      height: 48px;
      min-height: 48px;
      padding: 0; }
  .frc-drawer__header-icon, .frc-drawer__header-icon-button {
    color: #ffffff; }
  .frc-drawer__content-item, .frc-drawer__header-content {
    height: 100%; }
  .frc-drawer__list-item {
    cursor: pointer;
    margin: 0 !important;
    padding: 0 16px !important; }
    .frc-drawer__list-item.mdc-list-item--selected > .frc-header__list-item-link,
    .frc-drawer__list-item.mdc-list-item--selected > .frc-link,
    .frc-drawer__list-item.mdc-list-item--selected > a,
    .frc-drawer__list-item.mdc-list-item--selected > i,
    .frc-drawer__list-item.mdc-list-item--selected > span {
      color: #904778; }
    .frc-drawer__list-item--dialog {
      border-radius: 0 !important; }
  .frc-drawer__list-divider {
    margin: 0 !important; }
  .frc-drawer__logo {
    height: 40px; }
  .frc-drawer__title--sub {
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .frc-drawer__top-bar {
    color: rgba(0, 0, 0, 0.87);
    min-height: 48px; }
  .frc-drawer__top-bar-icon {
    color: rgba(0, 0, 0, 0.6) !important; }

[dir="rtl"] .mdc-drawer, .mdc-drawer[dir="rtl"] {
  border-left-width: 0; }

.frc-user-button__wrapper {
  height: 100%;
  min-width: 150px; }
  .frc-user-button__wrapper:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.2); }

.frc-user-button {
  height: 100%;
  color: white;
  padding: 0px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .frc-user-button__name {
    font-size: 0.9rem;
    text-transform: uppercase;
    margin: 0;
    font-weight: 700; }
  .frc-user-button__account {
    font-size: 0.8rem;
    margin: 0;
    font-weight: 500;
    white-space: break-word;
    max-width: 175px;
    text-overflow: clip;
    max-height: 2rem;
    overflow: hidden; }

.loading-dots--container {
  padding-left: 10px;
  padding-top: 5; }

.loading-dots {
  position: relative;
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  color: #ffffff;
  background-color: #ffffff;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s; }
  .loading-dots::before, .loading-dots::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0; }
  .loading-dots::before {
    left: -10px;
    width: 5px;
    height: 5px;
    border-radius: 2.5px;
    color: #ffffff;
    background-color: #ffffff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s; }
  .loading-dots::after {
    left: 10px;
    width: 5px;
    height: 5px;
    border-radius: 2.5px;
    color: #ffffff;
    background-color: #ffffff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s; }

@keyframes dotFlashing {
  0% {
    background-color: #ffffff; }
  50%,
  100% {
    background-color: #ffffff33; } }

/* Fortellis */
/* Material Overrides */
/* Fortellis */
/* Material Overrides */
/**
 * Copyright 2017 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.frc-footer {
  background-color: #202632;
  font-family: Raleway, Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

.frc-footer__divider {
  border-bottom-color: #5f6165 !important; }

.frc-footer__button--account {
  margin-left: 1rem; }
  .frc-footer__button--account.mdc-button {
    padding: 0 1rem 0 1rem; }
    .frc-footer__button--account.mdc-button:not(:disabled) {
      background-color: white; }
    .frc-footer__button--account.mdc-button:not(:disabled) {
      color: #904778; }

.frc-footer__button--mobile {
  margin: 0.75rem 0 1.5rem; }

.frc-footer__legal-link {
  color: white;
  min-height: 1.5rem;
  display: flex;
  align-items: center; }
  .frc-footer__legal-link--mobile {
    padding: 1rem; }

.frc-footer__list-item:hover {
  background-color: rgba(255, 255, 255, 0.2); }

.frc-footer__list-item .frc-footer__list-item-link {
  color: rgba(255, 255, 255, 0.7); }

.frc-footer__list-item-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  height: 100%;
  width: 100%; }

.frc-footer__nav-link.mdc-typography {
  padding-top: 1rem; }
  .frc-footer__nav-link.mdc-typography:last-child {
    padding-bottom: 1rem; }
  .frc-footer__nav-link.mdc-typography a {
    color: white; }

.frc-footer__nav-link-title.mdc-typography {
  text-transform: uppercase; }

.frc-footer__panel {
  color: #ffffff;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid #5f6165;
  background-color: transparent; }
  .frc-footer__panel:active, .frc-footer__panel:focus {
    background: transparent; }
  .frc-footer__panel.mdc-expansion-panel--collapsing, .frc-footer__panel.mdc-expansion-panel--collapsed {
    height: 40px;
    margin: 0; }
  .frc-footer__panel.mdc-expansion-panel--expanding, .frc-footer__panel.mdc-expansion-panel--expanded {
    margin: 0; }
    .frc-footer__panel.mdc-expansion-panel--expanding .mdc-expansion-panel__header, .frc-footer__panel.mdc-expansion-panel--expanded .mdc-expansion-panel__header {
      padding: 18px 16px; }

.frc-footer__panel-header {
  font-size: 1rem;
  font-weight: 600;
  height: 20px;
  letter-spacing: 0.96px;
  padding: 10px 16px;
  text-transform: uppercase; }
  .frc-footer__panel-header .mdc-expansion-panel__expansion-icon {
    color: white; }

.frc-footer__panel-body {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  padding: 0 0 16px !important; }

.frc-footer__row {
  color: white; }
  .frc-footer__row--account {
    height: 6.25rem; }
  .frc-footer__row--logo {
    min-height: 5.875rem;
    padding: 3rem 0; }
    .frc-footer__row--logo.frc-footer__row--mobile {
      min-height: 72px;
      padding: 0; }
  .frc-footer__row--copyright {
    padding-bottom: 1.5rem; }
    .frc-footer__row--copyright.frc-footer__row--mobile {
      padding-top: 1.5rem;
      text-align: center; }
  .frc-footer__row--legal {
    margin: 8px 16px 32px; }
  .frc-footer__row--mobile {
    margin: 0 16px; }
  .frc-footer__row--signup {
    margin-top: 12px; }
  .frc-footer__row--social.frc-footer__row--mobile {
    min-height: 58px; }

.frc-footer__section--legal-links, .frc-footer__section--nav-links {
  padding: 0 2rem; }

.frc-footer__section--legal-links {
  min-height: 1.5rem;
  display: flex;
  align-items: center; }

.frc-footer__section--nav {
  margin-left: 2rem; }

.frc-footer__section--nav-links {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  min-height: 5.875rem; }

.frc-footer__section--pipe {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  min-height: 1.5rem; }

.frc-footer__section--social {
  margin-left: -0.75rem;
  margin-top: 0.5rem; }

.frc-footer__section--trademark {
  margin-right: 2rem;
  min-height: 1.5rem;
  display: flex;
  align-items: center; }

.frc-footer__social--mobile {
  min-height: 3.625rem; }

.frc-footer__social-icon {
  display: flex !important;
  align-items: center;
  justify-content: center; }
  .frc-footer__social-icon:first-child {
    margin-left: 0; }
  .frc-footer__social-icon:last-child {
    margin-right: 0; }
  .frc-footer__social-icon--mobile {
    margin: 0 6px; }

.frc-footer__social-pipe {
  color: rgba(255, 255, 255, 0.7); }

.frc-footer__social-text--link {
  color: rgba(255, 255, 255, 0.7); }
  .frc-footer__social-text--link:hover {
    color: white; }

/** CDK EXCHANGE/ZEBRAFISH Theme Styles */
.billing-address-card .description {
  color: #505050;
  font-family: Raleway;
  font-size: 14px;
  line-height: 18px; }

.billing-address-card > p {
  color: #767676;
  font-family: Raleway;
  font-size: 1rem;
  letter-spacing: 1.11px;
  line-height: 18px; }

.billing-address-card .checkbox {
  color: rgba(0, 0, 0, 0.87);
  font-family: Raleway;
  font-size: 1rem;
  letter-spacing: 1.27px;
  line-height: 16px; }

.billing-address-card .subscription {
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  display: flex;
  width: 100%; }

.billing-address-card .mdc-select {
  margin-top: 8px; }
  .billing-address-card .mdc-select .mdc-select__label {
    bottom: 4px; }
  .billing-address-card .mdc-select .mdc-select__label--float-above {
    bottom: 10px; }

.billing-address-card .mdc-typography--subheading2 {
  color: rgba(0, 0, 0, 0.87);
  font-family: Montserrat;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.12px;
  line-height: 29px; }

.billing-address-card .billing-address-row {
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex; }
  .billing-address-card .billing-address-row > div {
    flex-grow: 1;
    flex-shrink: 1; }

.billing-address-card .billing-address-top-row {
  width: 100%; }
  @media only screen and (max-width: 600px) {
    .billing-address-card .billing-address-top-row > div {
      width: 100%; }
    .billing-address-card .billing-address-top-row .billing-address-input-field {
      width: 100%; } }
  @media only screen and (min-width: 600px) {
    .billing-address-card .billing-address-top-row .billing-address-input-field {
      width: calc(100% - 10px); } }

@media only screen and (max-width: 600px) {
  .billing-address-card .billing-address-bottom-row .billing-address-input-field {
    width: 100%; }
  .billing-address-card .billing-address-bottom-row .billing-address-drop-down {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    margin-right: 10px; } }

@media only screen and (min-width: 600px) {
  .billing-address-card .billing-address-bottom-row .billing-address-input-field {
    width: calc(100% - 10px); }
  .billing-address-card .billing-address-bottom-row .billing-address-drop-down {
    width: 25%;
    min-width: 25%;
    max-width: 25%;
    margin-right: 10px; } }

#recoveryQuestion {
  width: 100%; }

@media only screen and (max-width: 800px) {
  .stepper-registration-card {
    width: 100%; }
  .flex-wrapper div {
    width: 100%; } }

@media only screen and (min-width: 800px) {
  .stepper-registration-card {
    width: 100%; }
    .stepper-registration-card .mdc-select__label {
      width: 100%; } }

.stepper-registration-card {
  webkit-box-shadow: none;
  box-shadow: none;
  overflow: hidden !important; }
  .stepper-registration-card .mdc-select__label {
    color: rgba(0, 0, 0, 0.54);
    font-family: Raleway;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.75rem;
    transform-origin: left top;
    transform: translateY(-78%) scale(0.75); }
    @media only screen and (min-width: 968px) {
      .stepper-registration-card .mdc-select__label.mdc-select__label--float-above {
        transform: translateY(-70%) scale(0.75); } }
  .stepper-registration-card .user-info__subheading {
    height: 19px;
    width: 155px;
    color: rgba(0, 0, 0, 0.87);
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px; }
  .stepper-registration-card .text-field-wrapper {
    margin-bottom: 15px; }
    .stepper-registration-card .text-field-wrapper .mdc-text-field__input {
      padding-left: 16px;
      color: rgba(0, 0, 0, 0.87);
      font-family: Raleway;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.1px;
      line-height: 24px; }
    .stepper-registration-card .text-field-wrapper .mdc-text-field__label {
      padding-left: 16px;
      left: 4px; }
    .stepper-registration-card .text-field-wrapper .mdc-text-field__input:required + .mdc-text-field__label::after {
      display: none; }
    @media only screen and (min-width: 1025px) {
      .stepper-registration-card .text-field-wrapper .mdc-text-field-helper-text {
        padding-left: 16px; } }
  .stepper-registration-card .mdc-text-field {
    width: 100%;
    height: 56px !important;
    margin-top: 0px !important;
    padding-top: 0px !important; }
  .stepper-registration-card .mdc-select__selected-text {
    height: 40px;
    margin-top: 6px;
    white-space: inherit; }
  .stepper-registration-card .legal-terms-container {
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    width: 100%;
    height: 100%;
    color: rgba(0, 0, 0, 0.54) !important;
    font-family: Raleway;
    font-size: 0.8125rem !important;
    font-weight: 500 !important;
    letter-spacing: 1.28px !important;
    line-height: 19px !important;
    overflow-x: auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; }
    .stepper-registration-card .legal-terms-container section {
      display: inline-block !important;
      height: 100% !important; }
      .stepper-registration-card .legal-terms-container section div {
        display: inline-block !important; }

.flex-wrapper {
  padding-left: 8px;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  display: flex;
  width: 100%; }
  .flex-wrapper .mdc-text-field__bottom-line {
    width: 100%; }
  .flex-wrapper .mdc-select {
    height: 55px; }
  .flex-wrapper .mdc-select__surface {
    display: block;
    width: 100%; }
  .flex-wrapper .mdc-select__bottom-line {
    height: 1px;
    width: 100%; }
  .flex-wrapper .showhide__wrapper {
    width: 100%; }
  .flex-wrapper .mdc-select__label {
    display: block; }
  .flex-wrapper .form-instructions {
    width: 100%;
    color: rgba(0, 0, 0, 0.54);
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px; }

.strength-meter--WEAK .mdc-linear-progress__bar-inner {
  background-color: #cc0000; }

.strength-meter--GOOD .mdc-linear-progress__bar-inner {
  background-color: #008b00; }

.strength-meter--STRONG .mdc-linear-progress__bar-inner {
  background-color: #008b00; }

@media only screen and (max-width: 800px) {
  .flex-wrapper .parentsDivPasswordStrengthMeterFormedia {
    width: 33% !important; } }

.strengthPasswordLabeColors--GOOD,
.strengthPasswordLabeColors--STRONG {
  color: #008b00; }

.strengthPasswordLabeColors--WEAK {
  color: #d20000 !important; }

.strengthPasswordLabel {
  height: 14px;
  font-family: Raleway;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.07px;
  line-height: 14px;
  text-align: right; }

.parentsDivPasswordStrengthMeter {
  width: 33% !important;
  margin-right: 4px !important; }

.stepper-registration-card div {
  padding-bottom: 0; }

.stepper-registration-card .stepper-registration-card--last-child {
  margin-bottom: 3px; }

.signupPage-passowrd-label label {
  transform: translateY(-50%) scale(0.75); }

.stepper-registration-card--last-child .mdc-text-field__input::placeholder {
  opacity: 1 !important; }

.legal-terms-outer-container {
  border: 1px solid rgba(151, 151, 151, 0.14);
  background-color: rgba(216, 216, 216, 0.14);
  padding: 0px 16px 58px 16px;
  height: 50vh; }
  .legal-terms-outer-container .date {
    color: rgba(0, 0, 0, 0.54);
    font-family: Raleway;
    font-size: 0.8125rem;
    font-weight: 500;
    letter-spacing: 1.28px;
    margin-top: 13px;
    margin-bottom: 13px; }

.stepper-registration-card .mdc-form-field {
  font-size: 1rem;
  color: #232323;
  font-family: Raleway;
  font-weight: 500; }

.frc-header {
  background: url("/img/backgrounds/AvatarBackground.png") center no-repeat;
  background-size: cover; }
  .frc-header--cover {
    width: 100%; }

.frc-drawer__header--primary {
  background: url("/img/backgrounds/AvatarBackground.png") center no-repeat;
  background-size: cover; }

/** CDK EXCHANGE/ZEBRAFISH Theme Styles */
/* #TODO: ^^^^^^^^^^^^^^^^^^^^^^^^^ */
/* ================================================== */
/* =======
// #WIP: 636: Account UI // SET UP THEMING for CDK UIP/MDC/react
// reference: node_modules/@cdk-uip/theme/README.md
// reference: https://github.com/material-components/material-components-web/blob/master/packages/mdc-theme/README.md
======= */
/* #END */
/* ================================================== */
/* ================================================== */
/* #TODO: */
/* ================================================== */
/* =======
// #WIP: BAD!! SHAME + CHEAP OVERRIDES FOR TEMP:
======= */
:root {
  --mdc-typography-base: "Raleway", sans-serif;
  --mdc-typography-font-family: "Raleway", sans-serif;
  --mdc-theme-primary: #904778;
  --mdc-theme-primary-light: #904778;
  --mdc-theme-primary-dark: #904778;
  --mdc-theme-secondary: #904778;
  --mdc-theme-secondary-light: #904778;
  --mdc-theme-secondary-dark: #904778;
  --mdc-theme-background: #fff; }

.mdc-input-invalid .mdc-text-field__bottom-line {
  background-color: #d50000 !important; }

.mdc-input-invalid .mdc-text-field__input {
  border-color: #d50000 !important; }

.mdc-input-invalid div {
  border-color: #d50000 !important; }

.mdc-input-invalid label {
  color: #d50000 !important;
  border-color: #d50000 !important; }

.mdc-label-invalid {
  color: #d50000 !important; }

html,
body {
  font-family: "Raleway", sans-serif !important;
  font-size: 16px;
  font-weight: 500; }

[class*="mdc-"],
[class*="mdc-button"],
[class*="mdc-card"],
[class*="mdc-select"],
[class*="mdc-text"],
[class*="mdc-typography-"] {
  font-family: "Raleway", sans-serif !important; }

[class*="headline"],
[class*="title"] {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500; }

[class*="subtitle"] {
  font-family: "Raleway", sans-serif !important; }

[class*="subheading"] {
  font-family: "Montserrat", sans-serif !important; }

.customFooter .mdc-expansion-panel__expansion-icon {
  font-family: "Material Icons" !important; }

/* #END */
/* ================================================== */
/* ================================================== */
/* #TODO: */
/* ================================================== */
/* =======

// #WIP: XCHNG-635: SET UP CSS PREPROCESSOR.
// Following these Instructions:
// http://stash.cdk.com/projects/COM/repos/cdk-uip-react/browse/demo#adding-a-css-preprocessor-sass-less-etc

- SET UP SASS PREPROCESSOR
- SET UP THEMING for CDK UIP/MDC/react
- adopt, extend, tokenize from lib dependency:
-- all colors
-- all widths & breakpoints
-
======= */
/*VARS CANDIDATES:*/
.swatches-brand--gradient {
  background: #f70c71;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #f70c71 0%, #9c2c70 12%, #6b2574 24%, #354a90 45%, #308cb0 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #f70c71 0%, #9c2c70 12%, #6b2574 24%, #354a90 45%, #308cb0 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #f70c71 0%, #9c2c70 12%, #6b2574 24%, #354a90 45%, #308cb0 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(
 startColorstr="#f70c71",
 endColorstr="#308cb0",
 GradientType=1
 );
  /* IE6-9 fallback on horizontal gradient */ }

.swatch-#f70c71 {
  color: #f70c71; }

.swatch-#9c2c70 {
  color: #9c2c70; }

.swatch-#6b2574 {
  color: #6b2574; }

.swatch-#354a90 {
  color: #354a90; }

.swatch-#308cb0 {
  color: #308cb0; }

/* #END */
/* ================================================== */
/* #TODO: */
/* ================================================== */
/* =======

// #WIP: XCHNG-790: SET UP @MEDIA BREAKPOINT REFS/MIXINs [?]
======= */
/* #END */
/* ================================================== */
/* ================================================== */
/* PAGE: ALL */
/* ================================================== */
body {
  margin: 0;
  background-color: #f5f5f5; }

.mdc-card {
  background-color: #fff; }

/* #END */
/* ================================================== */
/* ================================================== */
/* CDK-LOGIN CARDS: ALL */
/* ================================================== */
/* http://gbh-npm-internal.gbh.dsi.adp.com/cdk-uip-react/#login */
@media only screen and (min-device-width: 568px) and (max-device-width: 850px) and (orientation: landscape) {
  .cdk-login {
    background-color: #fff;
    background-image: none !important; } }

.cdk-login {
  /* KEEP ITEMS TALLER THAN THE VIEWPORT A LITTLE DISTANCE AWAY FROM TOP */
  /* padding-top: 16px; */
  background: url(/img/backgrounds/brand_app_bg_300__compressed.jpg) no-repeat fixed top left;
  background-size: initial; }

.cdk-login::before {
  /* GRADIENT BAND UNDER THE BG IMG ON ".cdk-login" */
  z-index: -1;
  position: fixed;
  top: 0;
  display: block;
  content: "";
  height: 300px;
  /* Match the BG IMG height */
  width: 100%;
  background: #f70c71;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #f70c71 0%, #9c2c70 12%, #6b2574 24%, #354a90 45%, #308cb0 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #f70c71 0%, #9c2c70 12%, #6b2574 24%, #354a90 45%, #308cb0 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #f70c71 0%, #9c2c70 12%, #6b2574 24%, #354a90 45%, #308cb0 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(
 startColorstr="#f70c71",
 endColorstr="#308cb0",
 GradientType=1
 );
  /* IE6-9 fallback on horizontal gradient */ }

.cdk-login .mdc-card {
  /* LIB COMPONENT OVERRIDES */
  width: 39%;
  max-width: 496px;
  min-width: 350px;
  position: relative;
  margin-top: 90px;
  border-radius: 4px;
  box-shadow: 0em 0 0.2em rgba(0, 0, 0, 0.41); }

.cdk-login .mdc-button {
  margin-top: 0; }

.cdk-login .mdc-snackbar {
  z-index: 1; }

@media only screen and (max-width: 668px) {
  .cdk-login .mdc-card {
    width: 100%; } }

/* #END */
/* ================================================== */
/* ================================================== */
/* PAGE: OKTA WIDGET */
/* ================================================== */
#okta-sign-in .okta-sign-in-header {
  background-color: #000; }

/* #END */
/* ================================================== */
/* ================================================== */
/* PAGE: /HEADER */
/* ================================================== */
.frc-header__nav-link:hover {
  text-decoration: none; }

.frc-header__list-item-link:hover {
  text-decoration: none; }

.frc-header__list-item i {
  font-family: "Material Icons" !important; }

/* #END */
/* ================================================== */
/* ================================================== */
/* PAGE: /SIGNIN */
/* ================================================== */
.ssocard__logo {
  position: absolute;
  top: 16px;
  left: 24px;
  padding: 14px 8px; }
  .ssocard__logo :hover {
    color: white;
    text-decoration: none; }

.ssocard__logo-title {
  width: 212px;
  font-size: 30px;
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  letter-spacing: 4.97px;
  display: inline-block;
  line-height: 27px;
  font-family: "Montserrat", sans-serif; }

.ssocard__signin--title {
  height: 32px;
  padding-top: 0 !important;
  color: rgba(0, 0, 0, 0.87);
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 32px; }

.ssocard-text-field-wrapper {
  margin-bottom: 20px; }
  .ssocard-text-field-wrapper .mdc-text-field__input {
    padding-left: 16px;
    padding-bottom: 10px;
    color: rgba(0, 0, 0, 0.87);
    font-family: Raleway;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 24px; }
  .ssocard-text-field-wrapper .mdc-text-field__label {
    padding-left: 16px;
    left: 4px;
    color: rgba(0, 0, 0, 0.6);
    font-family: Raleway;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.2px; }

.ssocard__account-text--center {
  padding-bottom: 0;
  color: rgba(0, 0, 0, 0.6);
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center; }
  .ssocard__account-text--center .anchor {
    height: 24px;
    line-height: 24px; }

.ssocard__forgotpwd-text--center {
  padding-top: 0;
  font-family: Raleway;
  font-size: 14px;
  line-height: 24px;
  text-align: center; }

.ssocard__button--wrapper {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 59px; }

.ssocard__button--primary {
  width: 100%; }

.image-login {
  background: url(/img/ui/img_signin@2x.png);
  width: 490px;
  height: 291px;
  background-size: cover; }

.image-signup {
  background: url(/img/ui/img_createaccount@2x.png);
  width: 592px;
  height: 439px;
  background-size: cover; }

  .image-verify-account {
    background: url(/img/ui/img_verifyaccount@2x.png);
    width:367px;
    height: 330px;
    background-size: cover; 
    margin-top: 40px;
  }

    .confirm__body {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;
      background-color: #fff;
    }

    .confirm__body .confirm__title {
      margin-top: 30px;
      font-weight: 300;
      font-size: 42px;
    }
    .confirm__body .confirm__subtitle {
      margin-top: 10px;
      font-size: 13px;
    font-weight: 400;
    }
    .confirm__body .confirm__description {
      margin-top: 10px;
      font-size: 13px;
    font-weight: 400;
    }

.parent-div {
  position: relative;
  display: flex;
  background-color: #ffffff; }

.sign-up-parent-div {
  position: relative;
  display: grid;
  grid-template-columns: repeat(12,1fr);
  background-color: #ffffff;
  justify-content: center;
  height: 750;
  max-width: 1500px;
  width: 100%;
 }
 .change-password-title{
      font-size: 44px;
 }
 
  @media screen and (max-width: 1023px) {
    .sign-up-left-img {
      display: none;
      grid-column: 1/7;
    }

    .create-account-header {
      display: block;
    }
  }

  .sign-up-left-img {
    grid-column: 1/7;
  }

  @media screen and (min-width: 1024px) {
    .create-account-header {
      display: none;
    }
  }
  
.parent-div .image-wrapper .image-login {
  background: url(/img/ui/LoginPage_bg.png);
  width: "490px";
  height: "291px";
  background-size: cover;
  position: "absolute";
  top: 25%;
  left: 50%; }

.parent-div .image-wrapper {
  margin-left: 15%;
  padding-top: 8%; }

@media screen and (max-width: 700px) {
  .image-login {
    display: none; }
  .image-forgotPassword {
    display: none; } 
    .image-changePassword {
      display: none; } }

/* #END */
/* ================================================== */
/* ================================================== */
/* PAGE: /SIGNUP */
/* ================================================== */
.signupcard {
  width: 478px; }

.signupcard__form-wrapper {
  padding: 1px 9px 0 7px; }

.signupcard__form-wrapper .mdc-text-field {
  width: 100%; }

.signupcard__button--primary {
  width: 100%; }

.signupcard__button--wrapper {
  width: 85%;
  margin-left: auto;
  margin-right: auto; }
  
  

.mdc-ripple-upgraded--background-focused .mdc-select__bottom-line {
  height: 2px; }

.mdc-select__surface {
  height: 55px;
  padding-left: 0px;
  width: 100%; }

.mdc-select__selected-text {
  color: rgba(0, 0, 0, 0.54) !important;
  font-family: Raleway;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 1rem;
  width: 100% !important;
  margin-top: 11px;
  margin-bottom: 9px; }

.mdc-select__bottom-line {
  height: 1px; }

.mdc-select__bottom-line--active {
  height: 2px; }

.mdc-select__label {
  left: 0px;
  bottom: 0px; }

.mdc-select__label--float-above {
  bottom: 10px; }

.signupcard__captcha--wrapper.mdc-text-field-helper-text--validation-msg {
  color: #d50000;
  text-align: center; }

.signupcard .mdc-text-field {
  width: 100%; }

.signupcard__button--primary {
  width: 100%; }

.signupcard__button--wrapper {
  width: 85%;
  margin-left: auto;
  margin-right: auto; }

.signupcard__captcha--wrapper.mdc-text-field-helper-text--validation-msg {
  color: #d50000;
  text-align: center; }

.invalidInput--wrapper.mdc-text-field-helper-text--validation-msg {
  color: #d50000;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px; }

.mdc-dialog__footer__button * {
  pointer-events: none; }

.mdc-dialog__surface {
  max-height: calc(100vh - 30px);
  overflow: hidden; }

.mdc-dialog__body--cdk-full-height {
  max-height: none; }

.toolbar {
  background: url(/img/backgrounds/brand_app_bg__compressed.jpg);
  background-size: cover;
  height: 96px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4); }
  .toolbar .toolbar-row {
    width: 70%;
    margin: 0 auto; }
    @media screen and (max-width: 668px) {
      .toolbar .toolbar-row {
        width: 100%; } }
  .toolbar .toolbar-title {
    height: 14px;
    width: 519px;
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    margin-left: 14px;
    padding-top: 20px; }
  .toolbar .back-arrow {
    font-size: 24px;
    margin: 26px 0px 16px 16px;
    cursor: pointer;
    height: 24px; }

.anchor {
  color: rgba(144, 71, 120, 0.87);
  font-weight: 600;
  cursor: pointer; }
  .anchor:hover {
    text-decoration: underline; }

.sign-up__toolbar {
  height: 300px; }
  .sign-up__toolbar .sign-up__logo {
    width: 335px;
    position: absolute;
    top: 0;
    margin-left: 18px; }
  .sign-up__toolbar .sign-up__logo-title {
    width: 212px;
    font-size: 30px;
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
    letter-spacing: 4.97px;
    display: inline-block;
    line-height: 27px;
    font-family: "Montserrat", sans-serif; }

.sign_up_layout {
  position: absolute;
  top: 88px;
  left: 0;
  right: 0;
  border-radius: 4px;
  background-color: #fff;
  margin: 0 auto;
  display: flex;
  flex-shrink: wrap;
  flex-direction: column;
  min-width: 350px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  height: calc(100vh - 88px); }
  @media only screen and (max-width: 375px) {
    .sign_up_layout {
      width: 100%;
      min-width: auto; } }
  .sign_up_layout .title_text {
    margin: 0px;
    color: rgba(0, 0, 0, 0.87);
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.5px;
    line-height: 32px; }
  .sign_up_layout .subtitle_text {
    height: 20px;
    margin-top: 0px;
    margin-bottom: 25px;
    color: rgba(0, 0, 0, 0.54);
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; }
  .sign_up_layout .large {
    height: 36px;
    width: 36px; }
  .sign_up_layout .login-text {
    height: 20px;
    margin: 2px 0px;
    color: rgba(0, 0, 0, 0.6);
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; }
    .sign_up_layout .login-text .anchor {
      color: rgba(144, 71, 120, 0.87);
      font-weight: 600;
      cursor: pointer; }
      .sign_up_layout .login-text .anchor:hover {
        text-decoration: underline; }
  .sign_up_layout .cdk-stepper__step .cdk-stepper__step__icon {
    color: #ffffff;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center; }
  .sign_up_layout .cdk-stepper__step .clickable {
    cursor: pointer; }
  .sign_up_layout .cdk-stepper__step .purple-icon {
    background-color: #904778; }
    .sign-up-form-gap {
      grid-column: 7/8;
      }

      /* @media only screen and (min-width: 1023px) {
        .sign_up_layout .sign-up-form {
        grid-column: 8/12;
        }
        } */
      
      @media only screen and (max-width: 1023px) {
        .sign-up-form-gap {
          display: none;
        }
        .flex-wrapper {
          justify-content: center;
          align-items: center;
        }
      }

  .sign_up_layout .sign-up-form {
    grid-column: 1/12;
    border-radius: 4px;
    background-color: white;
    margin-top: 30px;
   }

   @media only screen and (min-width: 1023px) {
    .sign_up_layout .sign-up-form {
      grid-column: 8/12;
      }
   }

    .sign_up_layout .sign-up-form .captcha-wrapper {
      height: 76px;
      display: flex;
      justify-content: center;
      padding: 0 16px; }
    .sign_up_layout .sign-up-form .form-container {
      margin: 16px 24px 16px 16px; }
      .sign_up_layout .sign-up-form .form-container .form-instructions {
        margin-top: 0; }
    .sign_up_layout .sign-up-form .check-box-container {
      margin-left: 16px; }
    .sign_up_layout .sign-up-form #legalEntityType {
      margin-bottom: 12px; }
    .sign_up_layout .sign-up-form .check-box {
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      display: flex;
      width: 100%;
      position: relative;
      left: -11px; }
      @media only screen and (max-width: 1024px) {
        .sign_up_layout .sign-up-form .check-box {
        justify-content: center;
        }
      }
      .sign_up_layout .sign-up-form .check-box .checkbox {
        color: rgba(0, 0, 0, 0.87);
        font-family: Raleway;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        white-space: nowrap; }
        .sign_up_layout .sign-up-form .check-box .checkbox .fake-link {
          display: inline;
          margin: 0px 3px;
          color: #904778; }
          .sign_up_layout .sign-up-form .check-box .checkbox .fake-link:hover {
            cursor: pointer;
            text-decoration: underline; }
    .sign_up_layout .sign-up-form .footer {
      display: flex;
      margin-top: 32px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap; }
      .sign_up_layout .sign-up-form .footer button {
        width: 70%;
        height: 36px;
        border-radius: 4px;
        margin-bottom: 8px;
        color: #fff;
        font-family: Raleway;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 1.25px; }
  .sign_up_layout .step-body {
    background-color: white;
    width: calc(100% - 48px);
    margin-top: 20px;
    padding: 0px 24px 24px 24px; }
    .sign_up_layout .step-body .footer {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between; }
      .sign_up_layout .step-body .footer button {
        margin-right: 8px; }
  .sign_up_layout .shadow, .sign_up_layout .step-body {
    box-shadow: 5px 5px 5px #e2e7eb; }
  @media only screen and (max-width: 350px) {
    .sign_up_layout {
      padding: 0 8px 16px; }
    .sign_up_layout .rc-anchor,
    .sign_up_layout .rc-anchor-content,
    .sign_up_layout .rc-anchor-content label {
      width: auto; }
    .sign_up_layout .sign-up-form .check-box .checkbox {
      white-space: normal; } }
  @media screen and (max-width: 575px) {
    .sign_up_layout #rc-imageselect,
    .sign_up_layout .g-recaptcha {
      transform: scale(0.57);
      -webkit-transform: scale(0.57);
      transform-origin: 0 0;
      -webkit-transform-origin: 0 0; }
    .sign_up_layout .sign-up-form .check-box .checkbox {
      white-space: normal; } }
  .sign_up_layout .rc-anchor-checkbox-label {
    width: auto; }
  .sign_up_layout .rc-anchor-normal .rc-anchor-pt {
    margin: 2px 0 0 26px;
    width: 58px; }

@media only screen and (max-width: 425px) {
  .mdc-simple-menu.mdc-select__menu.mdc-simple-menu--open {
    left: 0 !important; }
    .mdc-simple-menu.mdc-select__menu.mdc-simple-menu--open li {
      white-space: normal;
      line-height: 18px; } }

@media only screen and (max-width: 350px) {
  .rc-anchor-normal {
    width: 250px; } }

/* #END */
/* ================================================== */
/* ================================================== */
/* PAGE: /SIGNIN OR /LOGIN */
/* ================================================== */
/* CURRENTLY SAME AS OKTA WIDGET */
/* #END */
/* ================================================== */
/* ================================================== */
/* PAGE: /RESETPWD */
/* ================================================== */
.resetpwdcard {
  padding-bottom: 8px; }
  .resetpwdcard .errorblock {
    margin: 1rem 1rem 0 0.5rem;
    padding: 0.625rem 1rem; }

#resetpwd__recaptcha {
  height: 76px; }

.resetpwdcard__form-wrapper {
  padding: 0px 24px 8px; }
  .resetpwdcard__form-wrapper .ssocard-text-field-wrapper {
    margin-bottom: 24px; }

.resetpwdcard__form-wrapper .mdc-text-field {
  width: 100%; }

.resetpwdcard__button--primary {
  width: 100%; }

.resetpwdcard__button--wrapper {
  width: 85%;
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto; }

.resetpwd-email-link {
  text-align: left;
  padding-bottom: 32px !important;
  margin-right: auto;
  margin-top: 40px !important;
  color: #904778;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px; }

.resetpwd-email_text {
  width: 700px;
  color: #151B25 !important;
  font-family: "Open Sans";
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 24px;
  white-space: initial !important; }

@media screen and (max-width: 700px) {
  .resetpwd-email_text {
    width: 500px; } }

.resetpwd-image {
  background: url(/img/ui/img_checkemail@2x.png);
  width: 388px;
  height: 332px;
  background-size: cover;
  margin-left: auto;
  margin-right: auto;
  display: table; }

/* #END */
/* ================================================== */
/* ================================================== */
/* PAGE: /challengecard__button--wrapper */
/* ================================================== */
.challengecard {
  width: 478px; }

#challengecard__recaptcha {
  margin-top: 32;
  margin-bottom: 32px;
  height: 76px; }
  #challengecard__recaptcha div {
    margin-left: auto;
    margin-right: auto; }

.challengecard__form-wrapper {
  padding: 1px 24px 24px 24px; }

.challengecard__text-field-wrapper {
  margin-bottom: 8px; }
  .challengecard__text-field-wrapper .mdc-text-field__input {
    padding-left: 16px;
    padding-bottom: 10px;
    color: rgba(0, 0, 0, 0.87);
    font-family: Raleway;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 24px; }
  .challengecard__text-field-wrapper .mdc-text-field__label {
    padding-left: 16px;
    left: 4px;
    color: rgba(0, 0, 0, 0.6);
    font-family: Raleway;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.2px; }
  .challengecard__text-field-wrapper .mdc-text-field-helper-text {
    padding-left: 16px; }
  .challengecard__text-field-wrapper .mdc-text-field:first-child {
    margin-bottom: 0; }
  .challengecard__text-field-wrapper .mdc-text-field__input:required + .mdc-text-field__label::after {
    display: none; }

.challengecard__button--primary {
  width: 100%; }

.challengecard__button--primary-action {
  width: 100%;
  margin-top: 0em !important; }

.challengecard__button--wrapper {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px; }

.resetpwdcard .resetpwd-email-link {
  margin-top: 56px !important; }

.parent-div .image-wrapper .image-forgotPassword {
  background: url(/img/ui/img_forgotpassword@2x.png);
  width: 389px;
  height: 287px;
  background-size: cover;
  position: "absolute";
  top: 25%;
  left: 50%; }
  .challengecard__pwdchange-recaptcha{
    width: 24rem;
    display: flex;
   justify-content: center;
 }

/* #END */
/* ================================================== */
/* ================================================== */
/* PAGE: /SUCCESS */
/* ================================================== */
.successcard {
  width: 478px;
  border-radius: 0px; }

.successcard__form-wrapper {
  width: 91%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px !important; }

/* BUTTON SPACING */
.successcard button {
  margin-top: 1rem;
  margin-right: 0.5rem;
  width: 100%; }

.successcard button:last-of-type {
  margin-right: 0; }

.successcard__button--primary {
  width: 100%; }

.successcard__button--wrapper {
  padding: 0;
  margin: 0 auto 8px;
  width: 100%; }
  .successcard__button--wrapper .verification-button {
    width: auto; }

/* #END */
/* ================================================== */
/* ================================================== */
/* UTILITIES */
/* ================================================== */
.util__text--center {
  text-align: center;
  padding: 16px 24px 32px 16px; }

@media screen and (max-width: 668px) {
  .util__text--vertical-padding {
    padding-top: 5px;
    padding-bottom: 5px; } }

#resetpwd__recaptcha div {
  margin-left: auto;
  margin-right: auto; }

.util__text--purple {
  color: #904778; }

.util__anchor--pointer:hover, .close-button:hover {
  cursor: pointer; }

.mdc-card__subtitle.util__text--secondary {
  color: rgba(0, 0, 0, 0.6);
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  margin-top: 0;
  padding: 16px 8px 0 8px; }

.verification-button.mdc-ripple-upgraded {
  height: 36px;
  width: 350px;
  margin: 0; }

div.util__text--bold {
  font-weight: 700; }

.form-instructions__link,
.c-text-login,
.c-text-signup {
  margin-top: 1rem;
  font-size: 0.94rem; }

.c-text-signup + .c-text-forgotpassword {
  margin-top: -0.5rem; }

.form-instructions__link,
.c-text-login__link,
.c-text-forgotpassword__link,
.c-text-signup__link {
  font-weight: bold; }

.u-hide {
  display: none; }

.u-show,
.u-show--block {
  display: block; }

.u-show--inline {
  display: inline; }

.u-show--inline-block {
  display: inline-block; }

.u-text--is-optional::after {
  content: "(optional)";
  margin-left: 0.3rem;
  font-size: 0.8em; }

.u-text--is-bold, .c-legal-terms-dialog .mdc-dialog__header__title {
  font-weight: bold; }

.u-text--is-link {
  color: #904778;
  cursor: pointer;
  pointer-events: all; }

.u-text--is-linkcolor {
  color: #904778; }

label.u-text--is-required::after,
.u-text--is-required [class*="label"]::after {
  margin-left: 1px;
  content: "*"; }

.cdk-login label.u-text--is-required::after,
.cdk-login .u-text--is-required [class*="label"]::after {
  margin-left: 1px;
  content: "*"; }

.cdk-login .mdc-text-field__input:required + .mdc-text-field__label::after {
  margin-left: 1px;
  content: "*"; }

/* #END */
/* ================================================== */
/* ================================================== */
/* TEMP & SHAMEFUL OVERRIDES */
/* ================================================== */
.cdk-login__footer {
  font-size: 0.75rem;
  /* color: #777777; */
  font-family: Roboto, Arial, sans-serif;
  text-align: center;
  font-size: 12px;
  color: #000;
  font-family: Raleway;
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
  text-align: center;
  background:#fff;
  padding: 2rem;
  margin: 0rem;
 }

.confirmation-box .cdk-login__footer {
  position: absolute;
  bottom: 8px;
  left: 0;
  right: 0;
  margin-bottom: 16px; }

.cdk-login-footer__link {
  padding: 0 0.5rem;
  color: #000;
  text-decoration: none; }
  .cdk-login-footer__link:hover {
    text-decoration: underline; }

.ssocard__footer {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0; }

.confirmation-box__footer {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 14vh;
 }

.mdc-card__title {
  color: #3b3331; }

.mdc-card__subtitle {
  margin-top: 0rem;
  color: #767676;
  padding: 0px; }
  .mdc-card__subtitle span {
    font-size: 0.95rem; }

.mdc-card__primary {
  padding-left: 25px; }

.mdc-card__subtitle--custom-emphasized {
  font-size: 0.93rem;
  font-weight: bold;
  color: #904778; }

.resetpwdcard .mdc-card__media,
.cdk-login .mdc-card__media {
  display: none; }

.resetpwdcard .mdc-card {
  border-top: #904778 5px solid; }

.mdc-dialog__footer__button--cancel:not(:disabled),
.mdc-button.mdc-button--raised:not(:disabled),
.mdc-button.mdc-button--unelevated:not(:disabled) {
  background-color: transparent !important;
  color: #904778 !important;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.25px;
  line-height: 36px;
  text-align: center; }

.mdc-dialog__footer__button--accept:not(:disabled),
.mdc-button--primary:not(:disabled),
.mdc-button--primary.mdc-button--raised:not(:disabled),
.mdc-button--primary.mdc-button--unelevated:not(:disabled),
.mdc-dialog__action.mdc-button--raised:not(:disabled),
.mdc-dialog__action.mdc-button--unelevated:not(:disabled) {
  background-color: #904778 !important;
  color: #fff !important; }

a {
  color: #904778; }
  a:hover {
    color: #904778;
    text-decoration: underline; }

/* CSS VAR TEST */
/* .mdc-button--raised:not(:disabled), */
/* .mdc-button--unelevated:not(:disabled) { */
/* background-color: var(--mdc-theme-primary, #004e9a); */
/* #END */
/* ================================================== */
/* ================================================== */
/* ================================================== */
/* COMPONENTS: ALL */
/* ================================================== */
/* SECTION FOR EACH ONE: */
/* close button */
/* ================================================== */
.close-button__wrapper {
  position: absolute;
  top: 1.5rem;
  left: 4rem; }

.close-button {
  margin-top: 0 !important;
  font-size: 0.93rem;
  font-weight: 500;
  color: white !important;
  text-transform: capitalize !important; }
  .close-button:hover {
    text-decoration: none; }

.close-button__icon {
  margin: 0 0.1rem;
  font-size: 1.3rem;
  vertical-align: top; }

@media screen and (max-width: 668px) {
  .close-button__wrapper {
    top: 0.5rem;
    left: 0.3rem;
    opacity: 0.75; }
    .close-button__wrapper:hover {
      opacity: 1; }
  .close-button {
    color: #3b3331 !important; }
  .close-button__textlabel {
    display: none; }
  .close-button__icon {
    font-size: 2rem; } }

/* errorToaster */
/* ================================================== */
.errorblock {
  margin: 1rem;
  text-align: left;
  padding: 0.5rem 1rem;
  border-left: 6px solid #f2453d;
  background-color: rgba(250, 154, 154, 0.18); }

.errorblock__title {
  height: 20px;
  font-family: Raleway, sans-serif;
  font-size: 0.9rem;
  color: #f2453d; }

.errorblock__subtitle {
  line-height: 20px;
  margin-top: 4px;
  padding: 0 0 0 19px;
  font-family: Raleway, sans-serif;
  font-size: 0.9rem;
  color: #3f3f3f; }
  .errorblock__subtitle .anchor {
    font-weight: 500; }
  .errorblock__subtitle span {
    font-size: 0.9rem; }
  .errorblock__subtitle p {
    margin: 0; }

.errorblock__links {
  color: #904778; }

/* page-not-found__icon */
/* ================================================== */
.page-not-found__icon {
  font-size: 4rem;
  color: #904778;
  opacity: 0.5; }

/* circularprogress */
/* ================================================== */
.circularprogress__outer-wrapper {
  position: relative;
  margin: 8px; }

.circularprogress__component {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -24px; }

/* legal-terms-dialog */
/* ================================================== */
.c-legal-terms-dialog .mdc-dialog__header__title {
  font-size: 1.5rem; }

.mdc-typography--subheading2 {
  font-weight: bold;
  font-size: 1.2rem;
  color: #3b3331; }

.c-legal-terms-dialog--scrollablecontent {
  max-height: 195px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  overflow-x: auto;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  max-height: 66vh !important; }

.c-legal-terms-dialog__content {
  margin: 1rem;
  padding: 1rem;
  margin-bottom: 0; }

.c-legal-terms-dialog__divider {
  margin: 0.5rem; }

.cdk-login .c-legal-terms-dialog__button {
  margin-right: 0;
  margin-left: 0;
  width: auto;
  align-self: flex-end; }

.changePasswordCard {
  text-align: center; }

.changePasswordText {
  margin-left: 10%;
  margin-right: 10%; }

.image-changePassword {
  background: url(/img/ui/img_createnewpassword@2x.png);
  width: 447px;
  height: 270px;
  background-size: cover; }

.fee-acceptance-dialog .fee-acceptance-dialog-section {
  margin-top: 10px; }

.fee-acceptance-dialog .fee-acceptance-dialog-content {
  font-size: 1.2rem;
  margin-top: 0;
  margin-bottom: 30px; }

.fee-acceptance-dialog .fee-acceptance-dialog-button-container {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap; }

.terms-section-header {
  margin: 10px 0 0 18.75pt; }

.mdc-text-field__label {
  transform: translateY(-100%) scale(0.75); }

/* ================================================== */
/* PAGE: /MFA SECURITY SETTINGS */
/* ================================================== */
.mfa-security--title {
  margin-bottom: 16px; }

.mfa-security--sub-title {
  color: rgba(0, 0, 0, 0.87);
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 24px; }

.mfa-security--content {
  margin: 8px 0 16px;
  color: rgba(0, 0, 0, 0.6);
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; }

.mfa-security__toggle--container {
  height: 68px;
  padding: 0 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.12); }
  .mfa-security__toggle--container span {
    height: 20px;
    color: rgba(0, 0, 0, 0.54);
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; }

.mfa-security__button {
  border-style: solid;
  padding: 0 14px 0 14px;
  margin-left: auto;
  border-width: 2px;
  line-height: 32px;
  float: right; }

.mfa-security__contact--sub-title {
  margin: 24px 0 8px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px; }

.mfa-security__contact--content {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.6);
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.01px;
  line-height: 19px; }

.mfa-verification__input {
  padding-bottom: 25px; }
  .mfa-verification__input .mdc-text-field__input {
    padding-left: 16px;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 24px; }
  .mfa-verification__input .mdc-text-field__label {
    padding-left: 16px;
    left: 4px;
    font-size: 16px;
    font-weight: 500; }

.mfa-verify__dialog--subtitle {
  color: rgba(0, 0, 0, 0.6);
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; }

.mfa-verify__dialog--content {
  padding: 8px 59px 0 5px; }
  .mfa-verify__dialog--content p {
    margin: 2px 0 8px;
    color: rgba(0, 0, 0, 0.6);
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; }
  .mfa-verify__dialog--content span {
    height: 24px;
    color: #904778;
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px; }

.mfa-security__snackbar .mdc-snackbar {
  z-index: 1; }

.fortellis-dialog--title {
  color: rgba(0, 0, 0, 0.87);
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 24px; }

.mfa-verification .ssocard__header {
  padding-bottom: 8px; }


/* #END */
/* ================================================== */
/* ================================================== */
/* PAGE: /MFA SIGNIN */
/* ================================================== */
.mfa-selection__button-wrapper {
  width: 83%;
  margin: 0 auto 8px; }

.mfa-sub-title {
  letter-spacing: 0px; }

.mfa-selection__radio-wrapper {
  margin: 16px 0 80px 103px; }
  .mfa-selection__radio-wrapper .mdc-form-field > label {
    width: 69%; }
    .mfa-selection__radio-wrapper .mdc-form-field > label span {
      float: right;
      color: rgba(0, 0, 0, 0.6);
      font-family: Raleway;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 24px; }
.parent-div .mfa-image-wrapper {
  padding-top: 12%
}

.parent-div .mfa-image-wrapper .image-mfa-selection {
    background: url(/img/ui/img_requestverificationcode@2x.png);
    width: 447px;
    height: 281px;
    background-size: cover;
  }
  @media screen and (max-width: 800px) {
    .image-mfa-selection{
      display: none;
    }
  }
.mfa-verification__input-wrapper {
  margin: 0px 8px; }

.mfa-verification__checkbox-wrapper {
  margin: 2px 8px 0px 8px;
  width: 389px;
  padding-bottom: 27px; }
  .mfa-verification__checkbox-wrapper label {
    color: rgba(0, 0, 0, 0.87);
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    line-height: 40px; }

.mfa-verification__button-wrapper {
  width: 83%;
  margin: 32px auto 8px; }

  .mfa-verification__resend-wrapper {
    height: 20px;
    width: 389px;
    text-align: center;
    margin: 8px 0px;
    font-family: "Open Sans";
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px; }
  .mfa-verification__resend-wrapper span {
    float: right;
    color: #904778;
    font-weight: 600;
    line-height: 24px; }
    .mfa-verification__toast-wrapper{
      width:24rem;
    }

@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) and (orientation: portrait) {
  .mfa-selection__radio-wrapper {
    margin-left: 32px; }
  .mfa-verification__resend-wrapper span {
    float: left; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .mfa-selection .ssocard__signin--title {
    font-size: 22px; } }

@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: landscape) {
  .mfa-selection__radio-wrapper {
    margin-left: 64px; }
  .mfa-verification__resend-wrapper span {
    float: left; } }

.mfa-selection .ssocard__header {
  padding-bottom: 8px; }

/* #END */
/* ================================================== */
.settingLabel label {
  left: 16px !important; }

.settingLabel input {
  margin-left: 16px !important; }

.customErrorMsg {
  margin-left: 16px !important; }

.recaptcha-confirm-text {
  color: rgba(0, 0, 0, 0.54);
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; }

.confirmation-box {
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 0 16px;
  margin-top: 55px; }
  .confirmation-box .top-box-border {
    height: 4px;
    display: block;
    background-color: #904778;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12), 0 4px 4px 0 rgba(0, 0, 0, 0.24); }
  .confirmation-box .successcard {
    position: absolute;
    top: 180px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 39%;
    max-width: 500px;
    border-radius: 4px;
    background-color: #f5f5f5; }
    .confirmation-box .successcard .mdc-card__title--large {
      text-align: left;
      padding-top: 0;
      color: rgba(0, 0, 0, 0.87);
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.5px;
      line-height: 32px; }

/* #END */
/* ================================================== */
/* error-dialog-message */
/* ================================================== */
.error-dialog .mdc-dialog__surface {
  max-width: 512px; }

.error-dialog-message {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; }

.dialog-button {
  color: #904778 !important;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.25px;
  line-height: 36px;
  text-align: center;
  margin-top: 0px !important;
  width: auto !important; }
  .dialog-button:disabled {
    color: rgba(0, 0, 0, 0.37) !important; }

/* #END */
/* ================================================== */
/* ================================================== */
/* PAGE: /ListOfOrganization */
/* ================================================== */
.listOfOrganization {
  padding-bottom: 8px; }

.listOfOrganization__Page-header {
 height: 70px;
 background-color: #470B42 !important;
 }

.listOfOrganization__footer {
  bottom: 8px;
  left: 0;
  right: 0;
  width: 100%;
 }

.listOfOrganization__Page-link-home {
  background-color: inherit !important;
  letter-spacing: 5px !important;
  font-size: 28px !important;
  margin-left: 15px !important;
  margin-top: 15px !important;
  height: 22px !important; }

.listOfOrganization__Page-wrapper {
  margin: auto;
  width: 600px;
  padding: 10px;
  margin-top: 109px;
  margin-bottom: 109px; }

.listOfOrganization__Page-wrapper-content {
  box-shadow: none !important;
  margin-top: -10% !important;
  background-color: inherit !important; }

.listOfOrganization__text-head {
  height: 64px;
  width: 475px;
  color: #151b25;
  font-family: Montserrat;
  font-size: 48px !important;
  font-weight: 400 !important;
  letter-spacing: -1.2px;
  line-height: 64px;
  margin-left: 80px;
}

.signup__header__text {
  height: 64px;
  width: 584px;
  color: #151b25;
  font-family: Montserrat;
  font-size: 48px !important;
  font-weight: 400 !important;
  letter-spacing: -1.2px;
  line-height: 64px;
  margin-left: 80px;
}

.sign-up-hearder-message-large {
  font-size: 16px;
  line-height: 24px;
  color: #151B25;
  margin-left: 82px;
}

.sign-up-hearder-message-mobile {
  display: none;
}
@media screen and (max-width: 1023px) {
  .sign-up-hearder-message-mobile {
    font-size: 16px;
    line-height: 24px;
    color: #151B25;
    display: block;
  }
  }

  .sign-up__text-head {
    height: 64px;
    color: #151b25;
    font-family: Montserrat;
    font-size: 48px !important;
    font-weight: 400 !important;
    letter-spacing: -1.2px;
    line-height: 64px; }

    @media only screen and (max-width: 1023px) {
      .sign-up__text-head {
        font-size: 40px !important;
      }
    }
  
    @media only screen and (max-width: 767px) {
      .sign-up__text-head {
        font-size: 22px !important;
      }
    }

.listOfOrganization__text {
  color: #151b25 !important;
  font-family: "Open Sans";
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 24px;
  overflow: inherit !important;
  white-space: initial !important; }

.listOfOrganization__form-wrapper {
  padding: 0px 24px 8px; }

.listOfOrganization__button-wrapper--primary {
  margin-top: 32px; }

.listOfOrganization__button--primary {
  width: 100%; }

.listOfOrganization__card--wrapper {
  height: 80px; }

.listOfOrganization__text--body {
  height: 24px;
  width: 320px;
  color: #151b25;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 500 !important;
  line-height: 24px;
  letter-spacing: 0;
  position: relative;
  top: 16px;
  left: 16px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.listOfOrganization__text--content {
  height: 20px;
  width: 94px;
  opacity: 0.7;
  color: #151b25;
  font-family: "Open Sans";
  font-size: "14px";
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  top: 16px;
  left: 16px;
  padding-top: 2px; }

.listOfOrganization__button--secondary {
  height: 36px;
  position: relative;
  width: 160px;
  float: right;
  bottom: 22px;
  margin-right: 16px !important;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 20px; }

.listOfOrganization__spinner {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%; }

/* #END */
/* ================================================== */
.termsandconditions-dialog .mdc-dialog__surface {
  max-width: 560px; }

.termsandconditions-dialog .mdc-dialog__body--scrollable {
  max-height: 525px;
  margin-top: 9px; }

.signup-dialog-header-container {
  color: rgba(0, 0, 0, 0.87);
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin-top: -8px;
  margin-bottom: 2px; }

.signin-tnc-dialog .mdc-dialog__surface {
  max-width: 560px; }

.signin-tnc-dialog .mdc-dialog__header {
  flex-direction: column;
  align-items: flex-start; }

.signin-tnc-dialog .mdc-dialog__header__close {
  display: none; }

.signin-tnc-dialog .mdc-dialog__body--scrollable {
  max-height: 700px;
  margin-top: 9px; }

.signin-tnc-dialog-header-title {
  color: rgba(0, 0, 0, 0.87);
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin-top: -8px;
  margin-bottom: 4px; }

.signin-tnc-dialog-header-subtitle {
  color: rgba(0, 0, 0, 0.6);
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px; }

.tnc-updated-on {
  width: 512px;
  margin-top: 4px;
  margin-bottom: 2px;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px; }

.tnc-circular-progress {
  height: 650px;
  padding-top: 50%;
  text-align: center; }

.settingsLayout {
  background-color: #f5f5f5;
  margin: 0 70px;
  display: flex;
  flex-shrink: wrap;
  flex-direction: column;
  min-width: 390px;
  box-sizing: border-box; }

.Tabs {
  height: 100%;
  display: flex; }

.TabList {
  width: 261px;
  list-style-type: none;
  padding: 0px;
  margin: 0px; }

.TabPanel {
  flex: 1;
  display: none;
  padding-left: 40px; }

.TabPanel.is-active {
  display: block;
  padding-top: 0px; }

.SettingTitle {
  font-family: Montserrat;
  font-size: 34px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 40px;
  margin: 24px 0px 24px 0px; }

.settings-subtitle {
  margin-bottom: 25px;
  color: rgba(0, 0, 0, 0.6);
  font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.01px;
  line-height: 19px; }

.settings-tab .Button {
  display: inline-block;
  height: 32px;
  width: 100%;
  padding-left: 16px;
  color: #737373;
  text-align: left;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.6);
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px; }

.Tab.is-active .Button {
  background: #904778;
  color: white;
  border-radius: 0; }

.settings-pwd-snackbar.mdc-snackbar--multiline {
  padding-top: 12px; }
  .settings-pwd-snackbar.mdc-snackbar--multiline .mdc-snackbar__text {
    height: auto;
    width: auto; }

.stepper-registration-card .settings-panel-header {
  margin-top: 0px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 32px; }

.settingsBackground {
  background: transparent; }

.settingsFooter {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  float: right;
  margin-top: 34px; }
  .settingsFooter .cancelButton {
    color: #6b2574;
    margin-right: 10px; }

.settings-input {
  padding-bottom: 25px; }
  .settings-input .mdc-text-field__input {
    padding-left: 16px;
    color: rgba(0, 0, 0, 0.54) !important;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 24px; }
  .settings-input .mdc-text-field__label {
    padding-left: 16px;
    left: 4px; }
  .settings-input .visbilityIcon {
    position: absolute;
    right: 12px;
    line-height: 56px;
    cursor: pointer; }

.settings-pwd .mdc-text-field__label {
  transform: translateY(-50%) scale(0.75); }

.visbilityIcon {
  color: grey;
  vertical-align: middle; }

.mdc-text-field.mdc-text-field--with-trailing-icon.mdc-text-field--box.mdc-text-field--upgraded.mdc-ripple-upgraded.settngs-password {
  background: transparent; }

.questionMark {
  color: grey; }

.c-circular-progress--overlay-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.75);
  touch-action: none;
  pointer-events: none;
  z-index: 1000;
  padding-top: 44vh; }

.fortellis-field-info {
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
  text-align: left !important;
  max-width: 470px !important;
  border-radius: 2px !important;
  z-index: 100 !important; }

.background {
  height: 75px;
  width: 360px;
  border-radius: 2px;
  background-color: #ffffff; }

.text {
  height: 32.78px;
  width: 380px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; }

.help-icon {
  color: rgba(0, 0, 0, 0.6);
  float: right;
  margin-left: 10px;
  font-size: 20px; }

.textField {
  width: 100%; }

.flex {
  display: flex; }

.label {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.5); }

.pointerEvent {
  pointer-events: "Auto"; }

.toolTip {
  background-color: white;
  z-index: 1;
  left: 500%;
  top: 237px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 3px rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.2); }

.toolTipContactFortellis {
  color: #904778;
  font-weight: bold; }

@media only screen and (max-width: 1024px) {
  .confirmation-box .successcard,
  .verification-button.mdc-ripple-upgraded {
    width: 100%; }
  .confirmation-box__footer {
    margin-top: calc(100vh - 612px); } }

@media only screen and (min-device-width: 320px) and (max-device-width: 824px) and (orientation: landscape) {
  .sign-up__toolbar,
  .confirmation-box .cdk-login__footer {
    position: relative; }
  .confirmation-box__footer {
    margin-top: calc(100vh - 300px); }
  .cdk-login .mdc-card {
    width: 100%; } }

@media only screen and (min-device-width: 824px) and (max-device-width: 1366px) and (orientation: landscape) {
  .confirmation-box__footer {
    margin-top: calc(100vh - 392px); } }

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: portrait) {
  .sign-up__toolbar .sign-up__logo {
    margin-left: 0;
    width: 100%; }
  .confirmation-box .successcard {
    box-shadow: none; } }

.rc-anchor-normal .rc-anchor-pt {
  margin: 2px 0 0 26px;
  width: 58px;
  position: relative; }

.mdc-text-field:hover::before {
  opacity: 0; }

.changePasswordNewPassword label {
  left: 0px !important; }

.changePasswordForm-password {
  line-height: 1.2rem !important; }

.textFieldIcon-material-visibility-icon i {
  height: 24px !important;
  width: 24px !important; }

.textFieldIcon-material-visibility-icon {
  bottom: 12px !important; }

.passwordStrength-isPasswordValid-msg {
  margin: 0px;
  padding: 0px;
  color: red;
  font-size: 12px;
  font-weight: 500;
  padding-left: 16px; }

.password-strength-bar {
  margin: 5px 0px 0px 10px; }

.password-strength-bar div {
  width: calc(100% - 60px); }

.password-strength-bar div div {
  height: 4px !important; }

.password-strength-bar div div:last-of-type {
  display: none !important; }

.password-strength-bar p {
  margin: 6px 0px 0px !important;
  position: relative !important;
  top: -16px !important;
  height: 14px !important;
  font-family: Raleway !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  letter-spacing: 1.07px !important;
  line-height: 14px !important;
  text-align: right !important; }

.password-strength-bar ::-webkit-contacts-auto-fill-button {
  margin-right: 10px !important; }

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .password-strength-bar {
      margin-right: 10px !important; } } }

.settings-input .mdc-text-field__input::placeholder {
  opacity: 1 !important; }

.settings-input .settings-input-password-strength-bar {
  margin-top: 0px; }

.changePasswordForm-password .mdc-text-field__input::placeholder {
  opacity: 1 !important; }

.changePasswordForm-password .changePasswordForm-password-strength-bar {
  margin-bottom: 10px;
  margin-top: 12px; }

@-moz-document url-prefix() {
  .password-strength-bar {
    margin-right: 10px !important; } }

.password-strength-label-green p {
  color: #008b00 !important; }

.password-strength-label-red p {
  color: #cc0000 !important; }

.frc-header__menu {
  z-index: 9;
  max-width: 320px; }

.password-helper-text {
  position: relative;
  margin-top: -18px;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
  padding-left: 16px; }

.password-helper-text--invalid {
  color: #d50000; }

.stepper-registration-card .text-field-wrapper.stepper-registration-card--last-child {
  margin-bottom: 16px; }

.or-divider-line{
  margin-top:30px;
  margin-bottom: 30px;
}
  
.or-divider-line h2{
  width: 80%; 
  text-align: center; 
  border-bottom: 1px solid rgb(204,204,204); 
  line-height: 0.1em;
  margin: 10px auto 20px; 
}
    
.or-divider-line span{ 
  background:#fff;
  padding:0 30px;
  font-family: Open Sans,Roboto,sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
}